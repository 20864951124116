import React, { Component } from "react";
import classes from "./FilterModal.module.scss";
import BankIcon from "./BankIcon";

class FilterModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencies: [],
			banks: [],
			status: [],
		};
	}

	componentDidMount() {
		var currencies = JSON.stringify(this.props.currencies);
		var banks = JSON.stringify(this.props.banks);
		var status = JSON.stringify(this.props.status);
		this.setState({ currencies: JSON.parse(currencies), banks: JSON.parse(banks) });
		this.setState({ status: JSON.parse(status) });
	}

	render() {
		var currencies_list = this.state.currencies.map((index, key) => {
			const currency_style = index.isChecked
				? { background: "#285A64", color: "white" }
				: { backround: "none", color: "#285A64" };
			return (
				<div
					key={index.value}
					style={currency_style}
					className={classes.Currency}
					onClick={() => this.handleClickCurrency(index.value)}
				>
					{index.sign}
				</div>
			);
		});
		var bank_list = this.state.banks.map((index, key) => {
			const currency_style = index.isChecked
				? { background: "#285A64", color: "white" }
				: { backround: "none", color: "#285A64" };
			var br = null;
			if (key % 4 == 0) {
				br = <br />;
			}
			return (
				<>
					<div
						key={index.value}
						style={currency_style}
						className={classes.Currency}
						onClick={() => this.handleClickBank(index.name)}
					>
						<BankIcon bank={{ bank_name: index.name }} square={false} />
						<br />
					</div>
				</>
			);
		});
		var status_list = this.state.status.map((index, key) => {
			return (
				<div key={key} className={classes.checkbox}>
					<div
						className={index.isChecked && classes.checked}
						onClick={() => this.handleClickStatus(index.name)}
					></div>{" "}
					{index.name}
				</div>
			);
		});
		return (
			<div className={[classes.ModalItem, classes.FilterModal].join(" ")}>
				<div className={classes.ModalHeader}>
					<div className={classes.FilterModalHeader}>FILTERS</div>
					<div className={classes.FilterModalHeaderIcon}>
						<i
							className="fas fa-undo-alt"
							style={{ color: "white", fontSize: "23px", marginRight: "10px" }}
						></i>
					</div>
				</div>
				<div className={[classes.ModalBody, classes.FilterModalBody].join(" ")}>
					<div className={classes.FilterItem}>
						<div className={classes.ItemHeader}>
							{" "}
							<p className={classes.ItemTitle}>Status</p>{" "}
						</div>
						<div className={classes.Currencies} style={{ marginTop: 20 }}>
							{status_list}
						</div>
					</div>
					<div className={classes.FilterItem}>
						<div className={classes.ItemHeader}>
							{" "}
							<p className={classes.ItemTitle}>Currency</p>{" "}
						</div>
						<div className={classes.Currencies}>{currencies_list}</div>
					</div>
					<div className={classes.FilterItem} style={{ marginTop: 10, height: "auto" }}>
						<div className={classes.ItemHeader}>
							{" "}
							<p className={classes.ItemTitle}>Bank</p>{" "}
						</div>
						<div className={classes.Currencies}>{bank_list}</div>
					</div>
					<div className={classes.CenterButtons} style={{ marginTop: 20 }}>
						<button className={classes.YellowButton} onClick={() => this.onSaveFilters()}>
							SAVE
						</button>
						<button className={classes.WhiteButton} onClick={() => this.props.closeModal()}>
							{" "}
							CANCEL{" "}
						</button>
					</div>
				</div>
			</div>
		);
	}
	handleClickStatus = (status_value) => {
		let status = [...this.state.status];

		status.forEach((status) => {
			if (status.name == status_value) {
				status.isChecked = !status.isChecked;
			}
		});
		this.setState({
			status: status,
		});
	};
	handleClickCurrency = (currency_value) => {
		let currencies = [...this.state.currencies];

		currencies.forEach((currency) => {
			if (currency.value == currency_value) {
				currency.isChecked = !currency.isChecked;
			}
		});
		this.setState({
			currencies: currencies,
		});
	};
	handleClickBank = (bank_name) => {
		let banks = [...this.state.banks];

		banks.forEach((bank) => {
			if (bank.name == bank_name) {
				bank.isChecked = !bank.isChecked;
			}
		});
		this.setState({
			banks: banks,
		});
	};
	onSaveFilters = () => {
		this.props.closeModal();
		this.props.filterRecords(this.state);
	};
}
export default FilterModal;
