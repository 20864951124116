import gql from "graphql-tag";

export default gql`
	query ($type: String!) {
		bankTransfers(type: $type) {
			bankAccounts
		}
	}
`;
// type can be "incoming" or "outgoing"
