import gql from "graphql-tag";

export default gql`
	mutation updatePasswordWithToken(
		$reset_password_token: String!
		$password: String!
		$password_confirmation: String!
	) {
		updatePasswordWithToken(
			resetPasswordToken: $reset_password_token
			password: $password
			passwordConfirmation: $password_confirmation
		) {
			success
		}
	}
`;
