import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react";
import classes from "../LimitsAndSpreads.module.scss";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import spreads_query from "../../../../queries/Spreads/Spreads";
import update_spreads from "../../../../mutations/Spreads/CreateSpread";

const UserSpreadDetail = forwardRef((props, ref) => {
	const user = props.user;
	const modifiable = props.modifiable;
	const [spreads_data, setSpreads] = useState(null);

	const [EUR, set_EUR] = useState(null);
	const [USD, set_USD] = useState(null);
	const [GBP, set_GBP] = useState(null);
	const [CHF, set_CHF] = useState(null);

	const { loading, error, data, refetch } = useQuery(spreads_query, {
		variables: {
			user_id: user.type === "individual" ? user.id : null,
			group_id: user.type === "group" ? user.id : null,
		},
	});

	const [updateSpread, { data_mutation }] = useMutation(update_spreads, {
		refetchQueries: [{ query: spreads_query }],
	});

	useEffect(() => {
		refetch();
	}, []);

	useImperativeHandle(ref, () => ({
		exceptionSpreadsMutation() {
			console.log(props.user);
			spreads_data.forEach((spread) => {
				updateSpread({
					variables: {
						user_id: user.type === "individual" ? user.id : null,
						group_id: user.type === "group" ? user.id : null,
						attributes: {
							saleSpread: eval(spread.currency),
							currency: spread.currency,
						},
					},
				}).then((res) => {
					refetch();
					props.close_edit_screen();
				});
			});
		},
	}));

	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	let spreads = null;

	if (spreads_data == null) {
		setSpreads(data.spreads);
	}

	spreads = data.spreads.map((spread) => {
		if (eval(spread.currency) == null) {
			eval("set_" + spread.currency)(spread.saleSpread);
		}
		return (
			<tr key={spread.id}>
				<td>
					<b>{spread.currency}</b>
				</td>
				<td>
					{!modifiable ? (
						spread.saleSpread
					) : (
						<input
							className={classes.TextInput}
							name={spread.currency}
							type="text"
							value={eval(spread.currency)}
							onChange={(e) => {
								changeSpread(e);
							}}
						/>
					)}
				</td>
			</tr>
		);
	});

	const changeSpread = (e) => {
		eval("set_" + e.target.name)(e.target.value);
	};

	return (
		<table className={classes.editSpreadsExceptionsForm}>
			<tbody>
				<tr>
					<td colSpan="3">
						<i
							className={["fas fa-times-circle fa-2x", classes.PlusIcon].join(" ")}
							style={{ paddingRight: "10px" }}
							onClick={props.hideException}
						></i>
						<b>{user.name}</b>
					</td>
				</tr>
				{spreads}
			</tbody>
		</table>
	);
});

export default UserSpreadDetail;
