import React, { useEffect, useState } from "react";
import FourDots from "../../UI/Icons/FourDots/FourDots";
import SearchEngine from "../../UI/SearchEngine/SearchEngine";
import FilterModal from "../Modals/CurrencyTrader/FilterModal/FilterModal";
import classes from "./CurrencyTrader.module.scss";
import EmptyCard from "./EmptyCard";
import TransactionCard from "./TransactionCard";
const PageColumn = (props) => {
	const [transactions, setTransactions] = useState(props.transactions);

	const showDetailsCard = (transaction, openAccordion, parentTransaction) => {
		props.showDetailsCard(transaction, openAccordion, parentTransaction);
	};

	const toggleAccordion = (transaction, openAccordion, parentTransaction) => {
		props.showDetailsCard(transaction, true, parentTransaction);
	};

	const getFile = () => {
		fetch(props.url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");

				link.setAttribute("href", url);
				link.setAttribute("download", "currency_trader_matches.xls");
				link.click();
			});
	};

	useEffect(() => {
		setTransactions(props.transactions);
	}, [props.transactions]);

	const status = props.status;
	const paid = props.paid;
	let title;
	switch (status) {
		case "all": {
			title = "ONLINE ORDERS";
			break;
		}
		case "paid": {
			title = "PAID ORDERS";
			break;
		}
		case "unpaid": {
			title = "UNPAID ORDERS";
			break;
		}
		default:
		//do nothing
	}

	let html = [];
	let countRows = 0;

	let transactionsArray = [];
	let parentTransactions = []; //saves parent trasactions

	let immutable_transactions = JSON.parse(JSON.stringify(transactions));
	immutable_transactions.forEach((element, index) => {
		let transactionsList = [];
		parentTransactions.push(element);
		if (element.type === "purchase" && element.transactions.length > 0) {
			const temp = element.transactions.filter(
				(element) => element.saleOrder.paidToPortal === paid
			);
			temp.forEach((transaction) => {
				transaction.saleOrder.amount = transaction.amount;
				transactionsList.push(transaction.saleOrder);
			});
		}
		if (element.type === "sale" && element.transactions.length > 0) {
			const temp = element.transactions.filter(
				(element) => element.purchaseOrder.paidToPortal === paid
			);
			temp.forEach((transaction) => {
				transaction.purchaseOrder.amount = transaction.amount;
				transactionsList.push(transaction.purchaseOrder);
			});
		}

		if (element.paidToPortal === paid) transactionsList.push(element);
		transactionsArray.push(transactionsList);
	});

	// console.log(transactionsArray)

	transactionsArray.forEach((transactionsList, index) => {
		if (transactionsList.length > 0) {
			countRows++;
			html.push(
				<TransactionCard
					key={status + countRows}
					id={status + countRows}
					items={transactionsList}
					parentTransaction={parentTransactions[index]}
					status={status}
					type={transactionsList[transactionsList.length - 1].type}
					index={countRows}
					showDetailsCard={showDetailsCard}
					transactionSelectedIndex={props.transactionSelectedIndex}
					transactionSelectedType={props.transactionSelectedType}
					toggleAccordion={toggleAccordion}
					openAccordion={false}
					closeAllAccordions={props.closeAllAccordions}
					showTrasactionDetailsCard={props.showTrasactionDetailsCard}
					selectedTransaction={props.selectedTransaction}
				/>
			);
		} else if (transactionsList.length <= 0 && props.graphicUI === "default") {
			html.push(<EmptyCard key={index} emptyCard={true} />);
		}
	});
	return (
		<div className={classes.PageColumn}>
			<div className={classes.Title}>
				<span>{title}</span>
				<div
					className="filterModalIcon"
					style={{ position: "relative" }}
					onClick={() => props.toggleFilterModal(props.status)}
				>
					<FourDots size={18} dotSize={7.37} />

					{props.showFilterModal[status] ? (
						<FilterModal
							closeModal={props.closeFilterModal}
							filterRecords={(state) => props.filterOrders(state)}
							filterValues={props.filterValues}
						/>
					) : null}
				</div>
				{!!props.url && (
					<i
						className="fas fa-share-square"
						style={{
							color: "rgb(211, 156, 62)",
							fontSize: "18px",
							marginRight: "10px",
							cursor: "pointer",
						}}
						onClick={() => getFile()}
					></i>
				)}
			</div>

			<SearchEngine
				placeholder="Search for an order"
				value={props.searchFilter}
				extraStyle={{ margin: "30px 0" }}
				filter_search_filed={props.filterSearchBox}
			/>

			{html}
		</div>
	);
};

export default PageColumn;
