import React, { useState, useEffect } from "react";
import MainPage from "./MainPage";
import DetailsPage from "./DetailsPage";

const CurrencyTrader = (props) => {
	const [view, setView] = useState("mainPage");
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [parentTransaction, setParentTransaction] = useState(null);
	const [openAccordion, setOpenAccordion] = useState(false);
	const [filterValues, setFilterValues] = useState(null);
	const [active_page, setActivePage] = useState(1);
	const [offset, setOffset] = useState(0);
	const [searchFilter, setSearchFilter] = useState("");
	const [direction, setDirection] = useState(null);

	useEffect(() => {
		if (props.location.state)
			setFilterValues({
				filter_object: { accountName: props.location.state.globalName },
				currencies: [
					{ value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
					{ value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false },
					{ value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false },
					{ value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false },
				],
			});
		setDirection("accounts");
	}, [props]);

	const goToMainPage = () => {
		setView("mainPage");
	};

	const showDetailsPage = (
		transaction,
		openAccordion,
		parentTransaction,
		filterValues,
		active_page,
		offset,
		searchFilter
	) => {
		setFilterValues(filterValues);
		setActivePage(active_page);
		setOffset(offset);
		setSearchFilter(searchFilter);
		if (view !== "detailsPage") {
			setView("detailsPage");
			setSelectedTransaction(transaction);
			setParentTransaction(parentTransaction);
			setOpenAccordion(openAccordion);
		}
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const toggleAccordion = (openAccordion, status, transaction) => {
		showDetailsPage(transaction);
		setSelectedTransaction(transaction);
	};

	return view === "mainPage" ? (
		<MainPage
			showDetailsPage={showDetailsPage}
			toggleAccordion={toggleAccordion}
			filterValues={filterValues}
			active_page={active_page}
			offset={offset}
			searchFilter={searchFilter}
			direction={direction}
		/>
	) : (
		<DetailsPage
			transaction={selectedTransaction}
			parentTransaction={parentTransaction}
			showDetailsPage={showDetailsPage}
			toggleAccordion={toggleAccordion}
			openAccordion={openAccordion}
			goToMainPage={goToMainPage}
			direction={direction}
		/>
	);
};

export default CurrencyTrader;
