import React from "react";

import classes from "./Button.module.css";

const button = (props) => (
	<button
		className={[classes.Button, classes[props.btnType], props.extraStyle].join(" ")}
		onClick={props.clicked}
		disabled={props.disabled}
		type="button"
	>
		{props.children}
	</button>
);

export default button;
