import React, { useState, useEffect } from "react";
import classes from "./Accounts.module.scss";
import { useQuery, useMutation } from "@apollo/react-hooks";
import GetSpreads from "../../../queries/Spreads/Spreads";
import Accounts from "../../../queries/Accounts/Accounts";
import UpdateSpreads from "../../../mutations/Spreads/CreateSpread";
import FileViewerPopup from "./UserInfo/FileViewerPopup";
import ApproveBankAccount from "../../../mutations/BankAccounts/ApproveBankAccount";
import RefuseBankAccount from "../../../mutations/BankAccounts/RefuseBankAccount";
import getSymbolFromCurrency from "currency-symbol-map";
import NumberFormat from "react-number-format";

const AccountingInfo = (props) => {
	const [editSpreads, setEditSpreads] = useState(false);
	const [penClicks, setPenClicks] = useState(0);
	const [file_viewer, setFileViewer] = useState(false);
	const [EURSpread, setEURSpread] = useState({ id: "", saleSpread: "" });
	const [USDSpread, setUSDSpread] = useState({ id: "", saleSpread: "" });
	const [GBPSpread, setGBPSpread] = useState({ id: "", saleSpread: "" });
	const [CHFSpread, setCHFSpread] = useState({ id: "", saleSpread: "" });
	const [updateSpreads] = useMutation(UpdateSpreads, {
		refetchQueries: [{ query: GetSpreads, variables: { user_id: props.account.id } }],
	});
	const [approveBankAccount] = useMutation(ApproveBankAccount, {
		refetchQueries: [{ query: Accounts }],
	});
	const [refuseBankAccount] = useMutation(RefuseBankAccount, {
		refetchQueries: [{ query: Accounts }],
	});

	const account = props.account;
	const { loading, data } = useQuery(GetSpreads, { variables: { user_id: account.id } });

	const handleUpdateSpreads = (currency) => {
		let attributes;

		switch (currency) {
			case "EUR": {
				attributes = {
					saleSpread: EURSpread.saleSpread,
					currency: "EUR",
				};
				break;
			}
			case "USD": {
				attributes = {
					saleSpread: USDSpread.saleSpread,
					currency: "USD",
				};
				break;
			}
			case "GBP": {
				attributes = {
					saleSpread: GBPSpread.saleSpread,
					currency: "GBP",
				};
				break;
			}
			case "CHF": {
				attributes = {
					saleSpread: CHFSpread.saleSpread,
					currency: "CHF",
				};
				break;
			}
			default:
			//do nothing
		}
		updateSpreads({
			variables: {
				user_id: parseInt(account.id),
				attributes: attributes,
			},
		}).catch((error) => console.log(error.message));
	};

	const approveBank = (bank) => {
		approveBankAccount({
			variables: {
				bank_id: parseInt(bank.id),
			},
		}).catch((error) => console.log(error.message));
	};

	const refuseBank = (bank) => {
		refuseBankAccount({
			variables: {
				bank_id: parseInt(bank.id),
			},
		}).catch((error) => console.log(error.message));
	};

	const toggleEditSpreads = () => {
		setEditSpreads(!editSpreads);

		if (penClicks === 0) {
			setPenClicks(penClicks + 1);
		} else if (penClicks === 1) {
			setPenClicks(penClicks + 1);
			///Updating Spreads
			handleUpdateSpreads("EUR");
			handleUpdateSpreads("USD");
			handleUpdateSpreads("GBP");
			handleUpdateSpreads("CHF");
		} else setPenClicks(0);
	};

	useEffect(() => {
		if (!loading) {
			console.log(data);
			const spreads = data.spreads;
			spreads.forEach((element) => {
				switch (element.currency) {
					case "EUR": {
						setEURSpread({ id: element.id, saleSpread: element.saleSpread });
						break;
					}
					case "USD": {
						setUSDSpread({ id: element.id, saleSpread: element.saleSpread });
						break;
					}
					case "GBP": {
						setGBPSpread({ id: element.id, saleSpread: element.saleSpread });
						break;
					}
					case "CHF": {
						setCHFSpread({ id: element.id, saleSpread: element.saleSpread });
						break;
					}
					default:
					//do nothing
				}
			});
		}
	}, [loading, data]);

	const handleOnChange = (event, currency) => {
		switch (currency) {
			case "EUR": {
				setEURSpread({ ...EURSpread, saleSpread: event.target.value });
				break;
			}
			case "USD": {
				setUSDSpread({ ...USDSpread, saleSpread: event.target.value });
				break;
			}
			case "GBP": {
				setGBPSpread({ ...GBPSpread, saleSpread: event.target.value });
				break;
			}
			case "CHF": {
				setCHFSpread({ ...CHFSpread, saleSpread: event.target.value });
				break;
			}
			default:
			//do nothing
		}
	};

	const bankHTML = account.bankAccounts.map((item, index) => (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div
				key={index}
				style={{
					margin: "15px 0",
					height: "90px",
					marginRight: "20px",
					padding: "10px 10px",
					border: item.status == "approvation" ? "solid 2px red" : "none",
				}}
			>
				<div>
					<b>{item.currency}</b>
				</div>
				<div>{item.bankName}</div>
				<div>IBAN: {item.iban}</div>
			</div>
			{item.status == "approvation" ? (
				<div>
					<i
						className="fas fa-check-square"
						style={{ fontSize: 19, color: "#6cc14e", cursor: "pointer" }}
						onClick={() => approveBank(item)}
					></i>
					<i
						className="fas fa-ban"
						style={{ fontSize: 19, color: "red", marginLeft: "5px", cursor: "pointer" }}
						onClick={() => refuseBank(item)}
					></i>
				</div>
			) : null}
		</div>
	));

	const missingPayments = account.missingPayments.map((item, index) => (
		<tr key={index}>
			<td>{item.number}</td>
			<td>
				{
					<NumberFormat
						value={item.missingPayment}
						displayType={"text"}
						suffix={(item?.amountPaidCurrency || item?.amountSoldCurrency) === "ALL" ? " L" : null}
						prefix={
							(item?.amountPaidCurrency || item?.amountSoldCurrency) === "ALL"
								? null
								: getSymbolFromCurrency(item?.amountPaidCurrency || item?.amountSoldCurrency)
						}
						thousandSeparator=","
						decimalSeparator="."
					/>
				}
			</td>
		</tr>
	));

	let currencyMissingPayments = {};
	account.missingPayments.forEach((item) => {
		if (currencyMissingPayments[item?.amountPaidCurrency || item?.amountSoldCurrency])
			currencyMissingPayments[item?.amountPaidCurrency || item?.amountSoldCurrency] =
				currencyMissingPayments[item?.amountPaidCurrency || item?.amountSoldCurrency] +
				parseFloat(item.missingPayment);
		else
			currencyMissingPayments[item?.amountPaidCurrency || item?.amountSoldCurrency] = parseFloat(
				item.missingPayment
			);
	});

	let currencyMissingPaymentsHTML = [];
	for (let key in currencyMissingPayments) {
		const html = (
			<tr key={key}>
				<td>{key}</td>
				<td>
					{
						<NumberFormat
							value={currencyMissingPayments[key]}
							displayType={"text"}
							suffix={key === "ALL" ? " L" : null}
							prefix={key === "ALL" ? null : getSymbolFromCurrency(key)}
							thousandSeparator=","
							decimalSeparator="."
						/>
					}
				</td>
			</tr>
		);
		currencyMissingPaymentsHTML.push(html);
	}

	return (
		<div className={classes.AccountingInfo}>
			<div>
				<div className={classes.Title}>
					<b>Spreads</b>
				</div>
				<div style={{ display: "flex" }}>
					<table className={classes.SpreadsTable}>
						<thead>
							<tr>
								<th className={classes.Currency}></th>
								<th></th>
								<th>
									<b>Sale</b>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={classes.Currency}>
									<b>EUR</b>
								</td>
								<td style={{ color: "#659278" }}> 124, 6100</td>
								<td>
									{editSpreads ? (
										<>
											<input
												className={classes.TextInput}
												type="text"
												value={EURSpread.saleSpread}
												onChange={(event) => handleOnChange(event, "EUR")}
											/>
										</>
									) : (
										<>{EURSpread.saleSpread}</>
									)}
								</td>
							</tr>
							<tr>
								<td className={classes.Currency}>
									<b>USD</b>
								</td>
								<td style={{ color: "#992020" }}> 124, 6100</td>
								<td>
									{editSpreads ? (
										<>
											<input
												className={classes.TextInput}
												type="text"
												value={USDSpread.saleSpread}
												onChange={(event) => handleOnChange(event, "USD")}
											/>
										</>
									) : (
										<>{USDSpread.saleSpread}</>
									)}
								</td>
							</tr>
							<tr>
								<td className={classes.Currency}>
									<b>GBP</b>
								</td>
								<td style={{ color: "#992020" }}> 124, 6100</td>
								<td>
									{editSpreads ? (
										<>
											<input
												className={classes.TextInput}
												type="text"
												value={GBPSpread.saleSpread}
												onChange={(event) => handleOnChange(event, "GBP")}
											/>
										</>
									) : (
										<>{GBPSpread.saleSpread}</>
									)}
								</td>
							</tr>
							<tr>
								<td className={classes.Currency}>
									<b>CHF</b>
								</td>
								<td style={{ color: "#992020" }}> 124, 6100</td>
								<td>
									{editSpreads ? (
										<>
											<input
												className={classes.TextInput}
												type="text"
												value={CHFSpread.saleSpread}
												onChange={(event) => handleOnChange(event, "CHF")}
											/>
										</>
									) : (
										<>{CHFSpread.saleSpread}</>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{ alignSelf: "center", marginLeft: "auto" }}>
						{" "}
						The spreads displayed <br />
						are those for all users. <br />
						To add an exception, <br />
						press the pencil icon <br />
						and change one or <br />
						more of the values.
					</div>
					<div style={{ marginLeft: "auto" }}>
						<i
							className={["fas fa-pen", classes.PenIcon].join(" ")}
							style={{ fontSize: 19, marginTop: 5 }}
							onClick={toggleEditSpreads}
						></i>
					</div>
				</div>

				<div className={classes.Title} style={{ marginBottom: 20 }}>
					<b>Bank Information</b>
				</div>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div className={classes.BankInfo}>{bankHTML}</div>
				</div>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div style={{ width: "50%", marginRight: 20 }}>
						<div className={classes.Title} style={{ marginBottom: 20 }}>
							<b>Total Missing Payments</b>
						</div>
						<div style={{ maxHeight: 190, overflow: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Order</th>
										<th>Missing</th>
									</tr>
								</thead>
								<tbody>{missingPayments}</tbody>
							</table>
						</div>
					</div>
					<div style={{ width: "50%" }}>
						<div className={classes.Title} style={{ marginBottom: 20 }}>
							<b>Total</b>
						</div>
						<div style={{ maxHeight: 190, overflow: "auto" }}>
							<table>
								<thead>
									<tr>
										<th>Currency</th>
										<th>Missing</th>
									</tr>
								</thead>
								<tbody>{currencyMissingPaymentsHTML}</tbody>
							</table>
						</div>
					</div>
				</div>

				<button
					className={classes.YellowButton}
					style={{ width: 183, alignSelf: "flex-end", marginBottom: 20 }}
					onClick={() => setFileViewer(true)}
				>
					STATEMENTS
				</button>
				{file_viewer ? (
					<FileViewerPopup
						name={"STATEMENTS"}
						bank_accounts={account.bankAccounts}
						closePopup={() => setFileViewer(false)}
					/>
				) : null}
			</div>
		</div>
	);
};

export default AccountingInfo;
