import React, { useState } from "react";
import classes from "./Login.module.scss";
import classes1 from "../Global.module.scss";
import { useMutation } from "react-apollo";
import UpdatePasswordWithToken from "../../mutations/Login/UpdatePasswordWithToken";

const ResetPassword = (props) => {
	const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: "",
		confirmPassword: "",
	});
	const [message, setMessage] = useState(null);
	const [updatePasswordWithToken] = useMutation(UpdatePasswordWithToken);

	const handleInput = (event) => {
		event.persist();
		const fieldName = event.target.name;
		setNewPassword({ ...newPassword, [fieldName]: event.target.value });
	};

	const changePassword = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const token = urlParams.get("token");
		if (newPassword.password === "" && newPassword.confirmPassword === "") {
			setMessage(<p style={{ color: "#992020" }}>Please enter both fields</p>);
		} else {
			updatePasswordWithToken({
				variables: {
					reset_password_token: token,
					password: newPassword.password,
					password_confirmation: newPassword.confirmPassword,
				},
			})
				.then((data) => {
					//props.history.push("/login");
					setResetPasswordSuccess(true);
				})
				.catch((error) => {
					console.log(error.message);
					if (error.message === "GraphQL error: Error: Reset password token is invalid") {
						setMessage(<p style={{ color: "#992020" }}>Reset password token is invalid</p>);
					} else if (
						error.message === "GraphQL error: Error: Password confirmation doesn't match Password"
					) {
						setMessage(
							<p style={{ color: "#992020" }}>Password confirmation doesn't match Password</p>
						);
					} else if (
						error.message ===
						"GraphQL error: Error: Password is too short (minimum is 6 characters)"
					) {
						setMessage(
							<p style={{ color: "#992020" }}>Password is too short (minimum is 6 characters)</p>
						);
					}
				});
		}
	};

	const closeHandler = () => {
		props.history.push("/login");
	};

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			changePassword();
		}
	};

	const resetPasswordSuccessHTML = (
		<div className={classes.CardContent}>
			<div className={classes.ForgotPasswordInfo}>
				The password was changed.
				<br />
				You can login to your account
				<br />
				using the new password.
			</div>
			<button className={classes1.YellowButton} onClick={closeHandler}>
				CLOSE
			</button>
		</div>
	);

	const form = (
		<div className={classes.CardContent}>
			<div className={classes.SubText}>Please enter your email</div>
			{message}
			<div style={{ margin: 30 }}>
				<input
					type="password"
					style={{ width: 285, marginBottom: 20 }}
					name="password"
					placeholder="Enter Password"
					className={classes1.TextInput}
					onChange={handleInput}
					value={newPassword.password}
					onKeyPress={handleOnKeyPress}
				/>

				<input
					type="password"
					style={{ width: 285 }}
					name="confirmPassword"
					placeholder="Confirm Password"
					className={classes1.TextInput}
					onChange={handleInput}
					value={newPassword.confirmPassword}
					onKeyPress={handleOnKeyPress}
				/>
			</div>
			<button className={classes1.YellowButton} onClick={changePassword}>
				CONFIRM
			</button>
		</div>
	);

	return (
		<div>
			<div className={classes.Card}>
				<div className={classes.CardHeader}> </div>
				{!resetPasswordSuccess ? form : resetPasswordSuccessHTML}
			</div>
		</div>
	);
};

export default ResetPassword;
