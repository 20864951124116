import React from "react";
import classes from "./TransactionDetailsCard.module.scss";
import NumberFormat from "react-number-format";

import getSymbolFromCurrency from "currency-symbol-map";
var dateFormat = require("dateformat");

const TransactionDetailsCard = (props) => {
	const index = props.transactionSelectedIndex;
	const type = props.transactionSelectedType;
	let cardStyle = { top: 120, background: null };
	let triangleStyle = { top: 155 + (index - 1) * 120, bottom: null, borderColor: null };

	switch (type) {
		case "purchase": {
			cardStyle.background = "#C2D0D3";
			triangleStyle.borderColor = "transparent #C2D0D3 transparent transparent";
			break;
		}
		case "sale": {
			cardStyle.background = "#ECF2EF";
			triangleStyle.borderColor = "transparent #ECF2EF transparent transparent";
			break;
		}
		default:
		//do nothing
	}

	let account_type = null;

	switch (props.user.typology) {
		case "service_point": {
			account_type = "Service Point";
			break;
		}
		case "business_account": {
			account_type = "Business Account";
			break;
		}
		case "bank_account": {
			account_type = "Bank Account";
			break;
		}
		case "private_account": {
			account_type = "Private Account";
			break;
		}
		default:
		//do nothing
	}

	const currency_symbol = getSymbolFromCurrency(props.currency);
	const paid_currency_symbol = getSymbolFromCurrency(props.paid_currency);
	return (
		<div className={classes.DetailsCard} style={cardStyle}>
			<div className={classes.Triangle} style={triangleStyle}></div>
			<div className={classes.Content}>
				<div className={classes.Title}>OFFER DETAILS</div>
				<div className={classes.Section1}>
					<div>
						<span>
							<b>Offer Placed: </b>
							{props.datetime}
						</span>
						<span style={{ float: "right" }}>
							<b>Fixed Order</b>
						</span>
					</div>
					<div>
						<span>
							<b>Offer Number: </b>
							{props.order_number}
						</span>
					</div>
				</div>
				<div className={classes.Section2}>
					<div className={classes.Col1}>
						<div className={classes.SubTitle}>
							<b>Item Details</b>
						</div>
						<div>
							<div>
								<NumberFormat
									displayType={"text"}
									thousandSeparator={true}
									suffix={" " + props.currency}
									value={props.amount}
								/>
							</div>
							<div>
								{" "}
								at {props.rate} {currency_symbol}/{paid_currency_symbol}1{" "}
							</div>
						</div>
					</div>
					<div className={classes.Col2}>
						<div className={classes.SubTitle}>
							<b>User Information</b>
						</div>
						<div style={{ display: "flex" }}>
							<div>
								<b>Account Type: </b>
							</div>
							<div style={{ marginLeft: 5 }}>
								<div>{account_type}</div>
							</div>
						</div>
						<div className={classes.UserInfo}>
							<div className={classes.Col1}>
								<div>
									<b>
										{props.user.firstName} {props.user.lastName}
									</b>
								</div>
								<div>
									{props.user.personalIdNumber} <br /> DOB:{" "}
									{dateFormat(props.user.dateOfBirth, "dd/mm/yyyy")}
								</div>
								{props.document.typology === "passport" || props.document.typology === "id_card" ? (
									<div key={index}>
										National {props.document.typology === "passport" ? "Passport" : "Identity Card"}{" "}
										<br />
										{props.document.issueEntity} <br />
										No: {props.document.number} <br />
										Issued: {props.document.issueDate} <br />
										Expires: {props.document.expirationDate}
									</div>
								) : null}
								{/*<button className={classes.YellowButton}
                                    style={{ width: 190 }}>VIEW DOCUMENTS</button>*/}
							</div>
							<div className={classes.Col2}>
								<div>
									{props.user.address} {props.user.postalCode} <br /> {props.user.city},{" "}
									{props.user.country}
								</div>
								<div style={{ marginTop: 20 }}>
									{props.user.email}
									<br /> {props.user.cellphone}
								</div>
								<button className={classes.YellowButton} style={{ width: 190, marginTop: 20 }}>
									REGISTRATIONS
								</button>
								<button className={classes.YellowButton} style={{ width: 190, marginTop: 10 }}>
									ACCESS HISTORY
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransactionDetailsCard;
