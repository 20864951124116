import gql from "graphql-tag";

export default gql`
	mutation createBackOfficeUser(
		$email: String!
		$password: String!
		$password_confirmation: String!
	) {
		createBackOfficeUser(
			email: $email
			password: $password
			passwordConfirmation: $password_confirmation
		) {
			id
		}
	}
`;
