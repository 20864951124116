import React from "react";

const plus = (props) => (
	<div>
		<i
			className="fas fa-plus-circle"
			style={{ cursor: "pointer", color: "#D39C3E", fontSize: "22px" }}
			onClick={props.clicked}
		></i>
	</div>
);

export default plus;
