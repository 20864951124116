import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import GetAccountsQuery from "../../../queries/Accounts/Accounts";
import CountAccounts from "../../../queries/Accounts/CountAccounts";
import CountGroups from "../../../queries/Groups/CountGroups";
import GetGroupsQuery from "../../../queries/Groups/Groups";
import FourDots from "../../UI/Icons/FourDots/FourDots";
import SearchEngine from "../../UI/SearchEngine/SearchEngine";
import AccountsFilterModal from "../Modals/Accounts/FilterModal";
import classes from "./Accounts.module.scss";
import Card from "./Card";
import DetailsCard from "./DetailsCard";

const Accounts = (props) => {
	const [activePage, setActivePage] = useState(1);
	const [offset, setOffset] = useState((activePage - 1) * 7);
	const [grouped, setGrouped] = useState(null);
	const [groupName, setGroupName] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [groups, setGroups] = useState([]);
	const [user_deleted, set_user_deleted] = useState([true]);
	const [filter_attributes, setFilterAttributes] = useState({});
	const accountsData = useQuery(GetAccountsQuery, {
		variables: { offset: offset, limit: 7, grouped: grouped, attributes: filter_attributes },
	});
	const accounts_counter = useQuery(CountAccounts, {
		variables: { attributes: filter_attributes },
	});
	const groupsData = useQuery(GetGroupsQuery, {
		variables: { offset: offset, limit: 7, group_name: groupName },
	});
	const groups_counter = useQuery(CountGroups, { variables: { group_name: groupName } });
	const [newGroup, setNewGroup] = useState({
		name: null,
		accounts: [],
	});

	const [showDetails, setShowDetails] = useState(null);
	const [columnSelectedIndex, setColumnSelectedIndex] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);

	const [userGroupIconOpacity, setUserGroupIconOpacity] = useState(0.3);
	//showAccounts
	//showGroups
	const [view, setView] = useState(
		props.location.state ? props.location.state.view : "showAccounts"
	);
	//addGroup
	//editGroup
	const [action, setAction] = useState(null);
	const [title, setTitle] = useState(view === "showAccounts" ? "ACCOUNTS" : "ALL GROUPS");
	const [showAccountsModal, setShowAccountsModal] = useState(false);

	useEffect(() => {
		if (!accountsData.loading && accountsData.data) {
			let tempData = accountsData.data.users.filter((user) => user.state !== "deleted");

			tempData.forEach((element) => {
				element.cardSelected = false;
			});
			setAccounts([...tempData]);

			if (selectedItem && action === null) {
				const temp = accountsData.data.users.filter((item) => item.id === selectedItem.id)[0];
				setSelectedItem(temp);
			}
		}
	}, [accountsData, user_deleted]);

	useEffect(() => {
		if (!groupsData.loading && groupsData.data) {
			setGroups([...groupsData.data.groups]);
		}
	}, [groupsData]);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setOffset((pageNumber - 1) * 7);
		setShowDetails(false);
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const toggleShowModal = () => {
		setShowAccountsModal(!showAccountsModal);
	};

	const closeModal = () => {
		setShowAccountsModal(false);
	};

	const handleShowDetails = (index, type, item) => {
		if (columnSelectedIndex === index || index === null) {
			setShowDetails(null);
			setColumnSelectedIndex(null);
			setSelectedItem(null);
		} else {
			setShowDetails(type);
			setColumnSelectedIndex(index);
			setSelectedItem(item);
		}
	};

	const addAccountHandler = () => {
		window.open("https://cp.hypertuning.it/signup", "_blank");
	};

	const toggleUserGroupIcon = () => {
		if (view === "showGroups" || action !== null) {
			setAction(null);
			setView("showAccounts");
			setTitle("ACCOUNTS");
			setActivePage(1);
			setOffset(0);
			setGrouped(null);
		} else {
			setView("showGroups");
			setTitle("ALL GROUPS");
			setActivePage(1);
			setOffset(0);
		}
		setShowDetails(null);
		setColumnSelectedIndex(null);
	};

	const toggleAddGroup = () => {
		props.history.push({
			pathname: "/dashboard/groups",
			state: { action: "addGroup" },
		});
	};

	const toggleEditGroup = () => {
		props.history.push({
			pathname: "/dashboard/groups",
			state: { action: "editGroup", selectedGroup: selectedItem },
		});
	};

	const filterSearchBox = (value) => {
		setShowDetails(null);
		setColumnSelectedIndex(null);
		setSelectedItem(null);
		let tempData;
		if (view === "showAccounts") {
			if (value === "") {
				setFilterAttributes({ ...filter_attributes, accountName: null });
			} else {
				setFilterAttributes({ ...filter_attributes, accountName: value });
			}
		} else {
			if (value === "") {
				setGroupName(null);
			} else {
				setGroupName(value);
			}
		}
		setOffset(0);
		setActivePage(1);
	};

	const getFilters = (filters) => {
		console.log(filters);
		setFilterAttributes(filters);
		setOffset(0);
		setActivePage(1);
	};

	const paginationTotalItems = (type) => {
		if (type === "showAccounts") {
			if (accounts_counter.data) {
				return accounts_counter.data.countAccounts;
			} else {
				return 0;
			}
		} else if (type === "showGroups") {
			if (groups_counter.data) {
				return groups_counter.data.countGroups;
			} else {
				return 0;
			}
		}
	};

	useEffect(() => {
		if (view === "showGroups" || action !== null) {
			setUserGroupIconOpacity(0.3);
		} else {
			setUserGroupIconOpacity(1);
		}
	});

	let accountCards = accounts.map((account, index) => (
		<Card
			key={account.id}
			index={index + 1}
			account={account}
			cardType={"accountCard"}
			showDetails={handleShowDetails}
			columnSelectedIndex={columnSelectedIndex}
			action="showDetails"
			onDeleteUser={() => accountsData.refetch()}
		/>
	));

	const groupCards = groups.map((group, index) => (
		<Card
			key={group.id}
			index={index + 1}
			group={group}
			cardType={"groupCard"}
			action="showDetails"
			showDetails={handleShowDetails}
			columnSelectedIndex={columnSelectedIndex}
		/>
	));

	const plusIconMargin = view === "showGroups" ? 10 : 0;

	const getAccountFile = () => {
		fetch(`${process.env.REACT_APP_HOME_URL}/file/accounts`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");

				link.setAttribute("href", url);
				link.setAttribute("download", "accounts.xls");
				link.click();
			});
	};

	// if (accountsData.loading) return "Loading...";
	return (
		<div className={classes.Page}>
			<div className={classes.PageColumn}>
				<div className={classes.Title}>
					<span>{title}</span>
					{view === "showAccounts" ? (
						<div className="filterModalIcon" style={{ position: "relative" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div onClick={toggleShowModal}>
									<FourDots size={20} dotSize={7.37} />
								</div>
								<i
									className="fas fa-share-square"
									style={{
										color: "rgb(211, 156, 62)",
										fontSize: "20px",
										marginRight: "5px",
										cursor: "pointer",
									}}
									onClick={getAccountFile}
								/>
							</div>
							{showAccountsModal && (
								<AccountsFilterModal
									filters={filter_attributes}
									getData={getFilters}
									toggleShowModal={toggleShowModal}
									closeModal={closeModal}
								/>
							)}
						</div>
					) : null}
					{action === null ? (
						<span
							style={{
								color: "#D39C3E",
								fontSize: 20,
								marginLeft: plusIconMargin,
								cursor: "pointer",
							}}
							onClick={view === "showGroups" ? toggleAddGroup : addAccountHandler}
						>
							<i className="fas fa-plus-circle"></i>
						</span>
					) : null}

					<span
						style={{
							marginLeft: "auto",
							color: "#D39C3E",
							fontSize: 23,
							opacity: userGroupIconOpacity,
						}}
						onClick={toggleUserGroupIcon}
					>
						<i className="fas fa-users"></i>
					</span>
				</div>

				<SearchEngine
					placeholder={view === "showAccounts" ? "Search for an account" : "Search for a group"}
					extraStyle={{ margin: "30px 0" }}
					filter_search_filed={filterSearchBox}
					value={filter_attributes.accountName || null}
				/>

				{view === "showGroups" ? groupCards : accountCards}

				<div className={classes.Pagination}>
					<div className={classes.order_pagination}>
						<Pagination
							activePage={activePage}
							itemsCountPerPage={7}
							totalItemsCount={paginationTotalItems(view)}
							pageRangeDisplayed={9}
							prevPageText={null}
							nextPageText={null}
							activeLinkClass={classes.activePage}
							itemClass={classes.numbers}
							linkClass={classes.numbers}
							onChange={handlePageChange}
						/>
					</div>
				</div>
			</div>
			{showDetails === "accountCard" ? (
				<DetailsCard
					type="accountCard"
					columnSelectedIndex={columnSelectedIndex}
					selectedItem={selectedItem}
					handleShowDetails={handleShowDetails}
				/>
			) : showDetails === "groupCard" ? (
				<DetailsCard
					type="groupCard"
					selectedItem={selectedItem}
					columnSelectedIndex={columnSelectedIndex}
					toggleEditGroup={toggleEditGroup}
				/>
			) : null}
		</div>
	);
};

export default Accounts;
