import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classes from "./WelcomePage.module.scss";
import { useQuery } from "@apollo/react-hooks";
import GetCurrentUser from "../../queries/LoggedUser";

const WelcomePage = () => {
	const [currentUser, setCurrentUser] = useState(null);
	const history = useHistory();
	const handleOnClick = (page) => {
		history.push("/dashboard/" + page);
	};

	const getCurrentUser = useQuery(GetCurrentUser, {
		onCompleted: () => {
			if (!getCurrentUser.loading && !getCurrentUser.error) {
				setCurrentUser(getCurrentUser.data.currentUser);
			}
		},
	});

	const has_permission = (resource) => {
		return currentUser.role[resource];
	};

	if (currentUser) {
		console.log(has_permission("outgoingPayments"));
	}

	return (
		currentUser && (
			<div className={classes.WelcomePage}>
				<div className={classes.WelcomeContent}>
					<div className={classes.WelcomeText}>
						Welcome {currentUser.firstName + " " + currentUser.lastName}...
					</div>
					<div className={[classes.CardHeader, classes.CardHeaderText].join(" ")}>
						WHAT WOULD YOU LIKE TO MANAGE?
					</div>
					<div className={classes.Navigation}>
						{has_permission("accounts") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("accounts")}>
								<span>ACCOUNTS</span>
							</div>
						) : null}
						{has_permission("currencyTrader") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("orders")}>
								ORDERS
							</div>
						) : null}
						{has_permission("outgoingPayments") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("outgoingPayments")}>
								OUTGOING <br /> PAYMENTS
							</div>
						) : null}
						{has_permission("incomingPayments") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("incomingPayments")}>
								INCOMING <br /> PAYMENTS
							</div>
						) : null}
						{currentUser.role.name == "super technical admin" ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("cashManager")}>
								CASH <br /> MANAGER
							</div>
						) : null}
						{has_permission("tradingBook") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("tradingBook")}>
								TRADING <br /> BOOK
							</div>
						) : null}
						{has_permission("limitsSpreads") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("limitsAndSpreads")}>
								LIMITS & <br /> SPREADS
							</div>
						) : null}
						{has_permission("documents") ? (
							<div className={classes.NavItems} onClick={() => handleOnClick("documents")}>
								REGISTRATION <br />
								DOCUMENTS
							</div>
						) : null}
					</div>
				</div>
			</div>
		)
	);
};

export default WelcomePage;
