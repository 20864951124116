import React, { useState, useEffect } from "react";
import classes from "./IncomingPayments.module.scss";
import BankIcon from "./BankIcon";
import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";

var dateFormat = require("dateformat");
const TransactionCard = (props) => {
	const [selectCircleStatus, setToggleCircleStatus] = useState(false);
	useEffect(() => {
		setToggleCircleStatus(props.selected);
	}, [props.selected]);

	var status = "";
	var StatusCardSpace = null;
	var opacity = 1;
	var Bcolor = "#F1E0C4";

	if (props.data.paidToPortal) {
		status = "PAID";
	}

	if (props.data.lastNotifiedAt != null) {
		status = "NOTIFIED";
	}

	if (props.data.CI) {
		status = "DEFAULTED";
	}

	switch (status) {
		case "NOTIFIED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace}>
					<div className={classes.Description}>
						Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
					</div>
					<div className={classes.Text}>NOTIFIED</div>
				</div>
			);
			break;
		case "PAID":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					<div className={classes.Text}>PAID</div>
				</div>
			);
			opacity = 0.3;
			break;
		case "DEFAULTED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					{props.data.lastNotifiedAt ? (
						<div className={classes.Description}>
							Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
						</div>
					) : null}
					<div className={classes.Text}>
						DEFAULTED{" "}
						{props.data.type == "purchase" ? (
							<NumberFormat
								value={props.data.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={props.data.amountPaidCurrency === "ALL" ? " L" : null}
								prefix={
									props.data.amountPaidCurrency === "ALL"
										? null
										: getSymbolFromCurrency(props.data.amountPaidCurrency)
								}
							/>
						) : (
							<NumberFormat
								value={props.data.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={props.data.amountSoldCurrency === "ALL" ? " L" : null}
								prefix={
									props.data.amountSoldCurrency === "ALL"
										? null
										: getSymbolFromCurrency(props.data.amountSoldCurrency)
								}
							/>
						)}
					</div>
					<div className={classes.Description} style={{ textAlign: "right", fontSize: 14 }}>
						paid: {props.data.completedPayment}
					</div>
				</div>
			);
			opacity = 0.3;
			Bcolor = "#9A2020";
			break;
	}
	return (
		<>
			{!props.data.CI && (
				<div
					className={
						selectCircleStatus
							? classes.SelectCircle + " " + classes.selected
							: classes.SelectCircle
					}
					onClick={() => toggleSelect()}
				></div>
			)}
			<div className={classes.parentTransactionCard}>
				<div className={classes.StatusBlockContainer}>
					<div
						className={classes.StatusBlock}
						style={{ marginRight: 0, background: `${Bcolor}`, opacity: opacity }}
					></div>
				</div>
				<div className={classes.TransactionCard} style={{ opacity: opacity }}>
					<div style={{ display: "flex" }}>
						<div style={{ flex: 1 }}>
							<div className={classes.FirstRow}>
								<span>
									<BankIcon
										bank={{ bank_name: props.data.bankAccounts[0].bankName }}
										square={false}
									/>
								</span>
								<span style={{ fontSize: 15, marginLeft: 5 }}>
									{props.data.portfolio.user.globalName}
								</span>
							</div>
							<div style={{ fontSize: 20 }}>
								<span>
									{props.data.type === "purchase"
										? props.data.amountPaidCents
										: props.data.amountSoldCents}
								</span>
							</div>
							<div style={{ fontSize: 12 }}>
								<span>IBAN: {props.data.bankAccounts[0].iban}</span>
							</div>
						</div>

						{StatusCardSpace}
					</div>
				</div>
			</div>
		</>
	);
	function toggleSelect() {
		if (!selectCircleStatus) {
			props.addToSelected(props.data);
		} else {
			props.removeFromSelected(props.data);
		}
		setToggleCircleStatus(!selectCircleStatus);
	}
};

export default TransactionCard;

export const TransactionCardMatches = (props) => {
	const [selectCircleStatus, setToggleCircleStatus] = useState(false);
	useEffect(() => {
		setToggleCircleStatus(props.selected);
	}, [props.selected]);

	var status = "";
	var StatusCardSpace = null;
	var opacity = 1;
	var Bcolor = "#F1E0C4";

	if (props.data.paidToPortal) {
		status = "PAID";
	}

	if (props.data.lastNotifiedAt != null) {
		status = "NOTIFIED";
	}

	if (props.data.CI) {
		status = "DEFAULTED";
	}

	switch (status) {
		case "PAID":
			opacity = 0.3;
			break;
		case "NOTIFIED":
			opacity = 1;
			break;
		case "DEFAULTED":
			opacity = 0.3;
			Bcolor = "#9A2020";
			break;
	}

	const lastIndex = props.data.transactions.length - 1;
	var mini_StatusCardSpace = null;
	var matches_map_list = props.data.transactions.map((index, key) => {
		var mini_status = "";
		var mini_opacity = 1;
		var mini_Bcolor = "#F1E0C4";

		var mini_data = {};
		if (props.data.type == "sale") {
			mini_data = index.purchaseOrder;
		} else {
			mini_data = index.saleOrder;
		}

		if (mini_data.paidToPortal) {
			mini_status = "PAID";
		}

		if (mini_data.lastNotifiedAt != null) {
			mini_status = "NOTIFIED";
		}

		if (mini_data.CI) {
			mini_status = "DEFAULTED";
		}
		switch (mini_status) {
			case "NOTIFIED":
				break;
			case "PAID":
				mini_opacity = 0.3;
				break;
			case "DEFAULTED":
				mini_opacity = 0.3;
				mini_Bcolor = "#9A2020";
				break;
		}
		if (key === lastIndex) {
			switch (mini_status) {
				case "NOTIFIED":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace}>
							<div className={classes.Description}>
								Last notice sent: {dateFormat(mini_data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
							</div>
							<div className={classes.Text}>NOTIFIED</div>
						</div>
					);
					break;
				case "PAID":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
							<div className={classes.Text}>PAID</div>
						</div>
					);
					mini_opacity = 0.3;
					break;
				case "DEFAULTED":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
							{mini_data.lastNotifiedAt ? (
								<div className={classes.Description}>
									Last notice sent: {dateFormat(mini_data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
								</div>
							) : null}
							<div className={classes.Text}>
								DEFAULTED{" "}
								{mini_data.type == "purchase" ? (
									<NumberFormat
										value={mini_data.missingPayment}
										thousandSeparator={true}
										displayType={"text"}
										suffix={mini_data.amountPaidCurrency === "ALL" ? " L" : null}
										prefix={
											mini_data.amountPaidCurrency === "ALL"
												? null
												: getSymbolFromCurrency(mini_data.amountPaidCurrency)
										}
									/>
								) : (
									<NumberFormat
										value={mini_data.missingPayment}
										thousandSeparator={true}
										displayType={"text"}
										suffix={mini_data.amountSoldCurrency === "ALL" ? " L" : null}
										prefix={
											mini_data.amountSoldCurrency === "ALL"
												? null
												: getSymbolFromCurrency(mini_data.amountSoldCurrency)
										}
									/>
								)}
							</div>
							<div className={classes.Description} style={{ textAlign: "right", fontSize: 14 }}>
								paid: {mini_data.completedPayment}
							</div>
						</div>
					);
					mini_opacity = 0.3;
					mini_Bcolor = "#9A2020";
					break;
			}
			mini_StatusCardSpace = (
				<div
					className={classes.TransactionCard}
					style={{ borderLeft: 0, flex: 1, opacity: mini_opacity }}
					onClick={() => {
						props.parentSetState.setDetailsCard({
							show: true,
							item: props.data,
							expand: true,
							activeItem: mini_data,
						});
					}}
				>
					<div style={{ display: "flex" }}>
						<div style={{ flex: 1 }}>
							<div className={classes.FirstRow}>
								<span>
									<BankIcon
										bank={{ bank_name: mini_data.bankAccounts[0].bankName }}
										square={false}
									/>
								</span>
								<span style={{ fontSize: 15, marginLeft: 5 }}>
									{mini_data.portfolio.user.globalName}
								</span>
							</div>
							<div style={{ fontSize: 20 }}>
								<span>
									{mini_data.type === "purchase"
										? mini_data.amountPaidCents
										: mini_data.amountSoldCents}
								</span>
							</div>
							<div style={{ fontSize: 12 }}>
								<span>IBAN: {mini_data.bankAccounts[0].iban}</span>
							</div>
						</div>
						{mini_StatusCardSpace}
					</div>
				</div>
			);
			return (
				<div
					className={classes.StatusBlock}
					style={{ marginRight: 0, background: `${mini_Bcolor}`, opacity: mini_opacity }}
					key={key}
					onClick={() => {
						props.parentSetState.setDetailsCard({ show: true, item: props.data, expand: false });
					}}
				></div>
			);
		}
		return (
			<div
				className={classes.StatusBlock}
				key={key}
				style={{ background: `${mini_Bcolor}`, opacity: mini_opacity }}
				onClick={() => {
					props.parentSetState.setDetailsCard({ show: true, item: props.data, expand: true });
				}}
			></div>
		);
	});

	return (
		<>
			<div
				className={
					selectCircleStatus ? classes.SelectCircle + " " + classes.selected : classes.SelectCircle
				}
				onClick={() => toggleSelect()}
			></div>
			<div className={classes.parentTransactionCard}>
				<div className={classes.StatusBlockContainer}>
					<div
						className={classes.StatusBlock}
						style={{ marginRight: 0, background: `${Bcolor}`, opacity: opacity }}
						onClick={() => {
							props.parentSetState.setDetailsCard({ show: true, item: props.data, expand: true });
						}}
					></div>
				</div>
				<div
					className={classes.TransactionCard}
					style={{ opacity: opacity, borderRadius: 0, borderRight: 0, width: 300 }}
					onClick={() => {
						props.parentSetState.setDetailsCard({
							show: true,
							item: props.data,
							expand: true,
							activeItem: props.data,
						});
					}}
				>
					<div className={classes.FirstRow}>
						<span>
							<BankIcon bank={{ bank_name: props.data.bankAccounts[0].bankName }} square={false} />
						</span>
						<span style={{ fontSize: 15, marginLeft: 5 }}>
							{props.data.portfolio.user.globalName}
						</span>
					</div>
					<div style={{ fontSize: 20 }}>
						<span>
							{props.data.type === "purchase"
								? props.data.amountPaidCents
								: props.data.amountSoldCents}
						</span>
					</div>
					<div style={{ fontSize: 12 }}>
						<span>IBAN: {props.data.bankAccounts[0].iban}</span>
					</div>
				</div>
				<div className={classes.StatusBlockContainer}>{matches_map_list}</div>
				{mini_StatusCardSpace}
			</div>
		</>
	);
	function toggleSelect() {
		if (!selectCircleStatus) {
			props.addToSelected(props.data);
		} else {
			props.removeFromSelected(props.data);
		}
		setToggleCircleStatus(!selectCircleStatus);
	}
};
