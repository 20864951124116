import gql from "graphql-tag";

export default gql`
	query ($attributes: FilterAccountsInput) {
		users(attributes: $attributes) {
			id
			firstName
			lastName
			guarantee
			globalName
			spreads {
				id
				saleSpread
			}
			servicePoint {
				commercialName
			}
			limitException
			tradingDailyLimit
		}
	}
`;
