import React, { useState, useEffect } from "react";
import classes from "./CurrencyTrader.module.scss";
import getSymbolFromCurrency from "currency-symbol-map";
import NumberFormat from "react-number-format";
import BankIcon from "./BankIcon";
import CardContent from "./CardContent";

const TransactionCard = (props) => {
	const items = props.items;
	const parentTransaction = props.parentTransaction;
	const [openAccordion, setOpenAccordion] = useState(props.openAccordion);
	const [cardWidth, setCardWidth] = useState(593);
	const [selectedTransaction, setSelectedTransaction] = useState(
		!props.openAccordion ? props.selectedTransaction : null
	);
	const [showDetails, setShowDetails] = useState(selectedTransaction ? true : false);

	const showDetailsCard = (transaction) => {
		setShowDetails(!showDetails);
		setSelectedTransaction(transaction);
		props.showDetailsCard(transaction, false, parentTransaction);
		if (showDetails) setOpenAccordion(false);
	};

	const toggleAccordion = (lastItem) => {
		setOpenAccordion(!openAccordion);
		props.toggleAccordion(lastItem, !openAccordion, parentTransaction);
	};

	let cardStyle = {
		purchase: {
			background: "#285A64",
		},
		sale: {
			background: "#BFD2C7",
		},
		defaulted: {
			background: "#9a2020",
		},
	};

	let singleCardStyle = {
		purchase: {
			borderLeft: "26px solid #285A64",
		},
		sale: {
			borderLeft: "26px solid #BFD2C7",
		},
		defaulted: {
			borderLeft: "26px solid #9a2020",
		},
	};

	const lastItem = items[items.length - 1];

	const length = items.length;
	const closeCardsHTML = items.map((transaction, index) => {
		if (length !== index + 1) {
			return (
				<div
					key={index}
					className={classes.Card}
					style={transaction.CI ? cardStyle["defaulted"] : cardStyle[transaction.type]}
					onClick={() => toggleAccordion(lastItem)}
				></div>
			);
		} else {
			return (
				<React.Fragment key={index}>
					<div
						key={index}
						className={classes.Card}
						style={transaction.CI ? cardStyle["defaulted"] : cardStyle[transaction.type]}
						onClick={() => toggleAccordion(lastItem)}
					></div>
					<div className={classes.Content} onClick={() => showDetailsCard(transaction)}>
						<CardContent transaction={transaction} />
					</div>
				</React.Fragment>
			);
		}
	});

	useEffect(() => {
		if (openAccordion) {
			setCardWidth((items.length - 1) * 191 + 397);
		} else setCardWidth(593);
		if (showDetails) setCardWidth(593);
	});

	const openCardsHTML = items.map((transaction, index) => {
		if (length !== index + 1) {
			return (
				<React.Fragment key={index}>
					<div
						className={classes.Card}
						style={transaction.CI ? cardStyle["defaulted"] : cardStyle[transaction.type]}
						onClick={() => toggleAccordion(lastItem)}
					></div>
					<div className={classes.OpenCard} onClick={() => showDetailsCard(transaction)}>
						<div className={classes.FirstRow} style={{ alignItems: "center" }}>
							<div
								className={
									transaction.type === "purchase" ? classes.PurchaseIcon : classes.SalesIcon
								}
							>
								<div>{transaction.type === "purchase" ? "P" : "S"}</div>
							</div>
							{transaction.CI ? (
								<div className={classes.CI_Icon}>
									<div>CI</div>
								</div>
							) : (
								<BankIcon transaction={transaction} />
							)}
							<span>
								<b>{transaction.type === "purchase" ? "Purchase" : "Sale"}</b>
							</span>
						</div>
						<div style={{ fontSize: 20 }}>
							<NumberFormat
								value={transaction.amount}
								displayType={"text"}
								prefix={getSymbolFromCurrency(
									transaction.amountPurchasedCurrency || transaction.amountSoldCurrency
								)}
								thousandSeparator=","
								decimalSeparator="."
							/>
						</div>
						<div style={{ fontSize: 12 }}>
							{transaction.portfolio.user.globalName}:{" "}
							<NumberFormat
								value={transaction.portfolio.user.tradingDailyLimit}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
							/>{" "}
							L
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment key={index}>
					<div
						className={classes.Card}
						style={transaction.CI ? cardStyle["defaulted"] : cardStyle[transaction.type]}
						onClick={() => toggleAccordion(lastItem)}
					></div>
					<div className={classes.Content} onClick={() => showDetailsCard(transaction)}>
						<CardContent transaction={transaction} />
					</div>
				</React.Fragment>
			);
		}
	});

	return (
		<div className={classes.MultipleCard} style={{ width: cardWidth }}>
			{!showDetails ? (
				<>{openAccordion ? openCardsHTML : closeCardsHTML}</>
			) : (
				<div
					className={classes.SingleCard}
					style={
						selectedTransaction.CI
							? singleCardStyle["defaulted"]
							: singleCardStyle[selectedTransaction.type]
					}
					onClick={() => showDetailsCard(lastItem)}
				>
					<CardContent transaction={selectedTransaction} />
				</div>
			)}
		</div>
	);
};

export default TransactionCard;
