import React, { useState, useEffect } from "react";
import classes from "../Accounts.module.scss";
import ServicePoint from "./ServicePoint";
import BusinessAccount from "./BusinessAccount";
import PrivateAccount from "./PrivateAccount";
import MarketViewer from "./MarketViewer";
import ApproveUser from "../../../../mutations/UserManagement/ApproveUser";
import SuspendUser from "../../../../mutations/UserManagement/SuspendUser";
import DisableUser from "../../../../mutations/UserManagement/DisableUser";
import GetAccountsQuery from "../../../../queries/Accounts/Accounts";
import { useMutation } from "react-apollo";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const UserInfo = (props) => {
	const [account, setAccount] = useState(props.account);

	useEffect(() => {
		setAccount(props.account);
	}, [props.account]);

	const [suspendUser] = useMutation(SuspendUser, { refetchQueries: [{ query: GetAccountsQuery }] });

	const handleSuspendUser = () => {
		suspendUser({
			variables: {
				user_id: account.id,
			},
		})
			.then((result) => console.log("Success"))
			.catch((error) => console.log(error));
	};

	const [approveUser] = useMutation(ApproveUser, { refetchQueries: [{ query: GetAccountsQuery }] });
	const handleApproveUser = () => {
		approveUser({
			variables: {
				user_id: account.id,
			},
		})
			.then((result) => console.log("Success"))
			.catch((error) => console.log(error));
	};

	const [disableUser] = useMutation(DisableUser, { refetchQueries: [{ query: GetAccountsQuery }] });
	const handleDisableUser = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.CustomConfirmationDialog}>
						<h3>Are you sure you want to delete this user?</h3>
						<button className={classes.WhiteButton} onClick={onClose}>
							No
						</button>
						<button
							className={classes.YellowButton}
							onClick={() => {
								deleteUser();
								onClose();
							}}
						>
							Yes
						</button>
					</div>
				);
			},
		});
	};
	const deleteUser = () => {
		disableUser({
			variables: {
				user_id: account.id,
			},
		})
			.then((result) => {
				console.log("Success");
				props.handleShowDetails(null, null, null);
			})
			.catch((error) => console.log(error));
	};

	switch (account.typology) {
		case "service_point": {
			return (
				<ServicePoint
					account={account}
					suspendUser={handleSuspendUser}
					approveUser={handleApproveUser}
					disableUser={handleDisableUser}
				/>
			);
		}
		case "business_account": {
			return (
				<BusinessAccount
					account={account}
					suspendUser={handleSuspendUser}
					approveUser={handleApproveUser}
					disableUser={handleDisableUser}
					typology={"Business Account"}
				/>
			);
		}
		case "bank_account": {
			return (
				<BusinessAccount
					account={account}
					suspendUser={handleSuspendUser}
					approveUser={handleApproveUser}
					disableUser={handleDisableUser}
					typology={"Bank Account"}
				/>
			);
		}
		case "private_account": {
			return (
				<PrivateAccount
					account={account}
					suspendUser={handleSuspendUser}
					approveUser={handleApproveUser}
					disableUser={handleDisableUser}
				/>
			);
		}
		case "market_viewer": {
			return (
				<MarketViewer
					account={account}
					suspendUser={handleSuspendUser}
					approveUser={handleApproveUser}
					disableUser={handleDisableUser}
				/>
			);
		}
		default:
		//do nothing
	}
};

export default UserInfo;
