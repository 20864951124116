import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

const Client = function () {
	const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

	const authLink = setContext((_, { headers }) => {
		const token = localStorage.getItem("token");

		return {
			headers: {
				...headers,
				authorization: `Bearer ${token}`,
			},
		};
	});

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			fragmentMatcher: new IntrospectionFragmentMatcher({
				introspectionQueryResultData: {
					__schema: {
						types: [],
					},
				},
			}),
		}),
	});

	return client;
};

export default Client;
