import gql from "graphql-tag";

export default gql`
	query ($limit: Int, $offset: Int, $attributes: FilterAccountsInput) {
		users(limit: $limit, offset: $offset, attributes: $attributes) {
			id
			firstName
			lastName
			globalName
			typology
			servicePoint {
				commercialName
			}
			tradingDailyLimit
			createdAt
			balances(currency: "ALL") {
				averageTransactionValue
				currency
				limit
				numberOfTransactions
				totalBalance
				totalEarnings
				totalPurchaseValue
				totalSaleValue
				numberOfSales
				numberOfPurchases
			}
			group {
				id
				name
			}
		}
	}
`;
