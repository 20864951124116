import React, { useState } from "react";
import classes from "./Login.module.scss";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import sendEmailToRecoverPassword from "../../mutations/Login/SendEmailToRecoverPassword";

const ForgetPasswordTool = () => {
	const [email, setEmail] = useState("");
	const [showForm, setShowForm] = useState(true);
	const [message, setMessage] = useState(null);
	const history = useHistory();
	const [recoverPassword] = useMutation(sendEmailToRecoverPassword);

	const handleNextOnClick = () => {
		recoverPassword({
			variables: {
				email: email,
			},
		})
			.then((data) => {
				const status = data.data.sendEmailToRecoverPassword.success;
				if (status) setShowForm(false);
				else
					setMessage(
						<p style={{ color: "#992020" }}>There is no account registered to that email</p>
					);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleCloseOnClick = () => {
		history.push("/login");
	};

	const handleOnChange = (event) => {
		setEmail(event.target.value);
	};

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleNextOnClick();
		}
	};

	const enterEmailForm = (
		<div className={classes.CardContent}>
			<div className={classes.SubText}>Please enter your email</div>
			{message}
			<form className={classes.LoginForm}>
				<input
					className={classes.TextInput}
					placeholder="Email"
					value={email}
					onChange={handleOnChange}
					onKeyPress={handleOnKeyPress}
				/>
			</form>
			<button className={classes.YellowButton} onClick={handleNextOnClick}>
				NEXT
			</button>
		</div>
	);

	const infoText = (
		<div className={classes.CardContent}>
			<div className={classes.ForgotPasswordInfo}>
				An email has been sent to the address provided <br />
				with instructions on how to <br />
				change your password.
			</div>
			<button className={classes.YellowButton} onClick={handleCloseOnClick}>
				CLOSE
			</button>
		</div>
	);

	return (
		<div>
			<div className={classes.Card}>
				<div className={classes.CardHeader}> </div>
				{showForm ? enterEmailForm : infoText}
			</div>
		</div>
	);
};

export default ForgetPasswordTool;
