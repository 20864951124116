import orders from "../../../queries/CashManager/Orders";
import counter from "../../../queries/CashManager/CountCashManager";
import { API } from "aws-amplify";
import Amplify from "aws-amplify";

Amplify.configure({
	API: {
		graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
		graphql_headers: async () => ({
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		}),
	},
});

export async function CashManagerQuery(variables) {
	let order_list = await API.graphql({
		query: orders,
		variables: { attributes: variables },
	});

	return order_list;
}

export async function CounterQuery(variables) {
	let cash_manager_counter = await API.graphql({
		query: counter,
		variables: { attributes: variables },
	});
	return cash_manager_counter;
}
