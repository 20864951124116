import React from "react";
import Language from "./Language/Language";
import NavigationItems from "./NavigationItems/NavigationItems";
import classes from "./Toolbar.module.css";

const toolbarLogin = (props) => (
	<header className={classes.Toolbar}>
		{/* <div className={classes.Language}>
            <Language/>
        </div> */}
		<nav className={classes.NavigationItems}>
			<NavigationItems items={[]} />
		</nav>
	</header>
);

export default toolbarLogin;
