import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-apollo";
import classes from "./MainPage.module.scss";
import STAConfirmationMutation from "../../../mutations/RoleManagement/SupertechConfirmation";
import GetCurrentUser from "../../../queries/LoggedUser";

const STAConfirmationPopup = (props) => {
	const users = props.users;
	const STAUsers = users.filter((user) => user.role.name === "super technical admin");
	let tempState = {};
	STAUsers.forEach((user) => {
		tempState["password" + user.id] = "";
	});
	const [passwordList, setPasswordList] = useState(tempState);

	const handlePasswordChange = (event, userId) => {
		setPasswordList({ ...passwordList, ["password" + userId]: event.target.value });
	};

	const HTML = STAUsers.map((user, index) => (
		<div key={index}>
			<div style={{ marginBottom: 20, display: "flex" }}>
				<div style={{ width: "30%" }}>
					<span style={{ marginRight: 15 }}>
						{" "}
						<b>{user.firstName + " " + user.lastName}</b>{" "}
					</span>
					<input type="hidden" id={"userId" + user.id} name={"userId" + user.id} value={user.id} />
				</div>
				<div>
					<input
						type="password"
						id={"password" + user.id}
						name={"password" + user.id}
						value={passwordList["password" + user.id]}
						placeholder="Enter password"
						className={classes.TextInput}
						style={{ width: 169 }}
						onChange={(event) => handlePasswordChange(event, user.id)}
					/>
				</div>
			</div>
		</div>
	));

	const [confirmationMutation] = useMutation(STAConfirmationMutation, {
		refetchQueries: [{ query: GetCurrentUser }],
	});
	const [message, setMessage] = useState("");
	const [showMessage, setShowMessage] = useState(false);

	const STAConfirmation = () => {
		let supertechUsers = [];
		for (let key in passwordList) {
			if (passwordList.hasOwnProperty(key)) {
				let value = passwordList[key];
				let id = parseInt(key.replace("password", ""));
				supertechUsers.push({ id: id, password: value });
			}
		}

		confirmationMutation({
			variables: {
				supertech_users: supertechUsers,
			},
		})
			.then((result) => {
				if (!result.data.supertechConfirmation.success) {
					setMessage("Incorrect password!");
					setShowMessage(true);
				} else {
					props.toggleSTAConfirmationPopup();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					props.toggleSTAConfirmationPopup();
				}
			};

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	return (
		<div className={classes.STAConfirmationPopup}>
			<div className={classes.Container} ref={wrapperRef}>
				<div className={classes.PopupContainer}>
					<div className={classes.Title}>Super Technical Admin Confirmation</div>

					{showMessage && (
						<div className={classes.Message} style={{ marginBottom: 20 }}>
							{message}
						</div>
					)}

					{HTML}

					<div>
						<button className={classes.WhiteButton} onClick={props.toggleSTAConfirmationPopup}>
							CANCEL
						</button>
						<button className={classes.YellowButton} onClick={STAConfirmation}>
							CONFIRM
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default STAConfirmationPopup;
