import gql from "graphql-tag";

export default gql`
	query {
		standardLimits {
			id
			range
			limit
		}
	}
`;
