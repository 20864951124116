import React, { Component } from "react";
import classes from "./Login.module.css";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import { graphql } from "react-apollo";
import mutation from "../../mutations/Login/SendOtp";

class VerificationTool extends Component {
	state = {
		orderForm: {
			email: {
				elementType: "radio",
				elementConfig: {
					type: "radio",
					name: "verification ",
					value: "email",
				},
				description: "Email (ea*****@gmail.com)",
			},
			text: {
				elementType: "radio",
				elementConfig: {
					type: "radio",
					name: "verification ",
					value: "text",
				},
				description: "Text (***-***-6473)",
			},
		},
		formIsValid: false,
		message: null,
	};

	inputChangedHandler = () => {
		this.setState({ formIsValid: true });
	};

	sendHandler = () => {
		this.props.mutate({}).then((res) => {});
		this.props.history.replace({
			pathname: "/login/code",
		});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.orderForm) {
			formElementsArray.push({
				id: key,
				config: this.state.orderForm[key],
			});
		}

		const email = this.state.orderForm.email;
		const text = this.state.orderForm.text;
		const intro =
			this.props.match.params.code === "newcode" ? (
				<p className={classes.requestNewCode}> Request a new code</p>
			) : (
				<p className={classes.verificationHeader}>
					How would you like to receive <br /> Your verification code?
				</p>
			);

		let form = (
			<form>
				<div className={classes.Components4}>
					<div className={classes.Head}> </div>
					<div className={classes.verificationContent}>
						{intro}

						{this.state.message}

						<div className={classes.verificationInputs}>
							<Input
								elementType={email.elementType}
								elementConfig={email.elementConfig}
								changed={() => this.inputChangedHandler()}
								description={email.description}
							/>

							<Input
								elementType={text.elementType}
								elementConfig={text.elementConfig}
								changed={() => this.inputChangedHandler()}
								description={text.description}
							/>
						</div>
						<Button clicked={() => this.sendHandler()} extraStyle={classes.closeButton}>
							SEND
						</Button>
					</div>
				</div>
			</form>
		);

		return <div>{form}</div>;
	}
}

export default graphql(mutation)(VerificationTool);
