import React, { useEffect, useState } from "react";
import FourDots from "../../UI/Icons/FourDots/FourDots";
import SearchEngine from "../../UI/SearchEngine/SearchEngine";
import FilterModal from "../Modals/Payments/FilterModal";
import classes from "./IncomingPayments.module.scss";
import TransactionCard, { TransactionCardMatches } from "./TransactionCard";

const PageColumn = (props) => {
	var isMatching = props.state.isMatching;

	const currencies_data = [
		{ value: "ALL", name: "ALL (Lek)", sign: "L", isChecked: false, type: "Currency" },
		{ value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false, type: "Currency" },
		{ value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false, type: "Currency" },
		{ value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false, type: "Currency" },
		{ value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false, type: "Currency" },
	];
	const banks_data = [
		{ name: "Union Bank", isChecked: false, type: "Bank" },
		{ name: "Intesa SanPaolo", isChecked: false, type: "Bank" },
		{ name: "Raiffeisen", isChecked: false, type: "Bank" },
		{ name: "Alpha Bank", isChecked: false, type: "Bank" },
		{ name: "American Bank of Investments", isChecked: false, type: "Bank" },
		{ name: "BKT", isChecked: false, type: "Bank" },
		{ name: "Credins Bank", isChecked: false, type: "Bank" },
		{ name: "OTP Bank", isChecked: false, type: "Bank" },
		{ name: "ProCredit Bank", isChecked: false, type: "Bank" },
		{ name: "Tirana Bank", isChecked: false, type: "Bank" },
		{ name: "United Bank of Albania", isChecked: false, type: "Bank" },
		{ name: "First Investment Bank", isChecked: false, type: "Bank" },
	];
	const status_data = [
		{ name: "Paid", type: "Status", isChecked: false },
		{ name: "Unpaid", type: "Status", isChecked: false },
		{ name: "Default", type: "Status", isChecked: false },
	];
	const [hrefLink, setHrefLink] = useState("");
	const [hrefLinkExportAll, setHrefLinkExportAll] = useState("");

	var filter_obj = {
		currencies: currencies_data,
		banks: banks_data,
		status: status_data,
	};
	const [view, setView] = useState("showPayments");

	const handlePageChange = (pageNumber) => {
		props.actions.paginationClicked(pageNumber);
	};

	const paginationTotalItems = (type) => {
		if (type === "showPayments") {
			if (props.orders_counter) {
				return props.orders_counter;
			} else {
				return 0;
			}
		}
	};

	const [selectedAll, setSelectedAll] = useState(false);
	const [modalDisplay, setModalDisplay] = useState(false);
	const [filter, setFilter] = useState(filter_obj);

	useEffect(() => {
		if (props.state.pageSelectAll.hasOwnProperty(props.state.activePage)) {
			setSelectedAll(props.state.pageSelectAll[props.state.activePage]);
		} else {
			setSelectedAll(false);
		}
	}, [props.state.pageSelectAll]);

	const getIncomingPaymentsFile = (all) => {
		fetch(all ? hrefLinkExportAll : hrefLink, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");

				link.setAttribute("href", url);
				link.setAttribute("download", all ? "incoming_payments_all.xls" : "incoming_payments.xls");
				link.click();
			});
	};

	useEffect(() => {
		var filters = props.state.filters;
		var selectedItems = props.state.selectedItems;
		var string = `${process.env.REACT_APP_HOME_URL}file/incoming_payments?user_id=${props.state.user_id}`;
		setHrefLinkExportAll(string);
		if (filters.banks) {
			string += "&banks=[";
			filters.banks.map((bank, key) => {
				string += '"';
				string += bank;
				string += '",';
			});
			string = string.substring(0, string.length - 1);
			string += "]";
		}
		if (filters.currency) {
			string += "&currency=[";
			filters.currency.map((currency, key) => {
				string += '"';
				string += currency;
				string += '",';
			});
			string = string.substring(0, string.length - 1);
			string += "]";
		}
		if (Object.keys(selectedItems).length > 0) {
			string += "&numbers=[";
			for (const [key, value] of Object.entries(selectedItems)) {
				string += '"';
				string += value.number;
				string += '",';
			}
			string = string.substring(0, string.length - 1);
			string += "]";
		}
		setHrefLink(string);
	}, [props.state.selectedItems, props.state.user_id, props.state.filters]);

	var selectCircleStatus = "";
	if (selectedAll) {
		selectCircleStatus = " " + classes.selected;
	}
	var incoming_list = props.data.map((index, key) => {
		var selectedIt = props.state.selectedItems[index.id];
		var selected = false;
		if (selectedIt) {
			selected = true;
		}
		if (isMatching) {
			return (
				<TransactionCardMatches
					key={key}
					data={index}
					parentSetState={props.setState}
					selected={selected}
					addToSelected={props.actions.addToSelected}
					removeFromSelected={props.actions.removeFromSelected}
				/>
			);
		} else {
			return (
				<TransactionCard
					key={key}
					data={index}
					selected={selected}
					addToSelected={props.actions.addToSelected}
					removeFromSelected={props.actions.removeFromSelected}
				/>
			);
		}
	});
	var chips_list = props.state.chips.map((index, key) => {
		return <Chip item={index} removeThisChips={() => removeThisChips(index)} key={key} />;
	});
	return (
		<div className={classes.PageColumn} style={{ width: "100%", minWidth: "fit-content" }}>
			<div className={classes.Title}>
				<span>{isMatching ? "PAYMENT MATCHING" : "INCOMING PAYMENTS"}</span>
				<div className="filterModalIcon" style={{ position: "relative" }}>
					<div onClick={() => setModalDisplay(!modalDisplay)} style={{ cursor: "pointer" }}>
						<FourDots size={18} dotSize={7.37} />
					</div>
					{!modalDisplay ? null : (
						<FilterModal
							currencies={filter.currencies}
							banks={filter.banks}
							status={filter.status}
							closeModal={closeModal}
							filterRecords={(state) => filterPayments(state)}
						/>
					)}
				</div>

				<i
					className="fas fa-share-square fa-lg"
					style={{ color: "#D39C3E", cursor: "pointer" }}
					onClick={() => getIncomingPaymentsFile(false)}
				/>

				<button
					className={classes.YellowButton}
					style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
					onClick={() => getIncomingPaymentsFile(true)}
				>
					Export All
				</button>
			</div>

			<div
				className={classes.SelectCircle + selectCircleStatus}
				onClick={() => {
					selectAll();
				}}
			></div>
			<SearchEngine
				placeholder="Search for an order"
				extraStyle={{ margin: "30px 0" }}
				filter_search_filed={props.actions.filterSearchBox}
			/>

			<div style={{ marginBottom: 30 }}>{chips_list}</div>
			{incoming_list}
			<div className={classes.Pagination}>
				<div className={classes.order_pagination}>
					{/* <Pagination
          activePage={props.state.activePage}
          itemsCountPerPage={7}
          totalItemsCount={paginationTotalItems(view)}
          pageRangeDisplayed={9}
          prevPageText={null}
          nextPageText={null}
          activeLinkClass={classes.activePage}
          itemClass={classes.numbers}
          linkClass={classes.numbers}
          onChange={handlePageChange}
        /> */}
				</div>
			</div>
		</div>
	);

	function selectAll() {
		if (!selectedAll) {
			props.actions.selectedAll();
		} else {
			props.actions.deselectedAll();
		}
	}
	function closeModal() {
		setModalDisplay(false);
	}
	function filterPayments(state) {
		var banks_filter = state.banks.filter((x) => x.isChecked == true);
		var currencies_filter = state.currencies.filter((x) => x.isChecked == true);
		var status_filter = state.status.filter((x) => x.isChecked == true);
		var chips_filter = banks_filter.concat(currencies_filter);
		chips_filter = chips_filter.concat(status_filter);
		props.setState.setChips(chips_filter);
		props.actions.addFilters(chips_filter);
		setFilter({ currencies: state.currencies, banks: state.banks, status: state.status });
	}
	function removeThisChips(chip) {
		var chips_list = props.state.chips;
		for (var i = 0; i < chips_list.length; i++) {
			if (chips_list[i].name === chip.name) {
				chips_list.splice(i, 1);
				break;
			}
		}
		props.setState.setOffset(0);
		props.actions.addFilters(chips_list);

		var list = Object.assign({}, filter);
		switch (chip.type) {
			case "Bank":
				for (var i = 0; i < list.banks.length; i++) {
					if (list.banks[i].name === chip.name) {
						list.banks[i].isChecked = false;
						break;
					}
				}
				break;
			case "Currency":
				for (var i = 0; i < list.currencies.length; i++) {
					if (list.currencies[i].name === chip.name) {
						list.currencies[i].isChecked = false;
						break;
					}
				}
				break;
			case "Status":
				for (var i = 0; i < list.currencies.length; i++) {
					if (list.status[i].name === chip.name) {
						list.status[i].isChecked = false;
						break;
					}
				}
				break;
		}
		setFilter(list);
	}
};

export default PageColumn;

const Chip = (props) => {
	return (
		<div className={classes.ChipContainer}>
			<i onClick={() => props.removeThisChips(props.item)} className="fas fa-times-circle"></i>{" "}
			<b>
				{props.item.type}: {props.item.name}
			</b>
		</div>
	);
};
