import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import classes from "./Dashboard.module.scss";
import Accounts from "./Accounts/Accounts";
import GroupManagement from "./Groups/GroupManagement";
import TradingBook from "./TradingBook/TradingBook";
import CashManager from "./CashManager/CashManager";
import LimitsAndSpreads from "./LimitsAndSpreads/LimitsAndSpreads";
import CurrencyTrader from "./CurrencyTrader/CurrencyTrader";
import Documents from "./Documents/Documents";
import ToolbarLogout from "../Header/ToolbarLogout";
import Backend from "react-dnd-html5-backend";
import { DndProvider, useDrop } from "react-dnd";
import OutgoingPayments from "./OutgoingPayments/OutgoingPayments";
import IncomingPayments from "./IncomingPayments/IncomingPayments";
import { useQuery } from "@apollo/react-hooks";
import GetCurrentUser from "../../queries/LoggedUser";

const Dashboard = (props) => {
	const history = useHistory();
	const [currentUser, setCurrentUser] = useState(null);
	let pathname = props.location.pathname.split("/")[2];

	const handleOnClick = (page) => {
		history.push("/dashboard/" + page);
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const getCurrentUser = useQuery(GetCurrentUser, {
		onCompleted: () => {
			if (!getCurrentUser.loading && !getCurrentUser.error) {
				setCurrentUser(getCurrentUser.data.currentUser);
			}
		},
	});

	const has_permission = (resource) => {
		return currentUser.role[resource];
	};

	return (
		currentUser && (
			<div>
				<ToolbarLogout />
				<DndProvider backend={Backend}>
					<div className={classes.Dashboard}>
						<nav className={classes.Sidebar}>
							{has_permission("accounts") ? (
								<div
									className={classes.NavItems}
									style={
										pathname === "accounts" || pathname === "groups" ? { opacity: "0.3" } : null
									}
									onClick={() => handleOnClick("accounts")}
								>
									ACCOUNTS
								</div>
							) : null}
							{has_permission("currencyTrader") ? (
								<div
									className={classes.NavItems}
									style={pathname === "orders" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("orders")}
								>
									ORDERS
								</div>
							) : null}
							{has_permission("outgoingPayments") ? (
								<div
									className={classes.NavItems}
									style={pathname === "outgoingPayments" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("outgoingPayments")}
								>
									OUTGOING <br /> PAYMENTS
								</div>
							) : null}
							{has_permission("incomingPayments") ? (
								<div
									className={classes.NavItems}
									style={pathname === "incomingPayments" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("incomingPayments")}
								>
									INCOMING <br /> PAYMENTS
								</div>
							) : null}
							{currentUser.role.name == "super technical admin" ? (
								<div
									className={classes.NavItems}
									style={pathname === "cashManager" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("cashManager")}
								>
									CASH <br /> MANAGER
								</div>
							) : null}
							{has_permission("tradingBook") ? (
								<div
									className={classes.NavItems}
									style={pathname === "tradingBook" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("tradingBook")}
								>
									TRADING <br /> BOOK
								</div>
							) : null}
							{has_permission("limitsSpreads") ? (
								<div
									className={classes.NavItems}
									style={pathname === "limitsAndSpreads" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("limitsAndSpreads")}
								>
									LIMITS & <br /> SPREADS
								</div>
							) : null}
							{has_permission("documents") ? (
								<div
									className={classes.NavItems}
									style={pathname === "documents" ? { opacity: "0.3" } : null}
									onClick={() => handleOnClick("documents")}
								>
									DOCUMENTS
								</div>
							) : null}
						</nav>
						<div className={classes.DashboardContent}>
							<Switch>
								{has_permission("accounts") ? (
									<Route exact path={"/dashboard/accounts"} component={Accounts} />
								) : null}
								{has_permission("accounts") ? (
									<Route exact path={"/dashboard/groups"} component={GroupManagement} />
								) : null}
								{has_permission("currencyTrader") ? (
									<Route exact path={"/dashboard/orders"} component={CurrencyTrader} />
								) : null}
								{has_permission("outgoingPayments") ? (
									<Route exact path={"/dashboard/outgoingPayments"} component={OutgoingPayments} />
								) : null}
								{has_permission("incomingPayments") ? (
									<Route exact path={"/dashboard/incomingPayments"} component={IncomingPayments} />
								) : null}
								{has_permission("tradingBook") ? (
									<Route exact path={"/dashboard/tradingBook"} component={TradingBook} />
								) : null}
								{currentUser.role.name == "super technical admin" ? (
									<Route exact path={"/dashboard/cashManager"} component={CashManager} />
								) : null}
								{has_permission("limitsSpreads") ? (
									<Route exact path={"/dashboard/limitsAndSpreads"} component={LimitsAndSpreads} />
								) : null}
								{has_permission("documents") ? (
									<Route exact path={"/dashboard/documents"} component={Documents} />
								) : null}
								<Redirect from="/" to="/welcome" />
							</Switch>
						</div>
					</div>
				</DndProvider>
			</div>
		)
	);
};

export default Dashboard;
