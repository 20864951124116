import gql from "graphql-tag";

export default gql`
	{
		currentUser {
			id
			token
			firstName
			lastName
			name
			role {
				id
				name
				userManagement
				accounts
				currencyTrader
				incomingPayments
				outgoingPayments
				tradingBook
				limitsSpreads
				documents
			}
			fullControl
		}
	}
`;
