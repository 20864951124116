import React from "react";
import classes from "./Language.module.scss";
import { useTranslation } from "react-i18next";

function Language() {
	const { i18n } = useTranslation();
	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
	};

	return (
		<div className={classes.Language}>
			<span
				className={i18n.language === "en" ? classes.LanguageActive : ""}
				onClick={() => changeLanguage("en")}
			>
				EN
			</span>
			|
			<span
				className={i18n.language === "sq" ? classes.LanguageActive : ""}
				onClick={() => changeLanguage("sq")}
			>
				SQ
			</span>
		</div>
	);
}

export default Language;
