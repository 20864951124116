import React, { useState } from "react";
import classes from "./MainPage.module.scss";
import Dropdown from "../../UI/Dropdown/RolesDropdown";
import UpdateBackOfficeUser from "../../../mutations/RoleManagement/UpdateBackOfficeUser";
import BackOfficeUsersQuery from "../../../queries/RoleManagement/BackOfficeUsers";
import ChangePassword from "../../../mutations/RoleManagement/ChangePassword";
import { useMutation } from "react-apollo";
import STAConfirmationPopup from "./STAConfimationPopup";
import RolesQuery from "../../../queries/RoleManagement/Roles";

const EditUser = (props) => {
	const selectedUser = props.selectedUser;
	const selectedUserRole = selectedUser.role.id;
	const [user, setUser] = useState({
		roleId: selectedUser.role.id,
		address: selectedUser.address,
		city: selectedUser.city,
		country: selectedUser.country,
		email: selectedUser.email,
		postalCode: selectedUser.postalCode,
		cellphone: selectedUser.cellphone,
		currentPassword: "",
		newPassword: "",
	});

	const [updateBackOfficeUser] = useMutation(UpdateBackOfficeUser, {
		refetchQueries: [{ query: BackOfficeUsersQuery }, { query: RolesQuery }],
	});

	const [changePassword] = useMutation(ChangePassword);

	const onSelectDropdown = (selectedValue, id) => {
		setUser({ ...user, roleId: id });
	};

	const handleOnChange = (event) => {
		event.persist();
		const fieldName = event.target.name;
		setUser({ ...user, [fieldName]: event.target.value });
	};

	const updateUser = () => {
		if (
			user.roleId === null ||
			user.roleId === "" ||
			user.address === null ||
			user.address === "" ||
			user.city === null ||
			user.city === "" ||
			user.country === null ||
			user.country === "" ||
			user.email === null ||
			user.email === "" ||
			user.postalCode === null ||
			user.postalCode === "" ||
			user.cellphone === null ||
			user.cellphone === ""
		) {
			props.handleSetMessage("Please enter all the fields");
		} else {
			let fullControl = props.currentUser.fullControl;
			let fullControlDate = new Date(fullControl);
			// let hours = Math.abs(fullControlDate - new Date()) / 36e5;
			let hours = (fullControlDate - new Date()) / 36e5;
			if (
				(user.roleId === "1" || selectedUserRole === "1") &&
				(props.currentUser.fullControl === null || hours < 0)
			) {
				props.toggleSTAConfirmationPopup();
			} else {
				updateBackOfficeUser({
					variables: {
						attributes: {
							firstName: selectedUser.firstName,
							lastName: selectedUser.lastName,
							personalIDNumber: "123456789",
							address: user.address,
							cellphone: user.cellphone,
							city: user.city,
							country: user.country,
							postalCode: user.postalCode,
						},
						role_id: user.roleId,
						user_id: selectedUser.id,
					},
				})
					.then((result) => {
						props.handleSetMessage("User was updated!");
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const handleUpdatePassword = () => {
		if (
			user.currentPassword === null ||
			user.currentPassword === "" ||
			user.newPassword === null ||
			user.newPassword === ""
		) {
			props.handleSetMessage("Please enter both passwords");
		} else {
			let fullControl = props.currentUser.fullControl;
			let fullControlDate = new Date(fullControl);
			// let hours = Math.abs(fullControlDate - new Date()) / 36e5;
			let hours = (fullControlDate - new Date()) / 36e5;
			if (user.roleId === "1" && (props.currentUser.fullControl === null || hours < 0)) {
				props.toggleSTAConfirmationPopup();
			} else {
				changePassword({
					variables: {
						password: user.currentPassword,
						new_password: user.newPassword,
					},
				})
					.then((result) => {
						if (!result.data.changePassword.success) props.handleSetMessage("Incorrect password!");
						else {
							props.handleSetMessage("Password was changed!");
							user.currentPassword = "";
							user.newPassword = "";
						}
					})
					.catch((error) => {
						console.log(error.message);
						if (error.message === "GraphQL error: Password does not fulfill requirements")
							props.handleSetMessage("Password does not fulfill requirements (min 6 characters)");
					});
			}
		}
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const createNewRole = () => {
		props.createNewRole();
	};

	return (
		<div className={classes.AddUser}>
			<div className={classes.UserInfo}>
				<div className={classes.Role}>
					<Dropdown
						items={props.roles}
						onSelectDropdown={onSelectDropdown}
						selectedItem={selectedUser.role.name}
						createNewRole={createNewRole}
					/>
				</div>
				<div style={{ margin: "40px 0 20px", textAlign: "center" }}>
					<div style={{ font: "Bold 20px/9px Open Sans", marginBottom: 10 }}>
						{selectedUser.firstName + " " + selectedUser.lastName}
					</div>
					<div style={{ fontSize: 14 }}>{selectedUser.personalIdNumber}</div>
				</div>

				<div className={classes.ContactInfo}>
					<span style={{ fontSize: 14 }}>
						<b>Contact Information:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="text"
							value={user.address}
							name="address"
							placeholder="Address"
							className={classes.TextInput}
							style={{ width: 469 }}
							onChange={handleOnChange}
						/>
						<input
							type="text"
							value={user.postalCode}
							name="postalCode"
							placeholder="Postal Code"
							className={classes.TextInput}
							style={{ width: 145 }}
							onChange={handleOnChange}
						/>
						<input
							type="text"
							value={user.city}
							name="city"
							placeholder="City"
							className={classes.TextInput}
							style={{ width: 145 }}
							onChange={handleOnChange}
						/>
						<input
							type="text"
							value={user.country}
							name="country"
							placeholder="Country"
							className={classes.TextInput}
							style={{ width: 146 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>

				<div className={classes.Email}>
					<span style={{ fontSize: 14 }}>
						<b>Email:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="text"
							value={user.email}
							name="email"
							placeholder="Email"
							className={classes.TextInput}
							style={{ width: 469 }}
							onChange={handleOnChange}
						/>
					</div>
					<span style={{ fontSize: 14, textAlign: "center" }}>
						<b>
							Please note: if you change your email address, you must repeat <br />
							the confirmation process
						</b>
					</span>
				</div>

				<div style={{ display: "flex", marginTop: 20, width: "100%" }}>
					<div>
						<span style={{ fontSize: 14 }}>
							<b>Phone:</b>
						</span>
						<div className={classes.PersonalInfo}>
							<input
								type="text"
								value={user.cellphone}
								name="cellphone"
								placeholder="Phone"
								className={classes.TextInput}
								style={{ width: 227 }}
								onChange={handleOnChange}
							/>
						</div>
					</div>
				</div>

				<div className={classes.ActionButton}>
					<button className={classes.YellowButton} onClick={updateUser}>
						SAVE
					</button>
					<button className={classes.WhiteButton} onClick={props.cancelEditUser}>
						CANCEL
					</button>
				</div>
			</div>

			<div className={classes.ChangePassword}>
				<div style={{ alignSelf: "center", fontSize: 20, marginBottom: 20 }}>
					<b>CHANGE PASSWORD</b>
				</div>
				<div style={{ alignSelf: "baseline" }}>
					<span style={{ fontSize: 14 }}>
						<b>Current Password:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="password"
							name="currentPassword"
							value={user.currentPassword}
							className={classes.TextInput}
							style={{ width: 227 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>
				<div style={{ display: "flex" }}>
					<div>
						<span style={{ fontSize: 14 }}>
							<b>New Password:</b>
						</span>
						<div className={classes.PersonalInfo}>
							<input
								type="password"
								name="newPassword"
								value={user.newPassword}
								className={classes.TextInput}
								style={{ width: 227 }}
								onChange={handleOnChange}
							/>
						</div>
					</div>
				</div>

				<div className={classes.ActionButton}>
					<button className={classes.YellowButton} onClick={handleUpdatePassword}>
						SAVE
					</button>
					<button className={classes.WhiteButton} onClick={props.cancelEditUser}>
						CANCEL
					</button>
				</div>

				{props.showSTAConfirmationPopup && (
					<STAConfirmationPopup
						users={props.users}
						toggleSTAConfirmationPopup={props.toggleSTAConfirmationPopup}
					/>
				)}
			</div>
		</div>
	);
};

export default EditUser;
