import React, { useEffect, useRef } from "react";
import classes from "../Accounts.module.scss";

const AllUsersPopup = (props) => {
	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					props.closePopup();
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	const html = props.employees.map((employee, index) => (
		<div key={index}>
			<b>{employee.firstName + " " + employee.lastName}</b>
		</div>
	));

	return (
		<div className={classes.Shareholders}>
			<div className={classes.Container} ref={wrapperRef} style={{ width: 500 }}>
				<div className={classes.ShareholdersTitle}>{props.name}</div>
				<div className={classes.ShareholdersContainer}>
					<div className="CEO_other_details" style={{ width: "100%" }}>
						<div style={{ maxWidth: "450px", maxHeight: "345px", overflow: "auto" }}>{html}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllUsersPopup;
