import React, { useState } from "react";
import classes from "./MainPage.module.scss";
import Dropdown from "../../UI/Dropdown/RolesDropdown";
import { useMutation } from "react-apollo";
import CreateBackOfficeUser from "../../../mutations/RoleManagement/CreateBackOfficeUser";
import BackOfficeUsersQuery from "../../../queries/RoleManagement/BackOfficeUsers";
import UpdateBackOfficeUser from "../../../mutations/RoleManagement/UpdateBackOfficeUser";
import ConfirmEmail from "../../../mutations/RoleManagement/ConfirmEmail";
import ResendEmailConfirmation from "../../../mutations/RoleManagement/ResendEmailConfirmation";
import STAConfirmationPopup from "./STAConfimationPopup";
import RolesQuery from "../../../queries/RoleManagement/Roles";

const AddUser = (props) => {
	const [createBackOfficeUser] = useMutation(CreateBackOfficeUser, {
		refetchQueries: [{ query: BackOfficeUsersQuery }],
	});
	const [confirmEmail] = useMutation(ConfirmEmail);
	const [resendEmailConfirmation] = useMutation(ResendEmailConfirmation);
	const [updateBackOfficeUser] = useMutation(UpdateBackOfficeUser, {
		refetchQueries: [{ query: BackOfficeUsersQuery }, { query: RolesQuery }],
	});
	const [stepOne, setStepOne] = useState(true);
	const [stepTwo, setStepTwo] = useState(false);
	const [enterCodeScreen, setEnterCodeScreen] = useState(false);
	const [showSuccessMsg, setShowSuccessMsg] = useState(false);
	const [user, setUser] = useState({
		id: null,
		code: "",
		firstName: "",
		lastName: "",
		personalIdNumber: "",
		roleId: "",
		address: "",
		city: "",
		country: "",
		email: "",
		postalCode: "",
		cellphone: "",
		password: "",
		passwordConfirmation: "",
	});

	const onSelectDropdown = (selectedValue, id) => {
		setUser({ ...user, roleId: id });
	};

	const handleOnChange = (event) => {
		event.persist();
		const fieldName = event.target.name;
		setUser({ ...user, [fieldName]: event.target.value });
	};

	const createNewRole = () => {
		props.createNewRole();
	};

	const handleNext = () => {
		if (stepOne) {
			if (!enterCodeScreen) createUser();
			else handleConfirmEmail();
		} else {
			updateUser();
		}
	};

	const createUser = () => {
		if (
			user.email === null ||
			user.email === "" ||
			user.password === null ||
			user.password === "" ||
			user.passwordConfirmation === null ||
			user.passwordConfirmation === ""
		) {
			props.handleSetMessage("Please enter all the fields");
		} else {
			props.handleSetMessage("");
			createBackOfficeUser({
				variables: {
					email: user.email,
					password: user.password,
					password_confirmation: user.passwordConfirmation,
				},
			})
				.then((result) => {
					let userID = result.data.createBackOfficeUser.id;
					setUser({ ...user, id: userID });
					setEnterCodeScreen(true);
				})
				.catch((error) => {
					console.log(error.message);
					if (error.message === "GraphQL error: Invalid input: Email has already been taken") {
						props.handleSetMessage("Email has already been taken");
					}
				});
		}
	};

	const handleConfirmEmail = () => {
		if (user.code === null || user.code === "") {
			props.handleSetMessage("Please enter the code");
		} else {
			props.handleSetMessage("");
			confirmEmail({
				variables: {
					token: user.code,
				},
			})
				.then((result) => {
					if (result.data.confirmEmail.success) {
						setEnterCodeScreen(false);
						setStepOne(false);
						setStepTwo(true);
					} else {
						props.handleSetMessage("Incorrect code");
					}
				})
				.catch((error) => {
					console.log(error.message);
					if (error.message === "GraphQL error: Invalid input: Email has already been taken") {
						props.handleSetMessage("Email has already been taken");
					}
				});
		}
	};

	const handleResendEmailConfirmation = () => {
		resendEmailConfirmation({
			variables: {
				id: user.id,
			},
		})
			.then((result) => {
				props.handleSetMessage("Confirmation code was sent to your email");
			})
			.catch((error) => console.log(error));
	};

	const updateUser = () => {
		if (
			user.roleId === null ||
			user.roleId === "" ||
			user.firstName === null ||
			user.firstName === "" ||
			user.lastName === null ||
			user.lastName === "" ||
			user.personalIdNumber === null ||
			user.personalIdNumber === "" ||
			user.address === null ||
			user.address === "" ||
			user.city === null ||
			user.city === "" ||
			user.country === null ||
			user.country === "" ||
			user.email === null ||
			user.email === "" ||
			user.postalCode === null ||
			user.postalCode === "" ||
			user.cellphone === null ||
			user.cellphone === ""
		) {
			props.handleSetMessage("Please enter all the fields");
		} else {
			let fullControl = props.currentUser.fullControl;
			let fullControlDate = new Date(fullControl);
			// let hours = Math.abs(fullControlDate - new Date()) / 36e5;
			let hours = (fullControlDate - new Date()) / 36e5;
			if (user.roleId === "1" && (props.currentUser.fullControl === null || hours < 0)) {
				//hours > 1
				props.toggleSTAConfirmationPopup();
			} else {
				updateBackOfficeUser({
					variables: {
						attributes: {
							firstName: user.firstName,
							lastName: user.lastName,
							personalIDNumber: user.personalIdNumber,
							address: user.address,
							cellphone: user.cellphone,
							city: user.city,
							country: user.country,
							postalCode: user.postalCode,
						},
						role_id: user.roleId,
						user_id: user.id,
					},
				})
					.then((result) => {
						setStepOne(false);
						setStepTwo(false);
						setShowSuccessMsg(true);
					})
					.catch((error) => {
						console.log(error);
					});
				props.handleSetMessage("");
			}
		}
	};

	const stepOneHTML = (
		<div className={classes.ChangePassword} style={{ height: 320 }}>
			<div style={{ display: "flex" }}>
				<div>
					<span style={{ fontSize: 14 }}>
						<b>Email:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="email"
							name="email"
							className={classes.TextInput}
							style={{ width: 227 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<div>
					<span style={{ fontSize: 14 }}>
						<b>New User Password:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="password"
							name="password"
							className={classes.TextInput}
							style={{ width: 227 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>
				<div>
					<span style={{ fontSize: 14 }}>
						<b>Confirm User Password:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="password"
							name="passwordConfirmation"
							className={classes.TextInput}
							style={{ width: 227 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>
			</div>
			{enterCodeScreen && (
				<div>
					<div style={{ margin: "10px 0" }}>
						Please enter the code sent to email address entered above.
					</div>
					<div>
						<span style={{ fontSize: 14 }}>
							<b>Code:</b>
						</span>
						<div className={classes.PersonalInfo}>
							<input
								type="code"
								name="code"
								className={classes.TextInput}
								style={{ width: 227 }}
								onChange={handleOnChange}
							/>
						</div>
					</div>

					<p style={{ fontSize: "12px", marginTop: "10px" }}>
						Didn't receive code?{" "}
						<strong style={{ cursor: "pointer" }} onClick={handleResendEmailConfirmation}>
							Click here.
						</strong>
					</p>
				</div>
			)}
		</div>
	);

	const stepTwoHTML = (
		<div className={classes.UserInfo}>
			<div className={classes.Role}>
				<Dropdown
					items={props.roles}
					onSelectDropdown={onSelectDropdown}
					createNewRole={createNewRole}
				/>
			</div>
			<div className={classes.PersonalInfo}>
				<input
					type="text"
					placeholder="Name"
					name="firstName"
					className={classes.TextInput}
					style={{ width: 226 }}
					onChange={handleOnChange}
				/>
				<input
					type="text"
					placeholder="Surname"
					name="lastName"
					className={classes.TextInput}
					style={{ width: 227 }}
					onChange={handleOnChange}
				/>
				<input
					type="text"
					placeholder="Personal ID number"
					name="personalIdNumber"
					className={classes.TextInput}
					style={{ width: 469 }}
					onChange={handleOnChange}
				/>
			</div>

			<div className={classes.ContactInfo}>
				<span style={{ fontSize: 14 }}>
					<b>Contact Information:</b>
				</span>
				<div className={classes.PersonalInfo}>
					<input
						type="text"
						placeholder="Street address"
						name="address"
						className={classes.TextInput}
						style={{ width: 469 }}
						onChange={handleOnChange}
					/>
					<input
						type="text"
						placeholder="Postal code"
						name="postalCode"
						className={classes.TextInput}
						style={{ width: 145 }}
						onChange={handleOnChange}
					/>
					<input
						type="text"
						placeholder="City"
						name="city"
						className={classes.TextInput}
						style={{ width: 146 }}
						onChange={handleOnChange}
					/>
					<input
						type="text"
						placeholder="Country"
						name="country"
						className={classes.TextInput}
						style={{ width: 146 }}
						onChange={handleOnChange}
					/>
				</div>
			</div>

			<div className={classes.Email}>
				<span style={{ fontSize: 14 }}>
					<b>Email:</b>
				</span>
				<div className={classes.PersonalInfo}>
					<input
						type="text"
						placeholder="Email address"
						name="email"
						className={classes.TextInput}
						style={{ width: 469 }}
						onChange={handleOnChange}
					/>
				</div>
				<span style={{ fontSize: 14, textAlign: "center" }}>
					<b>
						Please note: you must confirm your email address <br />
						before your account can be activated
					</b>
				</span>
			</div>

			<div style={{ display: "flex", marginTop: 20, width: "100%" }}>
				<div>
					<span style={{ fontSize: 14 }}>
						<b>Phone:</b>
					</span>
					<div className={classes.PersonalInfo}>
						<input
							type="text"
							placeholder="Phone"
							name="cellphone"
							className={classes.TextInput}
							style={{ width: 227 }}
							onChange={handleOnChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className={classes.AddUser}>
			{stepOne && stepOneHTML}

			{stepTwo && stepTwoHTML}
			{!showSuccessMsg ? (
				<div className={classes.ActionButton}>
					<button className={classes.YellowButton} onClick={handleNext}>
						Next
					</button>
					<button className={classes.WhiteButton} onClick={props.cancelAddUser}>
						CANCEL
					</button>
				</div>
			) : (
				<div>The user was created!</div>
			)}

			{props.showSTAConfirmationPopup && (
				<STAConfirmationPopup
					users={props.users}
					toggleSTAConfirmationPopup={props.toggleSTAConfirmationPopup}
				/>
			)}
		</div>
	);
};

export default AddUser;
