import React from "react";
import classes from "./CurrencyTrader.module.scss";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import CurrencyTraderStatus from "../../../mutations/SiteSettings/CurrencyTraderStatus";
import CurrencyTraderStatusQuery from "../../../queries/SiteSettings/CurrencyTraderStatus";

const TradingStatus = () => {
	const status_query = useQuery(CurrencyTraderStatusQuery);
	const [currency_trader_status] = useMutation(CurrencyTraderStatus, {
		refetchQueries: [{ query: CurrencyTraderStatusQuery }],
	});

	const handleCurrencyTraderStatus = () => {
		currency_trader_status();
	};

	return !status_query.loading ? (
		<button className={classes.TradingButton} onClick={handleCurrencyTraderStatus}>
			{status_query.data.currencyTraderStatus ? "STOP ALL TRADING" : "START ALL TRADING"}
		</button>
	) : null;
};

export default TradingStatus;
