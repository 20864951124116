import React, { useState } from "react";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";

const GroupManagement = (props) => {
	const action = props.location.state.action;
	const selectedGroup = props.location.state.selectedGroup;

	return <>{action === "addGroup" ? <AddGroup /> : <EditGroup selectedGroup={selectedGroup} />}</>;
};

export default GroupManagement;
