import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import classes from "./Accounts.module.scss";
import FourDots from "../../UI/Icons/FourDots/FourDots";
import CalendarFilterModal from "../Modals/Accounts/CalendarFilterModal/CalendarFilterModal";
import UpdateLimit from "../../../mutations/Limits/UpdateLimit";
import UpdateGuarantee from "../../../mutations/Guarantee/UpdateGuarantee";
import GetAccountsQuery from "../../../queries/Accounts/Accounts";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

const CurrencyTraderInfo = (props) => {
	const [showCalendarFilter, setShowCalendarFilter] = useState(false);
	const [editLimit, setEditLimit] = useState(false);
	const [editGuarantee, setEditGuaranatee] = useState(false);
	const [penClicks, setPenClicks] = useState(0);
	const [penClicks_G, setPenClicks_G] = useState(0);
	const [limit, setLimit] = useState(null);
	const [guarantee, setGuarantee] = useState(null);

	useEffect(() => {
		setLimit(props.account.tradingDailyLimit);
		setGuarantee(props.account.guarantee);
	}, [props.account.tradingDailyLimit, props.account.guarantee]);

	let history = useHistory();
	const [updateLimit] = useMutation(UpdateLimit, {
		refetchQueries: [{ query: GetAccountsQuery }],
	});
	const [updateGuarantee] = useMutation(UpdateGuarantee, {
		refetchQueries: [{ query: GetAccountsQuery }],
	});

	const toggleFilterModal = () => {
		setShowCalendarFilter(!showCalendarFilter);
	};

	const closeFilterModal = () => {
		setShowCalendarFilter(false);
	};

	const toggleEditLimit = () => {
		setEditLimit(!editLimit);

		if (penClicks === 0) {
			setPenClicks(penClicks + 1);
		} else if (penClicks === 1) {
			setPenClicks(0);
			///Updating Limit
			updateLimit({
				variables: {
					user_id: props.account.id,
					limit: parseInt(limit),
				},
			})
				.then((result) => console.log(result))
				.catch((error) => console.log(error));
		}
	};

	const toggleEditGuarnatee = () => {
		setEditGuaranatee(!editGuarantee);

		if (penClicks_G === 0) {
			setPenClicks_G(penClicks_G + 1);
		} else if (penClicks_G === 1) {
			setPenClicks_G(0);
			///Updating Limit
			updateGuarantee({
				variables: {
					user_id: props.account.id,
					guarantee: parseInt(guarantee),
				},
			})
				.then((result) => console.log(result))
				.catch((error) => console.log(error));
		}
	};

	const handleOnChange = (value, type) => {
		if (type === "limit") {
			setLimit(value);
		} else {
			setGuarantee(value);
		}
	};

	const openOrdersHandle = () => {
		history.push({
			pathname: "/dashboard/tradingBook",
			state: { globalName: props.account.globalName },
		});
	};

	const allOrdersHandle = () => {
		history.push({
			pathname: "/dashboard/orders",
			state: { globalName: props.account.globalName },
		});
	};

	const account = props.account;
	const balances = account.balances;
	const otherCurrrencyHTML = balances.map((item, index) => (
		<tr key={index}>
			<td>{item.currency}</td>
			<td>
				<div>{item.totalBalance}</div>
				<div style={{ fontSize: 12 }}>{item.limit} Reserved</div>
			</td>
			<td style={{ borderBottom: "0.5px solid #CCCCCC" }}>
				<div>
					<b>Number of Transactions:</b> {item.numberOfTransactions}
				</div>
				<div>
					<b>Breakdown:</b> {item.numberOfPurchases} purchases, {item.numberOfSales} sales
				</div>
				<div>
					<b>Average Transaction Value:</b> {item.averageTransactionValue}
				</div>
			</td>
			<td style={{ borderBottom: "0.5px solid #CCCCCC", paddingLeft: 20 }}>
				<div>
					<b>Total Purchase Value:</b> {item.totalPurchaseValue}
				</div>
				<div>
					<b>Total Sales Value:</b> {item.totalSaleValue}
				</div>
				<div>
					<b>Total Earnings:</b> {item.totalEarnings}
				</div>
			</td>
		</tr>
	));

	return (
		<div className={classes.CurrencyTraderInfo}>
			<div className={classes.Header}>
				<span className={classes.Title}>BALANCES</span>

				<div
					className="filterModalIcon"
					style={{ position: "relative" }}
					onClick={() => toggleFilterModal()}
				>
					<FourDots size={18} dotSize={7.37} />

					{showCalendarFilter ? <CalendarFilterModal closeModal={closeFilterModal} /> : null}
				</div>

				<div className={classes.LeftSide}>
					<i
						className={["fas fa-pen", classes.PenIcon].join(" ")}
						onClick={toggleEditGuarnatee}
					></i>
					<span style={{ fontWeight: "bold" }}>Guarantee: </span>
					{editGuarantee ? (
						<>
							<NumberFormat
								className={classes.TextInput}
								value={guarantee}
								suffix=" L"
								thousandSeparator=","
								decimalSeparator="."
								onValueChange={(value) => handleOnChange(value.value, "guarantee")}
							/>
						</>
					) : (
						<span style={guarantee < 0 ? { color: "#9a2020" } : null}>
							<NumberFormat
								value={guarantee}
								displayType={"text"}
								suffix=" L"
								thousandSeparator=","
								decimalSeparator="."
							/>
						</span>
					)}
				</div>

				<div className={classes.LeftSide}>
					<i className={["fas fa-pen", classes.PenIcon].join(" ")} onClick={toggleEditLimit}></i>
					<span style={{ fontWeight: "bold" }}>Account Limit: </span>
					{editLimit ? (
						<>
							<NumberFormat
								className={classes.TextInput}
								value={limit}
								suffix=" L"
								thousandSeparator=","
								decimalSeparator="."
								onValueChange={(value) => handleOnChange(value.value, "limit")}
							/>
						</>
					) : (
						<span>
							<NumberFormat
								value={limit}
								displayType={"text"}
								suffix=" L"
								thousandSeparator=","
								decimalSeparator="."
							/>
						</span>
					)}
				</div>
			</div>

			<table className={classes.CurrencyTraderTable}>
				<tbody>{otherCurrrencyHTML}</tbody>
			</table>

			<button
				className={classes.YellowButton}
				style={{ width: 183, marginRight: 23 }}
				onClick={openOrdersHandle}
			>
				OPEN ORDERS
			</button>
			<button
				className={classes.YellowButton}
				style={{ width: 183, marginRight: 23 }}
				onClick={allOrdersHandle}
			>
				ALL ORDERS
			</button>
		</div>
	);
};

export default CurrencyTraderInfo;
