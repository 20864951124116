import React from "react";
import classes from "./OutgoingPayments.module.scss";

const BankIcon = (props) => {
	let img = null;
	if (props.square) {
		const images = require.context("../../../assets/icons/ChartsIcons/", true);
		img = images("./" + props.bank.bank_name + ".png");
	} else {
		const images = require.context("../../../assets/icons/", true);
		img = images("./" + props.bank.bank_name + ".png");
	}

	return <img src={img} alt="Bank Image" />;
};

export default BankIcon;
