import React, { useState, useEffect } from "react";
import classes from "../Accounts.module.scss";

const MarketViewer = (props) => {
	const [account, setAccount] = useState(props.account);
	useEffect(() => {
		setAccount(props.account);
	}, [props.account]);

	return (
		<div className={classes.UserInfo} style={{ height: "90%" }}>
			<span>
				<b>Account Type: </b>Market Viewer
			</span>

			<div className={classes.Content} style={{ width: "100%", height: "100%" }}>
				<div className={classes.Section5} style={{ width: "100%", height: "100%" }}>
					<div>
						<div className={classes.Title} style={{ marginBottom: 30 }}>
							<b>User Infomation</b>
						</div>
						<div>
							<b>
								{account.firstName} {account.lastName}
							</b>
						</div>
						<div>{account.email}</div>
					</div>
					<div>
						<div className={classes.Title} style={{ marginBottom: 30 }}>
							<b>Company Information</b>
						</div>
						<div>
							<div>{account.companyName}</div>
							<div>Identification No: {account.companyIdentificationNumber}</div>
							<div>FiscalCode: {account.companyFiscalCode}</div>
						</div>
					</div>
					<div>
						<div className={classes.Title}>
							<b>Manage Account Access</b>
						</div>
						<div style={{ display: "flex", flexDirection: "row", marginTop: 25 }}>
							<div style={{ width: "31%" }}>
								Use the buttons <br /> to the right to <br />
								manage account <br />
								access
							</div>
							<div style={{ marginRight: 63 }}>
								<div>
									<b>Status:</b>{" "}
									{account.state === "ready_for_activation" ? "pending" : account.state}
								</div>
								{account.state === "active" ? (
									<>
										<div
											style={{ display: "flex", alignItems: "center" }}
											onClick={props.suspendUser}
										>
											<i
												className="fas fa-minus-square fa-3x"
												style={{ color: "#D39C3E", marginRight: 7, cursor: "pointer" }}
											></i>
											<b>SUSPEND</b>
										</div>
										<div onClick={props.disableUser}>
											<i
												className={["fas fa-times fa-2x", classes.DisabledIcon].join(" ")}
												style={{ cursor: "pointer" }}
											></i>
											<b>DISABLE</b>
										</div>
									</>
								) : (
									<>
										<div
											style={{ display: "flex", alignItems: "center" }}
											onClick={props.approveUser}
										>
											<i
												className="fas fa-check-square fa-3x"
												style={{ color: "#649177", marginRight: 7, cursor: "pointer" }}
											></i>
											<b>ACTIVATE</b>
										</div>
										<div onClick={props.disableUser}>
											<i
												className={["fas fa-times fa-2x", classes.DisabledIcon].join(" ")}
												style={{ cursor: "pointer" }}
											></i>
											<b>DISABLE</b>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MarketViewer;
