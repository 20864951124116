import React, { useState, useEffect } from "react";
import classes from "./OutgoingPayments.module.scss";
import BankIcon from "./BankIcon";
import NumberFormat from "react-number-format";

const Graphs = (props) => {
	if (props.data) {
		var graphList = props.data.bankTransfers.bankAccounts.map((bank, key) => {
			return <Graph key={key} index={key} setDisplay={props.setDisplay} data={bank} />;
		});
		return <div className={classes.graphsContainer}>{graphList}</div>;
	} else {
		return null;
	}
};

const Graph = (props) => {
	var currencies = props.data.currencies;
	var fixData = fixGraphData(currencies);
	var lineList = fixData.lineList;
	var step = fixData.step;
	return (
		<div className={classes.graphBankContainer}>
			<div className={classes.graphBankRow}>
				<BankIcon bank={props.data} square={true} />
				<div className={classes.graphBankName}>{props.data.bank_name}</div>
				{props.index === 0 ? (
					<div>
						<i
							className="fas fa-expand-arrows-alt"
							style={{ cursor: "pointer" }}
							onClick={() => openModal()}
						></i>
					</div>
				) : null}
			</div>
			<div style={{ width: "100%" }}>
				<div className={classes.graphNrRow}>
					<div className={classes.graphNr}>{"0"}</div>
					<div className={classes.graphNr}>{step}</div>
					<div className={classes.graphNr}>{step * 2}</div>
					<div className={classes.graphNr}>{step * 3}</div>
					<div className={classes.graphNr}>{step * 4}</div>
					<div className={classes.graphNr}>{step * 5}</div>
				</div>
				<div className={classes.graphLineContainer}>{lineList}</div>
			</div>
		</div>
	);
	function openModal() {
		props.setDisplay(true);
	}
};

export default Graphs;

const GraphLine = (props) => {
	const row_ref = React.createRef();
	const text_ref = React.createRef();
	const [position, setPosition] = useState({ position: "block" });
	useEffect(() => {
		var text_width = text_ref.current.getBoundingClientRect().width;
		var row_width = row_ref.current.getBoundingClientRect().width;
		var perc_width = row_width * (props.data.lineValue / props.data.maxValue);
		if (text_width + perc_width > row_width) {
			var right = row_width - perc_width + 10;
			setPosition({ position: "absolute", right: right });
		}
	}, []);

	var percentage = (props.data.lineValue / props.data.maxValue) * 100;
	return (
		<div className={classes.graphLineRow}>
			<div className={classes.graphLineTitle}>{props.data.type}</div>
			<div className={classes.graphLineRectangleContainer} ref={row_ref}>
				<div className={classes.graphLineRectangle} style={{ width: `${percentage}%` }} />
				<div className={classes.graphLineText} style={position} ref={text_ref}>
					{props.data.value.toLocaleString("ja-JP", { minimumFractionDigits: 2 })}
				</div>
			</div>
		</div>
	);
};

const fixGraphData = (currencies) => {
	var maxValue = 25;
	for (var i = 0; i < currencies.length; i++) {
		var value = currencies[i].total;
		if (currencies[i].currency == "ALL") {
			value = value / 100;
		}
		if (value > maxValue) {
			maxValue = value;
		}
	}

	var lineList = currencies.map((bank, key) => {
		var value = bank.total;
		var lineValue = value;
		if (bank.currency == "ALL") {
			lineValue = value / 100;
		}
		return (
			<GraphLine
				key={key}
				data={{ type: bank.currency, value: value, lineValue: lineValue, maxValue: maxValue }}
			/>
		);
	});
	var step = parseInt(maxValue / 5);
	return {
		maxValue: maxValue,
		step: step,
		lineList: lineList,
	};
};
