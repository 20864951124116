import gql from "graphql-tag";

export default gql`
	mutation sendMissingPaymentNotification($orders: [String!]!) {
		sendMissingPaymentNotification(orders: $orders) {
			success
		}
	}
`;

// {
//   "orders": ["7818733134385", "5597273395586", "8844447582266"]
// }
