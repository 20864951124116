import OutgoingPayments from "../../../queries/Payments/OutgoingPayments";
import CountCurrencyTrader from "../../../queries/CurrencyTrader/CountCurrencyTrader";
import { API } from "aws-amplify";
import Amplify from "aws-amplify";

Amplify.configure({
	API: {
		graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
		graphql_headers: async () => ({
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		}),
	},
});

export async function OutgoingPaymentsQuery(args, offset) {
	let payment_list = await API.graphql({
		query: OutgoingPayments,
		status: "completed",
		variables: {
			attributes: {
				currency: args ? args.currency : null,
				banks: args ? args.banks : null,
				// offset: offset,
				// accountName: args ? args.accountName : null,
				iban: args ? args.iban : null,
				sortingElement: args.sortingElement ? args.sortingElement : null,
				sortingOrder: args.sortingOrder ? args.sortingOrder : null,
				outgoing: args.outgoing ? args.outgoing : null,
				counterQuery: true,
				defaulted: args.defaulted != null ? args.defaulted : null,
				paidFromPortal: args.paidFromPortal != null ? args.paidFromPortal : null,
			},
		},
	});

	return payment_list;
}

export async function CounterQuery(variables) {
	let cash_manager_counter = await API.graphql({
		query: CountCurrencyTrader,
		variables: { status: "completed", attributes: variables },
	});
	return cash_manager_counter;
}

// const [getPayments_counter,{ data:orders_counter }] = useLazyQuery(CountCurrencyTrader, {onCompleted:setCounter});
// const { orders_counter } = useQuery(CountCurrencyTrader, { variables: { attributes: {banks:['Union Bank'], direction:'outgoing', accountName:'Emilio'}}, onCompleted:setCounter});
