import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import LoggedUser from "../../../queries/LoggedUser";
import classes from "./CurrencyTrader.module.scss";
import { CounterQuery, CurrencyTraderQuery } from "./CurrencyTraderQuery";
import DetailsCard from "./DetailsCard";
import PageColumn from "./PageColumn";
import TradingStatus from "./TradingStatus";

const MainPage = (props) => {
	const [transactionStatus, setTransactionStatus] = useState("default");
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [transactionSelectedIndex, setTransactionSelectedIndex] = useState(null);
	const [showFilterModal, setShowFilterModal] = useState({
		all: false,
		paid: false,
		unpaid: false,
	});
	const [filterValues, setFilterValues] = useState(props.filterValues);
	const [filters, setFilters] = useState(false);
	const [matching_orders_list, setMatchingOrders] = useState(null);
	const [active_page, setActivePage] = useState(props.active_page);
	const [offset, setOffset] = useState(props.offset);
	const [totalItemsCount, setTotalItemsCount] = useState(0);
	const [searchFilter, setSearchFilter] = useState(props.searchFilter);
	const [userId, setUserId] = useState(null);
	const loggedUser = useQuery(LoggedUser, {
		onCompleted: () => {
			if (!loggedUser.loading && !loggedUser.error) {
				setUserId(loggedUser.data.currentUser.id);
			}
		},
	});

	let url = `${process.env.REACT_APP_HOME_URL}/file/currency_trader_matches?user_id=${userId}`;
	if (filterValues) {
		if (filterValues.filter_object.maxValue)
			url = url + `&max_value=${filterValues.filter_object.maxValue}`;
		if (filterValues.filter_object.minValue)
			url = url + `&min_value=${filterValues.filter_object.minValue}`;
		if (filterValues.filter_object.maxRateValue)
			url = url + `&max_rate=${filterValues.filter_object.maxRateValue}`;
		if (filterValues.filter_object.minRateValue)
			url = url + `&min_rate=${filterValues.filter_object.minRateValue}`;
		if (filterValues.filter_object.maxPaidValue)
			url = url + `&max_paid=${filterValues.filter_object.maxPaidValue}`;
		if (filterValues.filter_object.minPaidValue)
			url = url + `&min_paid=${filterValues.filter_object.minPaidValue}`;
		if (filterValues.filter_object.maxDate)
			url = url + `&max_date=${filterValues.filter_object.maxDate}`;
		if (filterValues.filter_object.minDate)
			url = url + `&min_date=${filterValues.filter_object.minDate}`;
		if (filterValues.filter_object.sortingElement)
			url =
				url +
				`&sorting_element=${filterValues.filter_object.sortingElement}&sorting_order=${filterValues.sorting_order}`;
		if (filterValues.filter_object.accountName)
			url = url + `&account_name=${filterValues.filter_object.accountName}`;
		if (filterValues.selected_currencies) {
			let currenciesURL = "";
			filterValues.selected_currencies.forEach(
				(currency) => (currenciesURL += "&selected_currencies[]=" + currency)
			);
			url = url + `${currenciesURL}`;
		}
	}

	useEffect(() => {
		if (props.direction) {
			if (props.filterValues && !filters) {
				setFilterValues(props.filterValues);
				fetchOrders(props.filterValues, offset);
			} else {
				setFilterValues(filterValues ? filterValues : props.filterValues);
				fetchOrders(filterValues, offset);
			}
		}
	}, [props.filterValues, offset, props.direction]);

	const handlePageChange = async (pageNumber) => {
		setActivePage(pageNumber);
		setOffset((pageNumber - 1) * 7);
		const scrollOptions = {
			left: 0,
			top: 0,
			behavior: "smooth",
		};
		window.scrollTo(scrollOptions);
	};

	const filterSearchBox = (value) => {
		let args = filterValues
			? filterValues
			: {
					filter_object: {},
					currencies: filterValues
						? filterValues.currencies
						: [
								{ value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
								{ value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false },
								{ value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false },
								{ value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false },
						  ],
			  };
		setFilterValues(args);
		setSearchFilter(value);
		args.filter_object.accountName = value;
		fetchOrders(args);
		setActivePage(1);
		setOffset(0);
	};

	const toggleFilterModal = (status) => {
		switch (status) {
			case "all": {
				setShowFilterModal({ all: !showFilterModal[status], paid: false, unpaid: false });
				break;
			}
			case "paid": {
				setShowFilterModal({ all: false, paid: !showFilterModal[status], unpaid: false });
				break;
			}
			case "unpaid": {
				setShowFilterModal({ all: false, paid: false, unpaid: !showFilterModal[status] });
				break;
			}
		}
	};

	const closeFilterModal = () => {
		setShowFilterModal({ all: false, purchase: false, sale: false });
	};

	const showDetailsCard = (transaction, openAccordion, parentTransaction) => {
		props.showDetailsPage(
			transaction,
			openAccordion,
			parentTransaction,
			filterValues,
			active_page,
			offset,
			searchFilter
		);
	};

	const filterOrders = async (state) => {
		// if (filterValues)
		setOffset(0);
		setActivePage(1);

		let selected_currencies = [];
		state.currencies.map((currency) => {
			if (currency.isChecked) {
				selected_currencies.push(currency.value);
			}
		});

		selected_currencies = selected_currencies.length > 0 ? selected_currencies : null;

		fetchOrders({
			filter_object: state.filter_object,
			selected_currencies: selected_currencies,
			sorting_order: state.sorting_order,
		});
		setFilterValues({
			filter_object: state.filter_object,
			currencies: state.currencies,
			selected_currencies: selected_currencies,
			sorting_order: state.sorting_order,
		});
		setFilters(true);
	};

	const fetchOrders = async (args) => {
		CurrencyTraderQuery(args, offset)
			.then((data) => {
				let all_orders = data.data.currencyTraderOrders;
				if (all_orders) {
					setMatchingOrders([...all_orders]);

					CounterQuery(args, offset).then((data) => {
						setTotalItemsCount(data.data.countCurrencyTrader);
					});
				} else setMatchingOrders([]);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	if (matching_orders_list == null) return "Loading...";

	const paginatedTransactions = matching_orders_list;

	return (
		<div>
			<div className={classes.Page}>
				<PageColumn
					status="paid"
					title="PAID ORDERS"
					icon="none"
					paid={true}
					transactionStatus={transactionStatus}
					transactions={paginatedTransactions}
					transactionSelectedIndex={transactionSelectedIndex}
					showDetailsCard={showDetailsCard}
					showFilterModal={showFilterModal}
					toggleFilterModal={toggleFilterModal}
					closeFilterModal={closeFilterModal}
					toggleAccordion={props.toggleAccordion}
					graphicUI="default"
					selectedTransaction={selectedTransaction}
					filterSearchBox={filterSearchBox}
					filterValues={filterValues}
					filterOrders={filterOrders}
					searchFilter={searchFilter}
				/>
				<PageColumn
					status="unpaid"
					title="UNPAID ORDERS"
					icon="link"
					paid={false}
					transactionStatus={transactionStatus}
					transactions={paginatedTransactions}
					transactionSelectedIndex={transactionSelectedIndex}
					showDetailsCard={showDetailsCard}
					showFilterModal={showFilterModal}
					toggleFilterModal={toggleFilterModal}
					closeFilterModal={closeFilterModal}
					toggleAccordion={props.toggleAccordion}
					graphicUI="default"
					selectedTransaction={selectedTransaction}
					filterSearchBox={filterSearchBox}
					filterValues={filterValues}
					filterOrders={filterOrders}
					searchFilter={searchFilter}
					url={url}
				/>

				<div style={{ display: "flex", flexDirection: "column" }}>
					<DetailsCard transactionStatus={transactionStatus} />
					{/* <BanksCharts /> */}
					<TradingStatus />
				</div>
			</div>

			{transactionStatus === "default" && (
				<div className={classes.Pagination} style={{ bottom: -470 }}>
					<div className={classes.order_pagination}>
						<Pagination
							activePage={active_page}
							itemsCountPerPage={7}
							totalItemsCount={totalItemsCount} //{matching_orders_list ? matching_orders_list.length : 0}
							pageRangeDisplayed={9}
							prevPageText={null}
							nextPageText={null}
							activeLinkClass={classes.activePage}
							itemClass={classes.numbers}
							linkClass={classes.numbers}
							onChange={handlePageChange}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default MainPage;
