import React, { Component } from "react";
import Language from "./Language/Language";
import NavigationItems from "./NavigationItems/NavigationItems";
import classes from "./Toolbar.module.css";
import UserIcon from "./UserIcon/UserIcon";
import mutation from "../../mutations/Login/Logout";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import ShareSquare from ".././UI/ShareSquare/ShareSquare";
import CurrentUser from "../../queries/LoggedUser";

class toolbarLogout extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	giveDirection = () => {
		this.props.mutate({}).then((res) => {});
		this.props.history.push("/login");
	};

	render() {
		const userRole = this.props.data.currentUser.role ? this.props.data.currentUser.role.id : null;
		const user_name = this.props.data.currentUser.name ? this.props.data.currentUser.name : null;
		return (
			<header className={classes.Toolbar}>
				<div className={classes.RightNav}>
					<div
						style={{
							color: "white",
							fontSize: "20px",
							justifyContent: "baseline",
							padding: "15px",
							paddingLeft: "70px",
							cursor: "pointer",
						}}
					>
						<b>{user_name}</b>
					</div>
				</div>
				<div className={classes.ProfileSettings}>
					<ShareSquare />
					{["1", "2"].includes(userRole) ? <UserIcon /> : null}
					<nav className={classes.NavigationItems}>
						<NavigationItems setDirection={this.giveDirection} items={["Logout"]} />
					</nav>
				</div>
			</header>
		);
	}
}

export default withRouter(graphql(CurrentUser)(graphql(mutation)(toolbarLogout)));
