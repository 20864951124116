import React from "react";
import { Link, useHistory } from "react-router-dom";

const UserIcon = () => {
	let opacity = 1;
	const history = useHistory();
	const pathname = history.location.pathname;
	if (pathname === "/userManagement") opacity = 0.3;

	return (
		<Link to="/userManagement">
			<i
				className={"fas fa-user-cog "}
				style={{ color: "white", fontSize: "20px", opacity: opacity }}
			></i>
		</Link>
	);
};

export default UserIcon;
