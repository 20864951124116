import gql from "graphql-tag";

export default gql`
	query ($attributes: FilterCurrencyTraderInput, $status: String) {
		currencyTraderOrders(attributes: $attributes, status: $status) {
			... on PurchaseOrder {
				id
				amountPaidCents
				amountPaidCurrency
				amountPurchasedCents
				amountPurchasedCurrency
				type
				datetime
				rate
				number
				expiration
				status
				paidToPortal
				paidFromPortal
				updatedAt
				availableTradingAmount
				percentagePurchased
				portionPurchased
				typology
				CI
				completedPayment
				portfolio {
					user {
						firstName
						lastName
						globalName
						cellphone
						city
						country
						email
						address
						personalIdNumber
						dateOfBirth
						postalCode
						typology
						tradingDailyLimit
						servicePoint {
							commercialName
						}
						document {
							typology
							issueDate
							issueEntity
							expirationDate
							number
							description
							identityDocumentUrl
							identityDocumentFilename
						}
					}
				}
				bankAccounts(type: "incoming") {
					currency
					iban
					bankName
				}
				transactions {
					amount
					saleOrder {
						id
						amountPaidCents
						amountSoldCents
						amountPaidCurrency
						amountSoldCurrency
						type
						rate
						paidToPortal
						paidFromPortal
						number
						CI
						completedPayment
						portfolio {
							user {
								firstName
								lastName
								globalName
								cellphone
								city
								country
								email
								address
								personalIdNumber
								dateOfBirth
								postalCode
								tradingDailyLimit
								typology
								servicePoint {
									commercialName
								}
								document {
									typology
									issueDate
									issueEntity
									expirationDate
									number
									description
									identityDocumentUrl
									identityDocumentFilename
								}
							}
						}
						bankAccounts(type: "incoming") {
							currency
							iban
							bankName
						}
					}
				}
			}
			... on SaleOrder {
				id
				amountPaidCents
				amountSoldCents
				amountPaidCurrency
				amountSoldCurrency
				type
				datetime
				rate
				number
				companyRepresentative
				expiration
				status
				paidToPortal
				paidFromPortal
				updatedAt
				availableTradingAmount
				percentageSold
				portionSold
				typology
				CI
				completedPayment
				portfolio {
					user {
						firstName
						lastName
						globalName
						cellphone
						city
						country
						email
						address
						personalIdNumber
						dateOfBirth
						postalCode
						typology
						tradingDailyLimit
						servicePoint {
							commercialName
						}
						document {
							typology
							issueDate
							issueEntity
							expirationDate
							number
							description
							identityDocumentUrl
							identityDocumentFilename
						}
					}
				}
				bankAccounts(type: "incoming") {
					currency
					iban
					bankName
				}
				transactions {
					amount
					purchaseOrder {
						id
						amountPaidCents
						amountPaidCurrency
						amountPurchasedCents
						amountPurchasedCurrency
						datetime
						rate
						paidToPortal
						paidFromPortal
						number
						type
						CI
						completedPayment
						portfolio {
							user {
								firstName
								lastName
								globalName
								cellphone
								city
								country
								email
								address
								personalIdNumber
								dateOfBirth
								postalCode
								typology
								tradingDailyLimit
								servicePoint {
									commercialName
								}
								document {
									typology
									issueDate
									issueEntity
									expirationDate
									number
									description
									identityDocumentUrl
									identityDocumentFilename
								}
							}
						}
						bankAccounts(type: "incoming") {
							currency
							iban
							bankName
						}
					}
				}
			}
		}
	}
`;
