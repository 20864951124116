import React, { Component } from "react";
import classes from "./SearchInput.module.scss";
import NumberFormat from "react-number-format";
import update_limits from "../../../mutations/Limits/UpdateLimit";
import remove_spread_exception from "../../../mutations/Spreads/RemoveSpreadException";
import users_query from "../../../queries/Users";
import { graphql } from "react-apollo";
import compose from "lodash/fp/compose";

class SearchInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.items,
			showItems: true,
			selectedItem: "",
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps) {
			this.setState({ items: this.props.items });
		}
	}

	handleOnChange = (event) => {
		this.setState({
			showItems: true,
			selectedItem: event.target.value,
		});
	};

	selectItem = (item) => {
		this.setState({
			selectedItem: item.name,
			showItems: false,
		});

		this.props.onSelectItem(item);
	};

	onValueChange = (limit_value, user_id) => {
		let new_list = [...this.state.items];
		new_list.forEach((user) => {
			if (user.user_id === user_id) {
				user.limit = limit_value;
			}
		});
		this.setState({
			items: new_list,
		});
	};

	updateLimitsMutation() {
		this.state.items.forEach((limit) => {
			this.props.update_limits({
				variables: {
					user_id: limit.type === "individual" ? limit.user_id : null,
					group_id: limit.type === "group" ? limit.user_id : null,
					limit: parseInt(limit.limit),
				},
			});
		});
	}

	removeSpreadException(e, type, id) {
		e.stopPropagation();
		this.props
			.remove_spread_exception({
				variables: {
					user_id: type === "individual" ? id : null,
					group_id: type === "group" ? id : null,
				},
				refetchQueries: [{ query: users_query }],
			})
			.then((result) => {
				this.props.onRemoveSpread();
			});
	}

	componentDidMount = () => {
		if (this.props.handleEditLimitMutation) {
			this.updateLimitsMutation();
		}
	};

	render() {
		const regex = new RegExp(this.state.selectedItem, "i");
		return (
			<div className={classes.SearchBar}>
				<i className={["fas fa-search fa-lg", classes.SearchIcon].join(" ")}></i>
				<input
					className={classes.SearchInput}
					type="text"
					value={this.state.selectedItem}
					onChange={this.handleOnChange}
				/>

				<div style={{ display: "flex", overflowX: "hidden" }} className={classes.SearchItems}>
					{this.state.items
						.filter((item) => item.name.search(regex) === 0)
						.map((item, index) => (
							<div
								key={index}
								onClick={this.props.type !== "limit" ? () => this.selectItem(item) : null}
								style={{ margin: "10px", cursor: "pointer", width: "100%", display: "inline-flex" }}
							>
								<div>
									{item.type === "individual" ? (
										<i className={["fas fa-user", classes.UserIcon].join(" ")}></i>
									) : (
										<i className={["fas fa-users", classes.UserIcon].join(" ")}></i>
									)}
								</div>

								<div style={{ marginLeft: "30px", display: "inline-flex", width: "100%" }}>
									{item.name}
									{this.props.type === "limit" ? (
										<div className={classes.limitBox} style={{ width: "100%" }}>
											{this.props.modifiable ? (
												<NumberFormat
													className={classes.TextInput}
													thousandSeparator={true}
													value={item.limit}
													onValueChange={(value) => this.onValueChange(value.value, item.user_id)}
												/>
											) : (
												<NumberFormat
													displayType={"text"}
													thousandSeparator={true}
													value={item.limit}
												/>
											)}
										</div>
									) : (
										<div className={classes.limitBox} style={{ width: "100%" }}>
											<i
												onClick={(e) => this.removeSpreadException(e, item.type, item.id)}
												className={["fas fa-trash", classes.UserIcon].join(" ")}
											></i>
										</div>
									)}
								</div>
							</div>
						))}
				</div>
			</div>
		);
	}
}

export default compose(
	graphql(update_limits, { name: "update_limits" }),
	graphql(remove_spread_exception, { name: "remove_spread_exception" })
)(SearchInput);
