import React, { useState, useEffect } from "react";
import classes from "./TradingBook.module.scss";
import PageColumn from "./PageColumn";
import TransactionDetailsCard from "./TransactionDetailsCard";
import { useQuery } from "@apollo/react-hooks";
import orders_query from "../../../queries/CurrencyTrader/Orders";
import orders_counter from "../../../queries/CurrencyTrader/CountCurrencyTrader";
import orders_stats from "../../../queries/CurrencyTrader/OrderStats";
import { API } from "aws-amplify";
import Amplify from "aws-amplify";

var dateFormat = require("dateformat");

Amplify.configure({
	API: {
		graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
		graphql_headers: async () => ({
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		}),
	},
});

const TradingBook = (props) => {
	const [linked, setLinked] = useState(false); //Default View
	const [showTrasactionDetailsCard, setShowTrasactionDetailsCard] = useState(false); //Default View
	const [transactionsType, setTransactionsType] = useState("all");
	const [linkIconClicked, setLinkIconClicked] = useState(false);
	const [transactionSelectedIndex, setTransactionSelectedIndex] = useState(null);
	const [transactionSelectedType, setTransactionSelectedType] = useState(null);
	const [showFilterModal, setShowFilterModal] = useState({
		all: false,
		purchase: false,
		sale: false,
	}); //useState(false);
	const [purchase_orders, set_purchase_orders] = useState(null);
	const [sale_orders, set_sale_orders] = useState(null);
	const [orders, set_orders] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const [purchase_counter, setPurchaseCounter] = useState(0);
	const [sale_counter, setSaleCounter] = useState(0);
	const [all_counter, setALLCounter] = useState(0);
	const [sale_filters, setSaleFilters] = useState(null);
	const [purchase_filters, setPurchaseFilters] = useState(null);
	const [all_filters, setALLFilters] = useState(null);
	const [searchFilter, setSearchFilter] = useState(null);

	const { loading: loading_stats, data: stats_data } = useQuery(orders_stats, {
		variables: { typology: "admin_trading_book" },
	});
	const [selected_order, setSelectedOrder] = useState(null);

	useEffect(() => {
		if (props.location.state) setSearchFilter(props.location.state.globalName);
	});

	const handleLinkColumns = () => {
		if (!linked) {
			filterOrders("all", all_filters);
		} else {
			setTransactionsType("all");
			setTransactionSelectedIndex(null);
		}
		setLinkIconClicked(true);
		setLinked(!linked);
		setShowTrasactionDetailsCard(false);
		setActivePage(1);
	};

	const showDetailsCard = (type, index, id, activePage, orders, search_filtered, category_type) => {
		if (transactionSelectedIndex !== index) {
			setShowTrasactionDetailsCard(true);
		} else {
			setShowTrasactionDetailsCard(!showTrasactionDetailsCard);
		}
		setTransactionsType(type);
		setTransactionSelectedIndex(index);
		setTransactionSelectedType(type);
		setLinkIconClicked(false);
		setActivePage(activePage);
		setSelectedOrder(sale_orders.concat(purchase_orders).find((order) => order.id === id));

		if (search_filtered) {
			if (category_type === "purchase") {
				set_purchase_orders(orders);
			} else if (category_type === "sale") {
				set_sale_orders(orders);
			} else if (category_type === "all") {
				set_orders(orders);
			}
		}
	};

	const toggleFilterModal = (type) => {
		switch (type) {
			case "all": {
				setShowFilterModal({ all: !showFilterModal["" + type], purchase: false, sale: false });
				break;
			}
			case "purchase": {
				setShowFilterModal({ all: false, purchase: !showFilterModal["" + type], sale: false });
				break;
			}
			case "sale": {
				setShowFilterModal({ all: false, purchase: false, sale: !showFilterModal["" + type] });
				break;
			}
			default:
			//do nothing
		}
	};

	const closeFilterModal = () => {
		setShowFilterModal({ all: false, purchase: false, sale: false });
	};

	useEffect(() => {
		filterOrders("purchase", purchase_filters);
		filterOrders("sale", sale_filters);
	}, []);

	const fetch_orders = async (attributes) => {
		let fetched_orders = await API.graphql({
			query: orders_query,
			variables: {
				status: "pending",
				attributes: attributes,
			},
		});
		return fetched_orders.data.currencyTraderOrders;
	};

	const get_number = async (attributes) => {
		console.log(attributes);
		let counter = await API.graphql({
			query: orders_counter,
			variables: {
				status: "pending",
				attributes: attributes,
			},
		});
		return counter.data.countCurrencyTrader;
	};

	const fetchPurchases = async (
		offset = 0,
		max_paid = null,
		min_paid = null,
		max_value = null,
		min_value = null,
		max_rate = null,
		min_rate = null,
		account_name = null,
		selected_currencies = null,
		all_orders = true
	) => {
		let attributes = {
			offset: offset,
			category: "purchase",
			maxAmount: max_value,
			minAmount: min_value,
			maxRateValue: max_rate,
			minRateValue: min_rate,
			minPaidValue: min_paid,
			maxPaidValue: max_paid,
			currency: selected_currencies,
			accountName: account_name,
			sortingElement: "rate",
			sortingOrder: "DESC",
		};

		fetch_orders(attributes).then((result) => {
			get_number(attributes).then((number_result) => {
				setPurchaseCounter(number_result);
				let fetched_orders = result;
				if (all_orders) {
					set_orders(fetched_orders);
				}
				set_purchase_orders(fetched_orders);
			});
		});
	};

	const fetchSales = async (
		offset = 0,
		max_paid = null,
		min_paid = null,
		max_value = null,
		min_value = null,
		max_rate = null,
		min_rate = null,
		account_name = null,
		selected_currencies = null,
		all_orders = true
	) => {
		let attributes = {
			offset: offset,
			category: "sale",
			maxAmount: max_value,
			minAmount: min_value,
			maxRateValue: max_rate,
			minRateValue: min_rate,
			minPaidValue: min_paid,
			maxPaidValue: max_paid,
			currency: selected_currencies,
			accountName: account_name,
			sortingElement: "rate",
			sortingOrder: "ASC",
		};

		fetch_orders(attributes).then((result) => {
			get_number(attributes).then((number_result) => {
				setSaleCounter(number_result);
				let fetched_orders = result;
				if (all_orders) {
					set_orders(fetched_orders);
				}
				set_sale_orders(fetched_orders);
			});
		});
	};

	const fetchALL = async (
		offset = 0,
		max_paid = null,
		min_paid = null,
		max_value = null,
		min_value = null,
		max_rate = null,
		min_rate = null,
		account_name = null,
		selected_currencies = null
	) => {
		let attributes = {
			offset: offset,
			maxAmount: max_value,
			minAmount: min_value,
			maxRateValue: max_rate,
			minRateValue: min_rate,
			minPaidValue: min_paid,
			maxPaidValue: max_paid,
			accountName: account_name,
			currency: selected_currencies,
		};

		fetch_orders(attributes).then((result) => {
			get_number(attributes).then((number_result) => {
				let fetched_orders = result;
				set_orders(fetched_orders);
				setALLCounter(number_result);
			});
		});
	};

	const filterOrders = async (type, state = null, offset) => {
		if (state) {
			Object.keys(state).forEach(function (key) {
				if (state[key] === "") {
					state[key] = null;
				}
			});

			let selected_currencies = [];
			state.currencies.forEach((currency) => {
				if (currency.isChecked) {
					selected_currencies.push(currency.value);
				}
			});

			selected_currencies = selected_currencies.length > 0 ? selected_currencies : null;
			setShowTrasactionDetailsCard(false);

			if (type === "purchase") {
				fetchPurchases(
					offset,
					state.max_paid,
					state.min_paid,
					state.max_value,
					state.min_value,
					state.max_rate,
					state.min_rate,
					state.account_name,
					selected_currencies,
					false
				);
				setPurchaseFilters(state);
			} else if (type === "sale") {
				fetchSales(
					offset,
					state.max_paid,
					state.min_paid,
					state.max_value,
					state.min_value,
					state.max_rate,
					state.min_rate,
					state.account_name,
					selected_currencies,
					false
				);
				setSaleFilters(state);
			} else if (type === "all") {
				fetchALL(
					offset,
					state.max_paid,
					state.min_paid,
					state.max_value,
					state.min_value,
					state.max_rate,
					state.min_rate,
					state.account_name,
					selected_currencies,
					false
				);
				setALLFilters(state);
			}
		} else {
			if (type === "purchase") {
				fetchPurchases(offset);
			} else if (type === "sale") {
				fetchSales(offset);
			} else if (type === "all") {
				fetchALL(offset);
			}
		}
	};

	const hideDetailsCard = () => {
		setShowTrasactionDetailsCard(false);
	};

	let stats = null;
	let stats_type = null;
	if (!loading_stats) {
		stats = stats_data.currencyDetail;
		console.log(stats);
		switch (transactionsType) {
			case "purchase":
				stats_type = "numberOfPurchases";
				break;
			case "sale":
				stats_type = "numberOfSales";
				break;
			default:
				stats_type = "numberOfTransactions";
		}
	}

	if (!purchase_orders || !sale_orders) return "Loading...";
	return (
		<div className={classes.Page}>
			{!linked && transactionsType === "all" ? ( //Default view
				<>
					<PageColumn
						type="purchase"
						title="TRADING BOOK: BUYING"
						icon="none"
						transactionSelectedIndex={transactionSelectedIndex}
						handleLinkColumns={handleLinkColumns}
						showDetailsCard={showDetailsCard}
						closeDetailsCard={hideDetailsCard}
						showFilterModal={showFilterModal}
						toggleFilterModal={toggleFilterModal}
						closeFilterModal={closeFilterModal}
						activePage={activePage}
						set_offset={(offset) => {
							filterOrders("purchase", purchase_filters, offset);
						}}
						pagination_counter={purchase_counter}
						orders={purchase_orders}
						filterOrders={filterOrders}
						search_account_name={(type, value) =>
							fetchPurchases(null, null, null, null, null, null, null, value)
						}
						default_values={purchase_filters}
						searchFilter={searchFilter}
					/>
					<PageColumn
						type="sale"
						title="TRADING BOOK: SELLING"
						icon="link"
						transactionSelectedIndex={transactionSelectedIndex}
						handleLinkColumns={handleLinkColumns}
						showDetailsCard={showDetailsCard}
						closeDetailsCard={hideDetailsCard}
						showFilterModal={showFilterModal}
						toggleFilterModal={toggleFilterModal}
						closeFilterModal={closeFilterModal}
						activePage={activePage}
						set_offset={(offset) => {
							filterOrders("sale", sale_filters, offset);
						}}
						pagination_counter={sale_counter}
						orders={sale_orders}
						filterOrders={(type, state) => {
							filterOrders(type, state, 0);
						}}
						search_account_name={(type, value) =>
							fetchSales(null, null, null, null, null, null, null, value)
						}
						default_values={sale_filters}
						searchFilter={searchFilter}
					/>
				</>
			) : (
				<PageColumn
					type={transactionsType}
					title="TRADING BOOK"
					icon={linked ? "unlink" : "link"}
					transactionSelectedIndex={transactionSelectedIndex}
					handleLinkColumns={handleLinkColumns}
					showDetailsCard={showDetailsCard}
					closeDetailsCard={hideDetailsCard}
					showFilterModal={showFilterModal}
					toggleFilterModal={toggleFilterModal}
					closeFilterModal={closeFilterModal}
					activePage={activePage}
					set_offset={(offset) => {
						filterOrders(transactionsType, eval(transactionsType + "_filters"), offset);
					}}
					pagination_counter={eval(transactionsType + "_counter")}
					filterOrders={filterOrders}
					search_account_name={(type, value) =>
						fetchALL(null, null, null, null, null, null, null, value)
					}
					orders={
						transactionsType === "purchase"
							? purchase_orders
							: transactionsType === "sale"
							? sale_orders
							: orders
					}
					default_values={
						transactionsType === "purchase"
							? purchase_filters
							: transactionsType === "sale"
							? sale_filters
							: all_filters
					}
					searchFilter={searchFilter}
				/>
			)}

			{showTrasactionDetailsCard ? (
				<TransactionDetailsCard
					datetime={dateFormat(selected_order.datetime, "mmmm dd, yyyy HH:MM")}
					order_number={selected_order.number}
					amount={selected_order.amountPurchasedCents || selected_order.amountSoldCents}
					paid_price={selected_order.amountPaidCents}
					currency={selected_order.amountPurchasedCurrency || selected_order.amountSoldCurrency}
					paid_currency={selected_order.amountPaidCurrency}
					rate={selected_order.rate}
					user={selected_order.portfolio.user}
					document={selected_order.portfolio.user.document}
					service_point={selected_order.portfolio.user.servicePoint}
					transactionSelectedIndex={transactionSelectedIndex}
					transactionSelectedType={transactionSelectedType}
				/>
			) : stats ? (
				<div className={classes.Details}>
					<div className={classes.Title}>
						<b>
							{transactionsType === "all"
								? "All "
								: transactionsType.charAt(0).toUpperCase() + transactionsType.slice(1) + " "}
							Transactions:
						</b>
					</div>
					<div className={classes.Row}>
						<b>Number of Trasactions: </b>
						{stats[stats_type]}
					</div>
					<div className={classes.Row}>
						<b>Total Purchased (EUR) : </b>
						{stats.totalPurchasedEur}
					</div>
					<div className={classes.Row}>
						<b>Average Purchase Rate (EUR) : </b>
						{stats.averagePurchaseRateEur}
					</div>
					<div className={classes.Row}>
						<b>Number of Purchases: </b>
						{stats.numberOfPurchases}
					</div>
					<div className={classes.Row}>
						<b>Total Sold (EUR) : </b>
						{stats.totalSoldEur}
					</div>
					<div className={classes.Row}>
						<b>Average Sale Rate (EUR) : </b>
						{stats.averageSaleRateEur}
					</div>
					<div className={classes.Row}>
						<b>Number of Sales: </b>
						{stats.numberOfSales}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default TradingBook;
