import React, { useState, useEffect } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import NumberFormat from "react-number-format";
import currencies from "../../../data/currencies";
import classes from "./IncomingPayments.module.scss";
import Dclasses from "./TransactionDetailsCard.module.scss";
import BankIcon from "./BankIcon";
var dateFormat = require("dateformat");

const TransactionDetailsCard = (props) => {
	var item = props.detailsCard.item;

	const [expand, setExpand] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [activeItem, setActiveItem] = useState({});

	useEffect(() => {
		setExpand(props.detailsCard.expand);
		if (props.detailsCard.activeItem) {
			setActiveItem(props.detailsCard.activeItem);
			setShowDetails(true);
		}
	}, [props.detailsCard]);

	const toggleExpand = () => {
		setExpand(!expand);
	};

	const showDetailsHandler = (item) => {
		setShowDetails(true);
		setActiveItem(item);
	};

	var status = "";
	var StatusCardSpace = null;
	var opacity = 1;
	var Bcolor = "#F1E0C4";

	if (item.paidToPortal) {
		status = "PAID";
	}

	if (item.lastNotifiedAt != null) {
		status = "NOTIFIED";
	}

	if (item.CI) {
		status = "DEFAULTED";
	}

	switch (status) {
		case "NOTIFIED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace}>
					<div className={classes.Description}>
						Last notice sent: {dateFormat(item.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
					</div>
					<div className={classes.Text}>NOTIFIED</div>
				</div>
			);
			break;
		case "PAID":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					<div className={classes.Text}>PAID</div>
				</div>
			);
			opacity = 0.3;
			break;
		case "DEFAULTED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace}>
					{item.lastNotifiedAt ? (
						<div className={classes.Description}>
							Last notice sent: {dateFormat(item.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
						</div>
					) : null}
					<div className={classes.Text}>
						DEFAULTED{" "}
						{item.type == "purchase" ? (
							<NumberFormat
								value={item.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={item.amountPaidCurrency === "ALL" ? " L" : null}
								prefix={
									item.amountPaidCurrency === "ALL"
										? null
										: getSymbolFromCurrency(item.amountPaidCurrency)
								}
							/>
						) : (
							<NumberFormat
								value={item.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={item.amountSoldCurrency === "ALL" ? " L" : null}
								prefix={
									item.amountSoldCurrency === "ALL"
										? null
										: getSymbolFromCurrency(item.amountSoldCurrency)
								}
							/>
						)}
					</div>
					<div className={classes.Description} style={{ textAlign: "right", fontSize: 14 }}>
						paid: {item.completedPayment}
					</div>
				</div>
			);
			opacity = 0.3;
			Bcolor = "#9A2020";
			break;
	}
	var parentExpandDisplay = expand ? "flex" : "none";
	var parentItem = (
		<div style={{ display: "flex" }}>
			<div
				className={classes.StatusBlockContainer}
				onClick={() => {
					toggleExpand();
				}}
			>
				<div
					className={classes.StatusBlock}
					style={{ marginRight: 0, opacity: opacity, background: Bcolor }}
				></div>
			</div>
			<div
				className={classes.TransactionCard}
				style={{
					borderLeft: 0,
					flex: 1,
					borderRadius: 0,
					minWidth: "fit-content",
					display: parentExpandDisplay,
					opacity: opacity,
				}}
				onClick={() => {
					showDetailsHandler(item);
				}}
			>
				<div style={{ display: "flex" }}>
					<div style={{ flex: 1 }}>
						<div className={classes.FirstRow}>
							<span>
								<BankIcon bank={{ bank_name: item.bankAccounts[0].bankName }} square={false} />
							</span>
							<span style={{ fontSize: 15, marginLeft: 5 }}>{item.portfolio.user.globalName}</span>
						</div>
						<div style={{ fontSize: 20 }}>
							<span>{item.type === "purchase" ? item.amountPaidCents : item.amountSoldCents}</span>
						</div>
						<div style={{ fontSize: 12 }}>
							<span>IBAN: {item.bankAccounts[0].iban}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const lastIndex = item.transactions.length - 1;
	var mini_StatusCardSpace = null;
	var matches_map_list = item.transactions.map((index, key) => {
		var mini_status = "";
		var mini_opacity = 1;
		var mini_Bcolor = "#F1E0C4";

		var mini_data = {};
		if (item.type == "sale") {
			mini_data = index.purchaseOrder;
		} else {
			mini_data = index.saleOrder;
		}

		if (mini_data.paidToPortal) {
			mini_status = "PAID";
		}

		if (mini_data.lastNotifiedAt != null) {
			mini_status = "NOTIFIED";
		}

		if (mini_data.CI) {
			mini_status = "DEFAULTED";
		}
		switch (mini_status) {
			case "NOTIFIED":
				break;
			case "PAID":
				mini_opacity = 0.3;
				break;
			case "DEFAULTED":
				mini_opacity = 0.3;
				mini_Bcolor = "#9A2020";
				break;
		}
		var transactionCardStyle = {
			borderLeft: 0,
			flex: 1,
			borderRadius: 0,
			minWidth: "fit-content",
			display: expandDisplay,
			opacity: mini_opacity,
		};
		if (key === lastIndex) {
			switch (mini_status) {
				case "NOTIFIED":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace}>
							<div className={classes.Description}>
								Last notice sent: {dateFormat(mini_data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
							</div>
							<div className={classes.Text}>NOTIFIED</div>
						</div>
					);
					break;
				case "PAID":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
							<div className={classes.Text}>PAID</div>
						</div>
					);
					mini_opacity = 0.3;
					break;
				case "DEFAULTED":
					mini_StatusCardSpace = (
						<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
							{mini_data.lastNotifiedAt ? (
								<div className={classes.Description}>
									Last notice sent: {dateFormat(mini_data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
								</div>
							) : null}
							<div className={classes.Text}>
								DEFAULTED{" "}
								{mini_data.type == "purchase" ? (
									<NumberFormat
										value={mini_data.missingPayment}
										thousandSeparator={true}
										displayType={"text"}
										suffix={mini_data.amountPaidCurrency === "ALL" ? " L" : null}
										prefix={
											mini_data.amountPaidCurrency === "ALL"
												? null
												: getSymbolFromCurrency(mini_data.amountPaidCurrency)
										}
									/>
								) : (
									<NumberFormat
										value={mini_data.missingPayment}
										thousandSeparator={true}
										displayType={"text"}
										suffix={mini_data.amountSoldCurrency === "ALL" ? " L" : null}
										prefix={
											mini_data.amountSoldCurrency === "ALL"
												? null
												: getSymbolFromCurrency(mini_data.amountSoldCurrency)
										}
									/>
								)}
							</div>
							<div className={classes.Description} style={{ textAlign: "right", fontSize: 14 }}>
								paid: {mini_data.completedPayment}
							</div>
						</div>
					);
					mini_opacity = 0.3;
					mini_Bcolor = "#9A2020";
					break;
			}
			transactionCardStyle = { borderLeft: 0, flex: 1, opacity: mini_opacity };
		}
		var expandDisplay = expand ? "flex" : "none";
		return (
			<div key={key} style={{ display: "flex" }}>
				<div
					className={classes.StatusBlockContainer}
					onClick={() => {
						toggleExpand();
					}}
				>
					<div
						className={classes.StatusBlock}
						style={{ marginRight: 0, opacity: mini_opacity, background: mini_Bcolor }}
					></div>
				</div>
				<div
					className={classes.TransactionCard}
					style={transactionCardStyle}
					onClick={() => {
						showDetailsHandler(mini_data);
					}}
				>
					<div style={{ display: "flex" }}>
						<div style={{ flex: 1 }}>
							<div className={classes.FirstRow}>
								<span>
									<BankIcon
										bank={{ bank_name: mini_data.bankAccounts[0].bankName }}
										square={false}
									/>
								</span>
								<span style={{ fontSize: 15, marginLeft: 5 }}>
									{mini_data.portfolio.user.globalName}
								</span>
							</div>
							<div style={{ fontSize: 20 }}>
								<span>
									{mini_data.type === "purchase"
										? mini_data.amountPaidCents
										: mini_data.amountSoldCents}
								</span>
							</div>
							<div style={{ fontSize: 12 }}>
								<span>IBAN: {mini_data.bankAccounts[0].iban}</span>
							</div>
						</div>
						{key === lastIndex && mini_StatusCardSpace}
					</div>
				</div>
			</div>
		);
	});

	return (
		<div className={classes.DetailsModal}>
			<div className={classes.DetailsTitle} style={{ marginBottom: 30 }}>
				<i
					className={"fas fa-arrow-left fa-2x"}
					style={{ marginRight: 10, color: "#D39C3E" }}
					onClick={() => {
						props.setDetailsCard({ show: false });
					}}
				></i>
				<div className={classes.DetailsTitleText}>DETAILS</div>
			</div>
			{showDetails ? (
				<DetailsTransactionDetailsCard data={activeItem} setShowDetails={setShowDetails} />
			) : (
				<div style={{ width: "max-content" }}>
					<div className={classes.parentTransactionCard}>
						{parentItem}
						{matches_map_list}
					</div>
				</div>
			)}
		</div>
	);
};

export default TransactionDetailsCard;

const DetailsTransactionDetailsCard = (props) => {
	var status = "";
	var StatusCardSpace = null;
	var opacity = 1;
	var Bcolor = "#F1E0C4";
	let cardStyle = { top: -25, background: null };
	let triangleStyle = { top: 40, bottom: null, borderColor: null };
	var dateFormat = require("dateformat");
	var accounting = require("accounting");

	const transaction = props.data;

	cardStyle.background = "#f1e0c4";
	triangleStyle.borderColor = "transparent #f1e0c4 transparent transparent";

	if (props.data.paidToPortal) {
		status = "PAID";
	}

	if (props.data.lastNotifiedAt != null) {
		status = "NOTIFIED";
	}

	if (props.data.CI) {
		status = "DEFAULTED";
	}
	switch (status) {
		case "NOTIFIED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace}>
					<div className={classes.Description}>
						Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
					</div>
					<div className={classes.Text}>NOTIFIED</div>
				</div>
			);
			break;
		case "PAID":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					<div className={classes.Text}>PAID</div>
				</div>
			);
			opacity = 0.3;
			break;
		case "DEFAULTED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					{props.data.lastNotifiedAt ? (
						<div className={classes.Description}>
							Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
						</div>
					) : null}
					<div className={classes.Text}>
						DEFAULTED{" "}
						{props.data.type == "purchase" ? (
							<NumberFormat
								value={props.data.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={props.data.amountPaidCurrency === "ALL" ? " L" : null}
								prefix={
									props.data.amountPaidCurrency === "ALL"
										? null
										: getSymbolFromCurrency(props.data.amountPaidCurrency)
								}
							/>
						) : (
							<NumberFormat
								value={props.data.missingPayment}
								thousandSeparator={true}
								displayType={"text"}
								suffix={props.data.amountSoldCurrency === "ALL" ? " L" : null}
								prefix={
									props.data.amountSoldCurrency === "ALL"
										? null
										: getSymbolFromCurrency(props.data.amountSoldCurrency)
								}
							/>
						)}
					</div>
					<div className={classes.Description} style={{ textAlign: "right", fontSize: 14 }}>
						paid: {props.data.completedPayment}
					</div>
				</div>
			);
			opacity = 0.3;
			Bcolor = "#9A2020";
			cardStyle.background = "rgb(154, 32, 32,0.3)";
			triangleStyle.borderColor = "transparent rgb(154, 32, 32,0.3) transparent transparent";
			break;
	}

	let account_type = null;

	switch (transaction.portfolio.user.typology) {
		case "service_point": {
			account_type = "Service Point";
			break;
		}
		case "business_account": {
			account_type = "Business Account";
			break;
		}
		case "private_account": {
			account_type = "Private Account";
			break;
		}
		default:
		//do nothing
	}

	const currencyCode = transaction.amountPurchasedCurrency || transaction.amountSoldCurrency;
	const currencyName = currencies[currencyCode].name.toUpperCase();

	let order_number = null;

	switch (transaction.portfolio.user.typology) {
		case "service_point": {
			account_type = "Service Point";
			break;
		}
		case "business_account": {
			account_type = "Business Account";
			break;
		}
		case "bank_account": {
			account_type = "Bank Account";
			break;
		}
		case "private_account": {
			account_type = "Private Account";
			break;
		}
		default:
		//do nothing
	}
	if (typeof transaction.amount != "undefined") {
		const parent_order_amount = accounting.unformat(
			transaction.amountPurchasedCents || transaction.amountSoldCents
		);
		if (transaction.amount !== parent_order_amount) {
			order_number = (
				<span>
					This order is part of order <b>{transaction.number}</b>
				</span>
			);
		} else {
			order_number = <span>{transaction.number}</span>;
		}
	} else {
		order_number = <span>{transaction.number}</span>;
	}

	return (
		<div className="row">
			<div className="col-md-4">
				<div
					className={classes.parentTransactionCard}
					onClick={() => {
						props.setShowDetails(false);
					}}
				>
					<div className={classes.StatusBlockContainer}>
						<div
							className={classes.StatusBlock}
							style={{ marginRight: 0, background: Bcolor, opacity: opacity }}
						></div>
					</div>
					<div className={classes.TransactionCard} style={{ opacity: opacity }}>
						<div style={{ display: "flex" }}>
							<div style={{ flex: 1 }}>
								<div className={classes.FirstRow}>
									<span>
										<BankIcon
											bank={{ bank_name: props.data.bankAccounts[0].bankName }}
											square={false}
										/>
									</span>
									<span style={{ fontSize: 15, marginLeft: 5 }}>
										{props.data.portfolio.user.globalName}
									</span>
								</div>
								<div style={{ fontSize: 20 }}>
									<span>
										{props.data.type === "purchase"
											? props.data.amountPaidCents
											: props.data.amountSoldCents}
									</span>
								</div>
								<div style={{ fontSize: 12 }}>
									<span>IBAN: {props.data.bankAccounts[0].iban}</span>
								</div>
							</div>

							{StatusCardSpace}
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-7">
				<div className={Dclasses.DetailsCard} style={cardStyle}>
					<div className={Dclasses.Triangle} style={triangleStyle}></div>
					<div className={Dclasses.Content}>
						<div className={Dclasses.Title}>OFFER DETAILS</div>
						<div className={Dclasses.Section1}>
							<div>
								<span>
									<b>Offer Placed: </b>
									{dateFormat(transaction.datetime, "mmmm d, yyyy HH:MM")}
								</span>
								<span style={{ float: "right" }}>
									<b>{transaction.typology} Order</b>
								</span>
							</div>
							<div>
								<span>
									<b>Offer Number: </b>
									{order_number}
								</span>
							</div>
						</div>
						<div className={Dclasses.Section2}>
							<div className={Dclasses.Col1}>
								<div>
									<div className={Dclasses.SubTitle}>
										<b>Item Details</b>
									</div>
									<div>
										<NumberFormat
											value={
												typeof transaction.amount != "undefined"
													? transaction.amount
													: transaction.amountPurchasedCents || transaction.amountSoldCents
											}
											displayType={"text"}
											thousandSeparator=","
											decimalSeparator="."
										/>{" "}
										{currencyName} at
										{" " +
											transaction.rate +
											" " +
											getSymbolFromCurrency(transaction.amountPaidCurrency) +
											"/" +
											getSymbolFromCurrency(
												transaction.amountPurchasedCurrency || transaction.amountSoldCurrency
											)}
									</div>
									<div>
										Order Total:{" "}
										<NumberFormat
											value={
												typeof transaction.amount != "undefined"
													? transaction.amount * transaction.rate
													: transaction.amountPaidCents
											}
											displayType={"text"}
											suffix={transaction.amountPaidCurrency === "ALL" ? " L" : null}
											prefix={
												transaction.amountPaidCurrency === "ALL"
													? null
													: transaction.amountPaidCurrencyl
											}
											thousandSeparator=","
											decimalSeparator="."
										/>
									</div>
								</div>
								<div>
									<div className={Dclasses.SubTitle}>
										<b>Payment Method</b>
									</div>
									<div>
										<b>Bank Transfer</b>
									</div>
									<div>Bank of Albania</div>
									<div>IBAN: IT89 3704 0044 0532 0130 00</div>
								</div>
								<div>
									<div style={{ marginTop: 15 }}>
										<b>Payment Status:</b>
									</div>
									<div>
										{transaction.CI ? (
											<>
												<div style={{ color: "#9A2020", fontWeight: "bold" }}>Defaulted</div>
												<div>Paid {transaction.completedPayment}</div>
											</>
										) : transaction.paidToPortal ? (
											"Paid"
										) : (
											"Unpaid"
										)}
									</div>
								</div>
								<div>
									{/*<button className={Dclasses.YellowButton}
                                style={{ width: 190, marginBottom: 7, float: "left" }}>ORDER HISTORY</button>
                            <button className={Dclasses.YellowButton}
                                style={{ width: 190, float: "left" }}>ACCESS HISTORY</button>*/}
								</div>
							</div>
							<div className={Dclasses.Col2}>
								<div className={Dclasses.SubTitle}>
									<b>User Information</b>
								</div>
								<div style={{ display: "flex" }}>
									<div>
										<b>Account Type</b>
									</div>
									<div style={{ marginLeft: 5 }}>
										<div>{account_type}</div>
									</div>
								</div>
								<div className={Dclasses.UserInfo}>
									<div className={Dclasses.Col1}>
										<div>
											<b>
												{transaction.portfolio.user.firstName} {transaction.portfolio.user.lastName}
											</b>
										</div>
										<div>
											{transaction.portfolio.user.personalIdNumber} <br /> DOB:{" "}
											{dateFormat(transaction.portfolio.user.dateOfBirth, "dd/mm/yyyy")}
										</div>
										{transaction.portfolio.user.document.typology === "passport" ||
										transaction.portfolio.user.document.typology === "id_card" ? (
											<div>
												National{" "}
												{transaction.portfolio.user.document.typology === "passport"
													? "Passport"
													: "Identity Card"}{" "}
												<br />
												{transaction.portfolio.user.document.issueEntity} <br />
												No: {transaction.portfolio.user.document.number} <br />
												Issued: {transaction.portfolio.user.document.issueDate} <br />
												Expires: {transaction.portfolio.user.document.expirationDate}
											</div>
										) : null}
										{/*<button className={Dclasses.YellowButton}
                                    style={{ width: 190 }}>VIEW CERTIFICATES</button>*/}
									</div>
									<div className={Dclasses.Col2}>
										<div>
											{transaction.portfolio.user.address} {transaction.portfolio.user.postalCode}{" "}
											<br /> {transaction.portfolio.user.city}, {transaction.portfolio.user.country}
										</div>
										<div style={{ marginTop: 20 }}>
											{transaction.portfolio.user.email}
											<br /> {transaction.portfolio.user.cellphone}
										</div>

										{/*<button className={Dclasses.YellowButton}
                                    style={{ width: 190, marginTop: 10 }}>VIEW DOCUMENTS</button>*/}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
