import React, { Component } from "react";
import classes from "./FilterModal.module.scss";
import CalendarComponent from "../../../../UI/Modals/SettingsCalendar/Calendar";

var dateFormat = require("dateformat");

class FilterModal extends Component {
	constructor(props) {
		super(props);
		const filterValues = props.filterValues;
		this.state = {
			filter_object: (filterValues && filterValues.filter_object) || {},
			minDate: "",
			maxDate: "",
			currencies: filterValues
				? filterValues.currencies
				: [
						{ value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
						{ value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false },
						{ value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false },
						{ value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false },
				  ],
			calendar_date: new Date(),
			sorting_icon: filterValues
				? filterValues.sorting_order === "DESC"
					? "fas fa-sort-down fa-2x"
					: "fas fa-sort-up fa-2x"
				: "fas fa-sort-down fa-2x",
			sorting_order: filterValues ? filterValues.sorting_order : "DESC",
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	/**
	 * close modal if clicked on outside of element
	 */
	handleClickOutside(event) {
		if (
			this.wrapperRef &&
			!this.wrapperRef.contains(event.target) &&
			event.srcElement.offsetParent.className !== "filterModalIcon"
		) {
			this.props.closeModal();
		}
	}

	childEvent(event) {
		event.stopPropagation();
		if (this.props.childEvent) {
			this.props.childEvent();
		}
	}

	handleClickCurrency = (currency_value) => {
		let currencies = [...this.state.currencies];

		currencies.forEach((currency) => {
			if (currency.value === currency_value) {
				currency.isChecked = !currency.isChecked;
			}
		});
		this.setState({
			currencies: currencies,
		});
	};

	handleInput = (e) => {
		const input_data = e.target;
		this.setState((prevState) => {
			let filter_object = Object.assign({}, prevState.filter_object);
			filter_object[
				input_data.name === undefined ? input_data.getAttribute("name") : input_data.name
			] = input_data.value === undefined ? input_data.getAttribute("value") : input_data.value;
			return { filter_object };
		});
	};

	resetFilters = () => {
		let currencies = [...this.state.currencies];
		currencies.forEach((currency) => {
			currency.isChecked = false;
		});

		this.setState({
			currencies: currencies,
			filter_object: {},
			minDate: "",
			maxDate: "",
			calendar_date: new Date(),
		});
	};

	onSaveFilters = () => {
		this.props.closeModal();
		this.props.filterRecords(this.state);
	};

	handleDate = (type) => {
		this.setState({
			date_input_type: type,
			calendar_active: true,
		});
	};

	onChangeCalendar = (date) => {
		let e = null;
		let formated_date = dateFormat(date, "dd/mm/yyyy");

		if (this.state.date_input_type === "minDate") {
			e = { target: { value: formated_date, name: "minDate" } };
			this.setState({ minDate: formated_date });
		} else {
			e = { target: { value: formated_date, name: "maxDate" } };
			this.setState({ maxDate: formated_date });
		}

		this.handleInput(e);
		this.setState({
			calendar_date: date,
			calendar_active: false,
		});
	};

	changeSortingIcon = (e) => {
		e.stopPropagation();
		if (this.state.sorting_icon === "fas fa-sort-down fa-2x") {
			this.setState({
				sorting_icon: "fas fa-sort-up fa-2x",
				sorting_order: "ASC",
			});
		} else {
			this.setState({
				sorting_icon: "fas fa-sort-down fa-2x",
				sorting_order: "DESC",
			});
		}
	};

	getValue = (value) => {
		if (value === undefined) {
			return "";
		} else {
			return value;
		}
	};

	render() {
		const sort_element = (
			<i
				className={[this.state.sorting_icon, classes.SortIcon].join(" ")}
				style={this.state.sorting_icon === "fas fa-sort-up fa-2x" ? { marginTop: "7px" } : null}
				onClick={this.changeSortingIcon}
			></i>
		);
		return (
			<div
				ref={this.setWrapperRef}
				className={[classes.ModalItem, classes.FilterModal].join(" ")}
				onClick={this.childEvent.bind(this)}
			>
				<div className={classes.ModalHeader}>
					<div className={classes.FilterModalHeader}>FILTERS</div>
					<div className={classes.FilterModalHeaderIcon}>
						<i
							className="fas fa-undo-alt"
							style={{ color: "white", fontSize: "23px", marginRight: "10px" }}
							onClick={this.resetFilters}
						></i>
					</div>
				</div>

				<div className={[classes.ModalBody, classes.FilterModalBody].join(" ")}>
					<div className={classes.FilterItem}>
						<div className={classes.ItemHeader}>
							{" "}
							<p className={classes.ItemTitle}>Currency</p>{" "}
						</div>
						<div className={classes.Currencies}>
							{this.state.currencies.map((currency) => {
								const currency_style = currency.isChecked
									? {
											background: "#285A64",
											color: "white",
									  }
									: {
											backround: "none",
											color: "#285A64",
									  };
								return (
									<div
										key={currency.value}
										style={currency_style}
										className={classes.Currency}
										onClick={() => this.handleClickCurrency(currency.value)}
									>
										{currency.sign}
									</div>
								);
							})}
						</div>
					</div>
					<div>
						<div
							className={classes.ItemHeader}
							name="sortingElement"
							value="value"
							onClick={(e) => this.handleInput(e)}
						>
							<p className={classes.ItemTitle}>Value</p>
							{this.state.filter_object.sortingElement === "value" ? sort_element : null}
						</div>
						<div className={classes.Currencies}>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Min"
								name="minValue"
								value={this.getValue(this.state.filter_object.minValue)}
								onChange={(e) => this.handleInput(e)}
							/>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Max"
								name="maxValue"
								value={this.getValue(this.state.filter_object.maxValue)}
								onChange={(e) => this.handleInput(e)}
							/>
						</div>
					</div>
					<div>
						<div
							className={classes.ItemHeader}
							name="sortingElement"
							value={"amount_paid_cents"}
							onClick={(e) => this.handleInput(e)}
						>
							<p className={classes.ItemTitle}>Price paid (L)</p>
							{this.state.filter_object.sortingElement === "amount_paid_cents"
								? sort_element
								: null}
						</div>
						<div className={classes.Currencies}>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Min"
								name="minPaidValue"
								value={this.getValue(this.state.filter_object.minPaidValue)}
								onChange={(e) => this.handleInput(e)}
							/>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Max"
								name="maxPaidValue"
								value={this.getValue(this.state.filter_object.maxPaidValue)}
								onChange={(e) => this.handleInput(e)}
							/>
						</div>
					</div>
					<div>
						<div
							className={classes.ItemHeader}
							name="sortingElement"
							value={"rate"}
							onClick={(e) => this.handleInput(e)}
						>
							<p className={classes.ItemTitle}>Rate</p>
							{this.state.filter_object.sortingElement === "rate" ? sort_element : null}
						</div>
						<div className={classes.Currencies}>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Min"
								name="minRateValue"
								value={this.getValue(this.state.filter_object.minRateValue)}
								onChange={(e) => this.handleInput(e)}
							/>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Max"
								name="maxRateValue"
								value={this.getValue(this.state.filter_object.maxRateValue)}
								onChange={(e) => this.handleInput(e)}
							/>
						</div>
					</div>

					<div>
						<div
							className={classes.ItemHeader}
							name="sortingElement"
							value={"datetime"}
							onClick={(e) => this.handleInput(e)}
						>
							<p className={classes.ItemTitle}>Date</p>
							{this.state.filter_object.sortingElement === "datetime" ? sort_element : null}
						</div>
						<div className={classes.Currencies}>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="00/00/0000"
								name="minDate"
								value={this.state.filter_object.minDate || this.state.minDate}
								onClick={() => this.handleDate("minDate")}
								readOnly={true}
							/>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="00/00/0000"
								name="maxDate"
								value={this.state.filter_object.maxDate || this.state.maxDate}
								onClick={() => this.handleDate("maxDate")}
								readOnly={true}
							/>
						</div>
					</div>
					{this.state.calendar_active ? (
						<div className="filter_calendar">
							<CalendarComponent
								onChange={this.onChangeCalendar}
								value={this.state.calendar_date}
							/>
						</div>
					) : null}

					<div>
						<div className={classes.ItemHeader}>
							<p className={classes.ItemTitle}>Account Name</p>
						</div>
						<div className={classes.Currencies}>
							<input
								type="text"
								className={classes.Inputs}
								placeholder="Name"
								name="accountName"
								value={this.getValue(this.state.filter_object.accountName)}
								onChange={(e) => this.handleInput(e)}
							/>
						</div>
					</div>

					<div className={classes.CenterButtons}>
						<button className={classes.YellowButton} onClick={() => this.onSaveFilters()}>
							SAVE
						</button>
						<button className={classes.WhiteButton} onClick={() => this.props.closeModal()}>
							{" "}
							CANCEL{" "}
						</button>
					</div>
				</div>
			</div>
		);
	}
}
export default FilterModal;
