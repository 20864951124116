import gql from "graphql-tag";

export default gql`
	query ($attributes: FilterCurrencyTraderInput, $status: String) {
		currencyTraderOrders(attributes: $attributes, status: $status) {
			... on PurchaseOrder {
				amountPaidCents
				id
				amountPaidCurrency
				amountPurchasedCents
				amountPurchasedCurrency
				datetime
				rate
				number
				expiration
				status
				updatedAt
				availableTradingAmount
				percentagePurchased
				portionPurchased
				typology
				portfolio {
					user {
						firstName
						lastName
						globalName
						cellphone
						city
						country
						email
						address
						personalIdNumber
						dateOfBirth
						postalCode
						typology
						tradingDailyLimit
						servicePoint {
							commercialName
						}
						document {
							typology
							issueDate
							issueEntity
							expirationDate
							number
							description
							identityDocumentUrl
							identityDocumentFilename
						}
					}
				}
			}
			... on SaleOrder {
				id
				amountPaidCents
				amountSoldCents
				amountPaidCurrency
				amountSoldCurrency
				datetime
				rate
				number
				companyRepresentative
				expiration
				status
				updatedAt
				availableTradingAmount
				percentageSold
				portionSold
				typology
				portfolio {
					user {
						firstName
						lastName
						globalName
						cellphone
						city
						country
						email
						address
						personalIdNumber
						dateOfBirth
						postalCode
						typology
						tradingDailyLimit
						servicePoint {
							commercialName
						}
						document {
							typology
							issueDate
							issueEntity
							expirationDate
							number
							description
							identityDocumentUrl
							identityDocumentFilename
						}
					}
				}
			}
		}
	}
`;
