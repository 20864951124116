import React, { useState, useEffect } from "react";
import classes from "./MainPage.module.scss";
import Users from "./Users";
import Roles from "./Roles";
import { Link } from "react-router-dom";
import GetRoles from "../../../queries/RoleManagement/Roles";
import { useQuery } from "@apollo/react-hooks";
import GetCurrentUser from "../../../queries/LoggedUser";

const MainPage = () => {
	//users
	//roles
	const [page, setPage] = useState("users");
	const [usersOpacity, setUsersOpacity] = useState(1);
	const [rolesOpacity, setRolesOpacity] = useState(0.3);
	const [newRole, setNewRole] = useState(false);
	const [editSelectedUser, setEditSelectedUser] = useState(null);

	const handleUsersOnClick = () => {
		setPage("users");
		setUsersOpacity(1);
		setRolesOpacity(0.3);
		setNewRole(false);
	};

	const handleRolesOnClick = () => {
		setPage("roles");
		setUsersOpacity(0.3);
		setRolesOpacity(1);
		setNewRole(false);
	};

	const createNewRole = () => {
		setNewRole(true);
		setPage("roles");
		setUsersOpacity(0.3);
		setRolesOpacity(1);
	};

	const editUser = (selectedUser) => {
		setPage("users");
		setUsersOpacity(1);
		setRolesOpacity(0.3);
		setEditSelectedUser(selectedUser);
	};

	const [roles, setRoles] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);

	const { loading, error, data } = useQuery(GetRoles, {
		onCompleted: () => {
			if (!loading && !error && currentUser) {
				if (currentUser.role.name !== "super technical admin") {
					setRoles(
						data.roles
							.filter((role) => role.name !== "super technical admin")
							.map((role) => {
								return { id: role.id, value: role.name };
							})
					);
				} else {
					setRoles(
						data.roles.map((role) => {
							return { id: role.id, value: role.name };
						})
					);
				}
			}
		},
	});

	const getCurrentUser = useQuery(GetCurrentUser, {
		onCompleted: () => {
			if (!getCurrentUser.loading && !getCurrentUser.error) {
				setCurrentUser(getCurrentUser.data.currentUser);
			}
		},
	});

	useEffect(() => {
		if (!getCurrentUser.loading && !getCurrentUser.error) {
			setCurrentUser(getCurrentUser.data.currentUser);
		}
	}, [getCurrentUser]);

	return (
		<div className={classes.Container}>
			<div className={classes.BackgroundModal}>
				<div className={classes.ColoredDiv}></div>
				<div className={classes.ColoredDiv} style={{ marginTop: 93, height: 54 }}></div>
			</div>
			<div className={classes.UserManagement}>
				<div className={classes.Header}>
					<span style={{ marginRight: 5, opacity: usersOpacity }} onClick={handleUsersOnClick}>
						USERS{" "}
					</span>{" "}
					|
					<span style={{ marginLeft: 5, opacity: rolesOpacity }} onClick={handleRolesOnClick}>
						ROLES
					</span>
					<Link to="/welcome">
						<i className="fas fa-times-circle" style={{ marginLeft: "auto", color: "white" }}></i>
					</Link>
				</div>
				{page === "users" && (
					<Users
						createNewRole={createNewRole}
						editSelectedUser={editSelectedUser}
						roles={roles}
						currentUser={currentUser}
					/>
				)}
				{page === "roles" && <Roles newRole={newRole} editUser={editUser} />}
			</div>
		</div>
	);
};

export default MainPage;
