import React from "react";
import classes from "./TradingBook.module.scss";
import NumberFormat from "react-number-format";

const TransactionCard = (props) => {
	const type = props.type;
	const index = props.index;
	const transactionSelectedIndex = props.transactionSelectedIndex;
	let cardStyle;
	if (type === "purchase") {
		cardStyle = {
			background: "white",
			borderLeftColor: "#285A64",
		};
	} else {
		cardStyle = {
			background: "white",
			borderLeftColor: "#BFD2C7",
		};
	}
	if (index === transactionSelectedIndex) {
		if (type === "purchase") {
			cardStyle.background = "#C2D0D3";
		} else {
			cardStyle.background = "#ECF2EF";
		}
	}

	return (
		<div
			className={classes.TransactionCard}
			style={cardStyle}
			onClick={() => props.showDetails(type, index)}
		>
			<div className={classes.FirstRow}>
				<span className={["fa-stack", classes.UserIcon].join(" ")}>
					<i
						className="fas fa-circle fa-stack-2x"
						style={type === "purchase" ? { color: "#285a64" } : { color: "#BFD2C7" }}
					></i>
					<i className="fas fa-user fa-stack-1x fa-inverse" style={{ fontSize: "16px" }}></i>
				</span>
				<span>{props.service_point_name}</span>
			</div>
			<div style={{ fontSize: 20 }}>
				<span>
					<NumberFormat
						displayType={"text"}
						thousandSeparator={true}
						suffix={".00 " + props.currency}
						value={props.amount}
					/>
				</span>
				<span style={{ float: "right" }}>@ {props.rate}</span>
			</div>
			<div style={{ fontSize: 12 }}>
				<span>Expires: {props.datetime}</span>
				<span style={{ float: "right" }}>Total cost: {props.total_cost}</span>
			</div>
		</div>
	);
};

export default TransactionCard;
