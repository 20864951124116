import React, { useState, useEffect } from "react";
import PageColumn from "./PageColumn";
import classes from "./IncomingPayments.module.scss";
import DetailsActionCard from "./DetailsActionCard";
import Graphs from "./Graphs";
import GraphsModal from "./GraphsModal";

import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { IncomingPaymentsQuery, CounterQuery, UsersQuery } from "./IncomingPaymentsQuery";
import ChangeOnlineOrderStatus from "../../../mutations/Payments/ChangeOnlineOrderStatus";
import CaesarsInvestmentOrders from "../../../mutations/Payments/CaesarsInvestmentOrders";
import SendMissingPaymentNotification from "../../../mutations/Payments/SendMissingPaymentNotification";
import BankDetails from "../../../queries/CurrencyTrader/BankDetails";
import TransactionDetailsCard from "./TransactionDetailsCard";
import current_user from "../../../queries/LoggedUser";

const IncomingPayments = () => {
	const [selectedDue, setselectedDue] = useState({});
	const [payments, setPayments] = useState({ currencyTraderOrders: [] });
	const [counter, set_pagination_counter] = useState(0);
	const [loading, setLoading] = useState(true);
	const [graphModalDisplay, setGraphModalDisplay] = useState(false);
	const [detailsCard, setDetailsCard] = useState({ show: false });
	const [selectedItems, setSelectedItems] = useState({});
	const [markAsPaidDisplay, setMarkAsPaidDisplay] = useState(true);
	const [markAsUnpaidDisplay, setMarkAsUnpaidDisplay] = useState(false);
	const [markAsPaidDisable, setMarkAsPaidDisable] = useState(true);
	const [markAsUnpaidDisable, setMarkAsUnpaidDisable] = useState(true);
	const [pageSelectAll, setPageSelectAll] = useState({});
	const [user_id, setUserId] = useState(0);

	const [activePage, setActivePage] = useState(1);
	const [offset, setOffset] = useState(null);

	const [filters, setFilters] = useState({
		offset: offset,
		incoming: true,
		sortingElement: "value",
		sortingOrder: "ASC",
	});
	const { loading: loading_graph, data: graphData } = useQuery(BankDetails, {
		variables: { type: "incoming" },
	});
	const { loading: loading_user, data: user_id_data } = useQuery(current_user, {
		onCompleted: (data) => {
			setUserId(data.currentUser.id);
		},
	});
	const [changeOrderStatus] = useMutation(ChangeOnlineOrderStatus);
	const [caesarsInvestmentOrders] = useMutation(CaesarsInvestmentOrders);
	const [sendMissingPaymentNotification] = useMutation(SendMissingPaymentNotification);
	const [chips, setChips] = useState([]);
	const [guarantee, setGuarantee] = useState({ display: false });

	const [isMatching, setIsMatching] = useState(false);

	useEffect(() => {
		paginationClicked(activePage);
	}, []);

	useEffect(() => {
		if (!loading) {
			selectedAll();
		}
	}, [payments, loading]);

	const paginationClicked = (pageNumber) => {
		var offset = null;
		IncomingPaymentsQuery(filters, offset).then((result) => {
			// CounterQuery(filters).then(result_counter => {
			setActivePage(pageNumber);
			setOffset(offset);
			setPayments(result.data);
			if (loading) {
				setLoading(false);
			}
			// set_pagination_counter(result_counter.data.countCurrencyTrader)
			// })
		});
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		setPageSelectAll(newPageSelectAll);
	};

	// if (loading) {
	//   return (null)
	// } else {
	const filterSearchBox = (value) => {
		var newFilters = filters;
		newFilters.iban = value;
		UsersQuery(filters).then((result) => {
			if (result.data.users) {
				if (result.data.users.length == 1) {
					setGuarantee({ display: true, user: result.data.users[0] });
				} else {
					setGuarantee({ display: false });
				}
			} else {
				setGuarantee({ display: false });
			}
		});
		setFilters(newFilters);
		paginationClicked(1);
		setSelectedItems({});
		setselectedDue({});
		setPageSelectAll({});
	};

	function selectedAll() {
		var list = {};
		var newSelected = Object.assign({}, selectedItems);
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		payments.currencyTraderOrders.map((index, key) => {
			if (!selectedItems.hasOwnProperty(index.id)) {
				newSelected[index.id] = index;
			}
		});
		for (const [key, value] of Object.entries(newSelected)) {
			var bank = value.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
			if (list.hasOwnProperty(bank)) {
				list[bank].values.push(value);
			} else {
				list[bank] = { bank: value.bankAccounts[0].bankName, values: [] };
				list[bank].values.push(value);
			}
		}
		newPageSelectAll[activePage] = true;
		setSelectedItems(newSelected);
		setselectedDue(list);
		setPageSelectAll(newPageSelectAll);
		controllButtonStates(newSelected);
	}

	function deselectedAll() {
		var list = Object.assign({}, selectedDue);
		var newSelected = Object.assign({}, selectedItems);
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		payments.currencyTraderOrders.map((index, key) => {
			var bank = index.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
			for (var i = 0; i < list[bank].values.length; i++) {
				if (list[bank].values[i].id === index.id) {
					list[bank].values.splice(i, 1);
					break;
				}
			}
			if (newSelected.hasOwnProperty(index.id)) {
				delete newSelected[index.id];
			}
		});
		for (const [key, value] of Object.entries(list)) {
			if (value.values.length == 0) {
				delete list[key];
			}
		}
		newPageSelectAll[activePage] = false;
		setSelectedItems(newSelected);
		setselectedDue(list);
		setPageSelectAll(newPageSelectAll);
		controllButtonStates(newSelected);
	}

	function addToSelected(payment) {
		var list = Object.assign({}, selectedDue);
		var bank = payment.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		if (list.hasOwnProperty(bank)) {
			list[bank].values.push(payment);
		} else {
			list[bank] = { bank: bank, values: [] };
			list[bank].values.push(payment);
		}
		var newSelected = Object.assign({}, selectedItems);
		newSelected[payment.id] = payment;
		// Main Toggle
		var forMainCounter = 0;
		payments.currencyTraderOrders.map((index, key) => {
			var bankM = index.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
			if (list.hasOwnProperty(bankM)) {
				for (var i = 0; i < list[bankM].values.length; i++) {
					if (list[bankM].values[i].id === index.id) {
						forMainCounter++;
						break;
					}
				}
			}
		});

		if (forMainCounter == payments.currencyTraderOrders.length) {
			newPageSelectAll[activePage] = true;
		} else {
			newPageSelectAll[activePage] = false;
		}
		setSelectedItems(newSelected);
		setselectedDue(list);
		setPageSelectAll(newPageSelectAll);
		controllButtonStates(newSelected);
	}
	function removeFromSelected(payment) {
		var list = Object.assign({}, selectedDue);
		var bank = payment.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		for (var i = 0; i < list[bank].values.length; i++) {
			if (list[bank].values[i].id === payment.id) {
				list[bank].values.splice(i, 1);
				break;
			}
		}
		if (list[bank].values.length == 0) {
			delete list[bank];
		}
		var newSelected = Object.assign({}, selectedItems);
		delete newSelected[payment.id];

		// Main Toggle
		var forMainCounter = 0;
		payments.currencyTraderOrders.map((index, key) => {
			var bank2 = index.bankAccounts[0].bankName.replace(/\s+/g, "-").toLowerCase();
			if (list.hasOwnProperty(bank2)) {
				for (var i = 0; i < list[bank2].values.length; i++) {
					if (list[bank2].values[i].id === index.id) {
						forMainCounter++;
						break;
					}
				}
			}
		});
		if (forMainCounter == 7) {
			newPageSelectAll[activePage] = true;
		} else {
			newPageSelectAll[activePage] = false;
		}
		setSelectedItems(newSelected);
		setselectedDue(list);
		setPageSelectAll(newPageSelectAll);
		controllButtonStates(newSelected);
	}

	function controllButtonStates(newSelectedItems = selectedItems) {
		var toPay = false;
		var toUnpay = false;
		for (const [key, value] of Object.entries(newSelectedItems)) {
			if (!value.paidToPortal) {
				toPay = true;
			} else {
				toUnpay = true;
			}
		}
		if (toPay && toUnpay) {
			setMarkAsPaidDisable(true);
			setMarkAsUnpaidDisable(true);
			setMarkAsPaidDisplay(true);
			setMarkAsUnpaidDisplay(true);
		} else if (toUnpay) {
			setMarkAsPaidDisable(true);
			setMarkAsUnpaidDisable(false);
			setMarkAsUnpaidDisplay(true);
		} else if (toPay) {
			setMarkAsPaidDisplay(true);
			setMarkAsPaidDisable(false);
			setMarkAsUnpaidDisable(true);
			setMarkAsUnpaidDisplay(false);
		} else {
			setMarkAsPaidDisable(true);
			setMarkAsUnpaidDisable(true);
			setMarkAsPaidDisplay(true);
			setMarkAsUnpaidDisplay(false);
		}
	}

	function markAsPaid(paid = true) {
		var items = [];
		for (const [key, value] of Object.entries(selectedItems)) {
			items.push(value.number);
		}
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		newPageSelectAll[activePage] = false;
		changeOrderStatus({
			variables: {
				numbers: items ? items : null,
				paid_status: paid,
				direction: "incoming",
			},
		})
			.then((result) => {
				paginationClicked(activePage);
				setSelectedItems({});
				setselectedDue({});
				setMarkAsPaidDisplay(true);
				setMarkAsUnpaidDisplay(false);
				setMarkAsPaidDisable(true);
				setMarkAsUnpaidDisable(true);
				setPageSelectAll(newPageSelectAll);
			})
			.catch((error) => console.log(error));
	}

	const sendNotice = () => {
		var items = [];
		for (const [key, value] of Object.entries(selectedItems)) {
			items.push(value.number);
		}
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		newPageSelectAll[activePage] = false;
		sendMissingPaymentNotification({
			variables: {
				orders: items ? items : null,
			},
		})
			.then((result) => {
				paginationClicked(activePage);
				setSelectedItems({});
				setselectedDue({});
				setMarkAsPaidDisplay(true);
				setMarkAsUnpaidDisplay(false);
				setMarkAsPaidDisable(true);
				setMarkAsUnpaidDisable(true);
				setPageSelectAll(newPageSelectAll);
			})
			.catch((error) => console.log(error));
	};

	const sendDefault = () => {
		var items = [];
		for (const [key, value] of Object.entries(selectedItems)) {
			items.push(value.number);
		}
		var newPageSelectAll = Object.assign({}, pageSelectAll);
		newPageSelectAll[activePage] = false;
		UsersQuery(filters).then((result) => {
			if (result.data.users) {
				if (result.data.users.length == 1) {
					setGuarantee({ display: true, user: result.data.users[0] });
				} else {
					setGuarantee({ display: false });
				}
			} else {
				setGuarantee({ display: false });
			}
		});
		var missingPayment = document.getElementById("defaulted_ID").getAttribute("missing_payment");
		caesarsInvestmentOrders({
			variables: {
				orders: items ? items : null,
				missing_payment: missingPayment ? parseFloat(missingPayment) : null,
			},
		})
			.then((result) => {
				paginationClicked(activePage);
				setSelectedItems({});
				setselectedDue({});
				setMarkAsPaidDisplay(true);
				setMarkAsUnpaidDisplay(false);
				setMarkAsPaidDisable(true);
				setMarkAsUnpaidDisable(true);
				setPageSelectAll(newPageSelectAll);
			})
			.catch((error) => console.log(error));
	};

	const addFilters = (filter) => {
		// setFilters(filter);
		var currency_list = [];
		var bank_list = [];
		var status_list = [];
		for (var i = 0; i < filter.length; i++) {
			switch (filter[i].type) {
				case "Bank":
					bank_list.push(filter[i].name);
					break;
				case "Currency":
					currency_list.push(filter[i].value);
					break;
				case "Status":
					status_list.push(filter[i].name);
					break;
				default:
			}
		}
		var newFilters = filters;
		if (bank_list.length > 0) {
			newFilters.banks = bank_list;
		} else {
			delete newFilters.banks;
		}
		if (currency_list.length > 0) {
			newFilters.currency = currency_list;
			newFilters.incoming = true;
		} else {
			delete newFilters.currency;
		}

		var s_paid = status_list.filter((x) => x == "Paid").length > 0;
		var s_unpaid = status_list.filter((x) => x == "Unpaid").length > 0;
		var defaulted = status_list.filter((x) => x == "Default").length > 0;

		if (s_paid && s_unpaid) {
			newFilters.paidToPortal = null;
		} else if (s_paid) {
			newFilters.paidToPortal = true;
		} else if (s_unpaid) {
			newFilters.paidToPortal = false;
		} else {
			delete newFilters.paidToPortal;
		}

		if (defaulted) {
			newFilters.defaulted = defaulted;
		} else {
			if (s_paid || s_unpaid) {
				newFilters.defaulted = false;
			} else {
				delete newFilters.defaulted;
			}
		}

		if (s_paid && s_unpaid && defaulted) {
			delete newFilters.paidToPortal;
			delete newFilters.defaulted;
		}

		setFilters(newFilters);
		paginationClicked(1);
		setSelectedItems({});
		setselectedDue({});
		setPageSelectAll({});
	};

	var pageColumn_state = {
		selectedDue: selectedDue,
		counter: counter,
		activePage: activePage,
		offset: offset,
		filters: filters,
		chips: chips,
		selectedItems: selectedItems,
		isMatching: isMatching,
		pageSelectAll: pageSelectAll,
		user_id: user_id,
	};
	var pageColumn_setStates = {
		setChips: setChips,
		setActivePage: setActivePage,
		setOffset: setOffset,
		setFilters: setFilters,
		setDetailsCard: setDetailsCard,
	};
	var pageColumn_actions = {
		selectedAll: selectedAll,
		deselectedAll: deselectedAll,
		addToSelected: addToSelected,
		removeFromSelected: removeFromSelected,
		addFilters: addFilters,
		filterSearchBox: filterSearchBox,
		paginationClicked: paginationClicked,
	};
	var detailsActionCard_state = {
		isMatching: isMatching,
	};
	var detailsActionCard_setStates = {
		setIsMatching: setIsMatching,
	};
	return (
		<>
			<div className={classes.Page}>
				<PageColumn
					data={payments.currencyTraderOrders}
					orders_counter={counter}
					actions={pageColumn_actions}
					state={pageColumn_state}
					setState={pageColumn_setStates}
				/>
				<DetailsActionCard
					selectedDue={selectedDue}
					selectedItems={selectedItems}
					state={detailsActionCard_state}
					setState={detailsActionCard_setStates}
					markAsPaid={markAsPaid}
					sendNotice={sendNotice}
					sendDefault={sendDefault}
					markAsPaidDisplay={markAsPaidDisplay}
					markAsUnpaidDisplay={markAsUnpaidDisplay}
					markAsPaidDisable={markAsPaidDisable}
					markAsUnpaidDisable={markAsUnpaidDisable}
					guarantee={guarantee}
				/>
				<Graphs data={graphData} display={graphModalDisplay} setDisplay={setGraphModalDisplay} />
				{graphModalDisplay ? (
					<GraphsModal
						data={graphData}
						display={graphModalDisplay}
						setDisplay={setGraphModalDisplay}
					/>
				) : null}
			</div>
			{detailsCard.show ? (
				<TransactionDetailsCard setDetailsCard={setDetailsCard} detailsCard={detailsCard} />
			) : null}
		</>
	);
	// }
};

export default IncomingPayments;
