import React, { useState, useEffect } from "react";
import classes from "../Accounts.module.scss";
import ShareholdersPopup from "./ShareholdersPopup";
import FileViewerPopup from "./FileViewerPopup";
import AllUsersPopup from "./AllUsersPopup";

const BusinessAccount = (props) => {
	var dateFormat = require("dateformat");

	const [account, setAccount] = useState(props.account);

	useEffect(() => {
		setAccount(props.account);
	}, [props.account]);

	const shareholders = props.account.headquarter.shareholders;

	const [openShareholdersPopup, setOpenShareholdersPopup] = useState(false);
	const [file_viewer, setFileViewer] = useState(false);
	const [allUsersPopup, setAllUsersPopup] = useState(false);

	const toggleShareholdersPopup = () => {
		setOpenShareholdersPopup(!openShareholdersPopup);
	};

	return (
		<div className={classes.UserInfo}>
			<span>
				<b>Account Type: </b>
				{props.typology}
			</span>

			<div className={classes.Content}>
				<div style={{ width: 425 }}>
					<div className={classes.Section1}>
						<div className={classes.Title}>
							<b>Branch Office</b>
						</div>
						<div>
							<b>{account.firstName}</b>
						</div>
						<div>{account.personalIdNumber}</div>
						<div>
							<div>{account.address} </div>
							<div>
								{account.postalCode} {account.city}, {account.country}
							</div>
						</div>
						<div style={{ display: "flex", flexDirection: "row" }}>
							<div style={{ width: "77%" }}>
								<div>{account.email}</div>
								<div> Phone: {account.cellphone}</div>
								<div>Fax: {account.fax}</div>
							</div>
							<div className={classes.ActionButtons}>
								<button
									className={classes.YellowButton}
									style={{ marginBottom: 7, width: 132 }}
									onClick={() => setAllUsersPopup(true)}
								>
									ALL USERS
								</button>
								{allUsersPopup ? (
									<AllUsersPopup
										name="ALL USERS"
										employees={account.employees}
										closePopup={() => setAllUsersPopup(false)}
									/>
								) : null}
								<a href={account.signedContractUrl} target="_blank" rel="noopener noreferrer">
									<button className={classes.YellowButton} style={{ width: 132 }}>
										CONTRACT
									</button>
								</a>
							</div>
						</div>
					</div>
					<div className={classes.Title} style={{ marginBottom: 30 }}>
						<b>Headquarters Information</b>
					</div>
					<div className={classes.Section2}>
						<div>
							<div>{account.headquarter.address} </div>
							<div>
								{account.headquarter.postalCode} {account.headquarter.city},{" "}
								{account.headquarter.country}
							</div>
							<div>{account.headquarter.email}</div>
						</div>
						<div>
							<b>CEO:</b> {account.headquarter.ceo}
							<br />
							<br />
							<button
								className={classes.YellowButton}
								style={{ marginBottom: 7, width: 162 }}
								onClick={toggleShareholdersPopup}
							>
								SHAREHOLDERS
							</button>
							<br />
							{openShareholdersPopup && (
								<ShareholdersPopup
									toggleShareholdersPopup={toggleShareholdersPopup}
									shareholders={shareholders}
								/>
							)}
							<button
								className={classes.YellowButton}
								style={{ width: 162 }}
								onClick={() => setFileViewer(true)}
							>
								CERTIFICATES
							</button>
							{file_viewer ? (
								<FileViewerPopup
									name={"CERTIFICATES"}
									headquarter={account.headquarter}
									closePopup={() => setFileViewer(false)}
								/>
							) : null}
						</div>
					</div>
				</div>
				<div style={{ width: 425, marginLeft: 28 }}>
					<div className={classes.Section3}>
						<div className={classes.Title} style={{ marginBottom: 30 }}>
							<b>Administrator Information</b>
						</div>
						<div className={classes.Section2}>
							<div className={classes.SubSection}>
								<div>
									<b>
										{account.firstName} {account.lastName}
									</b>{" "}
									(administrator)
								</div>
								<div>
									<div>{account.personalIdNumber}</div>
									<div>DOB: {dateFormat(new Date(account.dateOfBirth), "dd/mm/yyyy")}</div>
								</div>
								<div>
									<div>{account.address}</div>
									<div>
										{account.postalCode} {account.city}, {account.country}
									</div>
								</div>
								<div>
									<div>{account.email}</div>
									<div>{account.cellphone}</div>
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
								<div>
									<div>{account.document.typology} </div>
									<div>No: {account.document.number}</div>
									<div>Issued: {account.document.issueDate}</div>
									<div>Expires: {account.document.expirationDate}</div>
								</div>
								<a
									href={account.document.identityDocumentUrl}
									target="_blank"
									rel="noopener noreferrer"
								>
									<button
										className={classes.YellowButton}
										style={{ width: 131, marginLeft: 0, marginTop: 20 }}
									>
										ID CARD
									</button>
								</a>
							</div>
						</div>
					</div>
					<div>
						<div className={classes.Title}>
							<b>Manage Account Access</b>
						</div>
						<div className={classes.Section4}>
							<div>
								Use the buttons <br /> to the right to <br />
								manage account <br />
								access
							</div>
							<div style={{ marginRight: 63 }}>
								<div>
									<b>Status:</b>{" "}
									{account.state === "ready_for_activation" ? "pending" : account.state}
								</div>
								{account.state === "active" ? (
									<>
										<div
											style={{ display: "flex", alignItems: "center" }}
											onClick={props.suspendUser}
										>
											<i
												className="fas fa-minus-square fa-3x"
												style={{ color: "#D39C3E", marginRight: 7, cursor: "pointer" }}
											></i>
											<b>SUSPEND</b>
										</div>
										<div onClick={props.disableUser}>
											<i
												className={["fas fa-times fa-2x", classes.DisabledIcon].join(" ")}
												style={{ cursor: "pointer" }}
											></i>
											<b>DISABLE</b>
										</div>
									</>
								) : (
									<>
										<div
											style={{ display: "flex", alignItems: "center" }}
											onClick={props.approveUser}
										>
											<i
												className="fas fa-check-square fa-3x"
												style={{ color: "#649177", marginRight: 7, cursor: "pointer" }}
											></i>
											<b>ACTIVATE</b>
										</div>
										<div onClick={props.disableUser}>
											<i
												className={["fas fa-times fa-2x", classes.DisabledIcon].join(" ")}
												style={{ cursor: "pointer" }}
											></i>
											<b>DISABLE</b>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BusinessAccount;
