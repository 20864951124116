import React, { useState } from "react";
import classes from "./MainPage.module.scss";
import DeleteRole from "../../../mutations/RoleManagement/DeleteRole";
import RolesQuery from "../../../queries/RoleManagement/Roles";
import { useMutation } from "react-apollo";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const AllRoles = (props) => {
	const roles = props.roles;

	const [selectedRole, setSelectedRole] = useState(props.selectedRole);
	const [showDetails, setShowDetails] = useState(false);
	const [deleteRoleMutation] = useMutation(DeleteRole, { refetchQueries: [{ query: RolesQuery }] });

	const handleShowDetails = (roleId) => {
		const role = roles.filter((role) => role.id === roleId)[0];

		if (selectedRole === null) {
			setSelectedRole(role);
			setShowDetails(true);
		} else if (selectedRole !== null) {
			if (selectedRole.id !== role.id) {
				setSelectedRole(role);
				setShowDetails(true);
			} else {
				setSelectedRole(null);
				setShowDetails(false);
			}
		}
	};

	const handleDeleteRole = (event, roleId) => {
		event.stopPropagation();
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className={classes.CustomConfirmationDialog}>
						<h3>Are you sure you want to delete this role?</h3>
						<button className={classes.WhiteButton} onClick={onClose}>
							No
						</button>
						<button
							className={classes.YellowButton}
							onClick={() => {
								deleteRole(roleId);
								onClose();
							}}
						>
							Yes
						</button>
					</div>
				);
			},
		});
	};

	const deleteRole = (roleId) => {
		deleteRoleMutation({
			variables: {
				role_id: roleId,
			},
		});
	};

	let permissionsHTML = null;
	if (selectedRole !== null) {
		permissionsHTML = selectedRole.permissions
			.filter((permission) => permission.name !== "userManagement")
			.map((permission) => (
				<div key={permission.name} className={classes.Permission}>
					<b>{props.permissions.filter((item) => item.name === permission.name)[0].displayName}</b>
					{permission.status === true ? (
						<i className={["fas fa-toggle-on fa-lg", classes.ToggleIcon].join(" ")}></i>
					) : (
						<i
							className={["fas fa-toggle-on fa-lg fa-flip-horizontal", classes.LowOpacityIcon].join(
								" "
							)}
						></i>
					)}
				</div>
			));
	}

	const mainHTML = roles.map((role) => (
		<div key={role.id} className={classes.RoleCard} onClick={() => handleShowDetails(role.id)}>
			<div className={classes.Role}>
				{role.name !== "super technical admin" && role.name !== "admin" && (
					<div
						className={classes.SelectionCircle}
						style={
							props.selectedRole &&
							(props.selectedRole.id === role.id ? { background: "#D39C3E" } : null)
						}
						onClick={() => props.select(role)}
					></div>
				)}
				<div style={{ flex: 1, textAlign: "center" }}>{role.name}</div>
				{role.name !== "super technical admin" && role.name !== "admin" && (
					<div onClick={(event) => handleDeleteRole(event, role.id)}>
						<i className="fas fa-trash" style={{ color: "#D39C3E" }}></i>
					</div>
				)}
			</div>
			{showDetails &&
				selectedRole.id === role.id &&
				role.name !== "super technical admin" &&
				role.name !== "admin" && (
					<>
						<div className={classes.SubText}>
							{selectedRole.users.length} users assigned this role
						</div>
						<div className={classes.RoleDetails}>
							<div className={classes.PermissionList}>{permissionsHTML}</div>
						</div>
					</>
				)}
		</div>
	));

	return <div className={classes.AllRoles}>{mainHTML}</div>;
};

export default AllRoles;
