import React, { useState, useEffect } from "react";
import classes from "./OutgoingPayments.module.scss";
import BankIcon from "./BankIcon";
var dateFormat = require("dateformat");

const TransactionCard = (props) => {
	const [selectCircleStatus, setToggleCircleStatus] = useState(false);
	useEffect(() => {
		setToggleCircleStatus(props.selected);
	}, [props.selected]);

	var status = "";
	var StatusCardSpace = null;
	var opacity = 1;
	var Bcolor = "#d39c3e";

	if (props.data.paidFromPortal) {
		status = "PAID";
	}

	if (props.data.CI) {
		status = "DEFAULTED";
	}

	switch (status) {
		case "NOTIFIED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace}>
					<div className={classes.Description}>
						Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
					</div>
					<div className={classes.Text}>NOTIFIED</div>
				</div>
			);
			break;
		case "PAID":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					<div className={classes.Text}>PAID</div>
				</div>
			);
			opacity = 0.3;
			break;
		case "DEFAULTED":
			StatusCardSpace = (
				<div className={classes.StatusCardSpace} style={{ justifyContent: "center" }}>
					{props.data.lastNotifiedAt ? (
						<div className={classes.Description}>
							Last notice sent: {dateFormat(props.data.lastNotifiedAt, "mmmm d, yyyy HH:MM")}
						</div>
					) : null}
					<div className={classes.Text}>DEFAULTED</div>
				</div>
			);
			opacity = 0.3;
			Bcolor = "#9A2020";
			break;
	}
	return (
		<>
			{!props.data.CI && (
				<div
					className={
						selectCircleStatus
							? classes.SelectCircle + " " + classes.selected
							: classes.SelectCircle
					}
					onClick={() => toggleSelect()}
				></div>
			)}
			<div
				className={classes.TransactionCard}
				style={{ borderLeft: `26px solid ${Bcolor}`, opacity: opacity }}
			>
				<div style={{ display: "flex" }}>
					<div style={{ flex: 1 }}>
						<div className={classes.FirstRow}>
							<span>
								<BankIcon
									bank={{ bank_name: props.data.bankAccounts[0].bankName }}
									square={false}
								/>
							</span>
							<span style={{ fontSize: 15, marginLeft: 5 }}>
								{props.data.portfolio.user.globalName}
							</span>
						</div>
						<div style={{ fontSize: 20 }}>
							<span>
								{props.data.type === "purchase"
									? props.data.amountPurchasedCents
									: props.data.amountPaidCents}
							</span>
						</div>
						<div style={{ fontSize: 12 }}>
							<span>IBAN: {props.data.bankAccounts[0].iban}</span>
						</div>
					</div>

					{StatusCardSpace}
				</div>
			</div>
		</>
	);
	function toggleSelect() {
		if (!selectCircleStatus) {
			props.addToSelected(props.data);
		} else {
			props.removeFromSelected(props.data);
		}
		setToggleCircleStatus(!selectCircleStatus);
	}
};

export default TransactionCard;
