import React, { useState } from "react";
import classes from "./PenaltyPercentage.module.scss";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PenaltyPercentageQuery from "../../../queries/SiteSettings/PenaltyPercentage";
import PenaltyPercentageMutation from "../../../mutations/SiteSettings/PenaltyPercentage";

const PenaltyPercentage = () => {
	const [penaltyPercentage, setPenaltyPercentage] = useState("");
	const [editPenalty, setEditPenalty] = useState(false);
	const [penClicks, setPenClicks] = useState(0);

	const penaltyQuery = useQuery(PenaltyPercentageQuery, {
		onCompleted: () => {
			if (!penaltyQuery.error && !penaltyQuery.loading)
				setPenaltyPercentage(penaltyQuery.data.penaltyPercentage);
		},
	});

	const [penaltyMutation] = useMutation(PenaltyPercentageMutation, {
		refetchQueries: [{ query: PenaltyPercentageQuery }],
	});
	const handleOnChange = (event) => {
		setPenaltyPercentage(event.target.value);
	};

	const toggleEditPenalty = () => {
		setEditPenalty(!editPenalty);

		if (penClicks === 0) {
			setPenClicks(penClicks + 1);
		} else if (penClicks === 1) {
			setPenClicks(0);
			///Updating Penalty
			penaltyMutation({ variables: { penalty_percentage: parseInt(penaltyPercentage) } })
				.then((result) => console.log(result))
				.catch((error) => console.log(error));
		}
	};

	return (
		<div className={classes.PenaltyPercentage}>
			<span style={{ color: "#D39C3E", marginRight: 10 }}>Penalty Percentage:</span>
			{editPenalty ? (
				<>
					<input
						className={classes.TextInput}
						style={{ width: 70 }}
						type="text"
						value={penaltyPercentage}
						onChange={(event) => handleOnChange(event)}
					/>
					%
				</>
			) : (
				<span style={{ display: "inline-block", width: 50 }}>{penaltyPercentage} %</span>
			)}
			<i
				className={["fas fa-pen", classes.PenIcon].join(" ")}
				style={{ marginLeft: 10 }}
				onClick={toggleEditPenalty}
			></i>
		</div>
	);
};

export default PenaltyPercentage;
