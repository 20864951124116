import gql from "graphql-tag";

export default gql`
	query ($attributes: FilterCurrencyTraderInput) {
		currencyTraderOrders(attributes: $attributes, status: "completed") {
			... on PurchaseOrder {
				id
				amountPaidCents
				amountPaidCurrency
				amountPurchasedCents
				amountPurchasedCurrency
				type
				paidFromPortal
				datetime
				rate
				number
				CI
				bankAccounts(type: "outgoing") {
					currency
					iban
					bankName
				}
				portfolio {
					user {
						firstName
						lastName
						globalName
						typology
						servicePoint {
							commercialName
						}
					}
				}
			}
			... on SaleOrder {
				id
				amountPaidCents
				amountSoldCents
				amountPaidCurrency
				amountSoldCurrency
				type
				paidFromPortal
				datetime
				rate
				number
				CI
				bankAccounts(type: "outgoing") {
					currency
					iban
					bankName
				}
				portfolio {
					user {
						firstName
						lastName
						globalName
						typology
						servicePoint {
							commercialName
						}
					}
				}
			}
		}
	}
`;
